import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';

const client = new ApolloClient({
  // uri: 'https://48p1r2roz4.sse.codesandbox.io'
  uri: 'http://localhost:4000/test'
});

export class ParkSpace extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  currency = () => {
    client
      .query({
        query: gql`
          {
            rates(currency: "USD") {
              currency
            }
          }
        `
      })
      .then(result => console.log(result));
  };

  test = () => {
    client
      .query({
        query: gql`
          query {
            users {
              name
            }
          }
        `
      })
      .then(res => console.log(res));
  };

  componentDidMount = () => {
    // this.currency()
    this.test();
  };

  render() {
    return (
      <div>
        <h1>AiA Hello world</h1>

      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkSpace);

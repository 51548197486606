import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner
} from 'reactstrap';
import imagePlaceholder from '../../../assets/images/imagePlaceholder.png';
import {
  getAccount,
  editAccountProperty,
  editUserAccount
} from '../../../actions/accountsActions';
import states from '../../../assets/js/states';
import accountTypes from '../../../assets/js/accountTypes';
import identificationTypes from '../../../assets/js/identificationTypes';
import sectors from '../../../assets/js/sectors';
import { toast } from 'react-toastify';

class ViewAccount extends Component {
  componentDidMount() {
    const { location, getAccount } = this.props;
    const { state: locationState } = location;
    if (!locationState) {
      this.props.history.goBack();
      return;
    }
    const { id } = locationState;
    if (!id) {
      this.props.history.pop();
      return;
    }
    getAccount(id);
  }
  handleChange = event => {
    const { name, value } = event.target;
    const { editAccountProperty } = this.props;
    switch (name) {
      case 'identification_type':
        editAccountProperty({
          [name]: value,
          identification_others: ''
        });
        break;
      case 'identification_others':
        editAccountProperty({
          [name]: value,
          identification_type: ''
        });
        break;
      case 'account_type':
        editAccountProperty({
          [name]: value,
          account_type_others: ''
        });
        break;
      case 'account_type_others':
        editAccountProperty({
          [name]: value,
          account_type: ''
        });
        break;
      case 'picture': {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.addEventListener(
          'load',
          () => {
            editAccountProperty({
              [name]: reader.result
            });
          },
          false
        );
        if (file) {
          reader.readAsDataURL(file);
        }
        break;
      }
      case 'id_image': {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.addEventListener(
          'load',
          () => {
            editAccountProperty({
              [name]: reader.result
            });
          },
          false
        );
        if (file) {
          reader.readAsDataURL(file);
        }
        break;
      }

      default:
        editAccountProperty({
          [name]: value
        });
    }
  };

  objIsEmpty = () => {
    const { accounts } = this.props;
    const { editAccount, defaultEditAccount } = accounts;
    for (let key in editAccount) {
      if (
        editAccount.hasOwnProperty(key) &&
        defaultEditAccount.hasOwnProperty(key)
      ) {
        if (editAccount[key] !== defaultEditAccount[key]) {
          return false;
        }
      }
    }
    return true;
  };

  handleSubmit = () => {
    const { accounts, editUserAccount } = this.props;
    const { editAccount, defaultEditAccount } = accounts;
    const { id } = editAccount;
    const submitObj = {};

    if (!this.objIsEmpty()) {
      for (let key in editAccount) {
        if (
          editAccount.hasOwnProperty(key) &&
          defaultEditAccount.hasOwnProperty(key)
        ) {
          if (editAccount[key] !== defaultEditAccount[key]) {
            submitObj[key] = editAccount[key];
          }
        }
      }
      submitObj.id = id;
      editUserAccount(submitObj);
    }
  };

  handleSubmit = () => {
    const { accounts, editUserAccount } = this.props;
    const { editAccount, defaultEditAccount, staffId } = accounts;
    const { id } = editAccount;
    const submitObj = {};

    if (!this.objIsEmpty()) {
      for (let key in editAccount) {
        if (
          editAccount.hasOwnProperty(key) &&
          defaultEditAccount.hasOwnProperty(key)
        ) {
          if (editAccount[key] !== defaultEditAccount[key]) {
            submitObj[key] = editAccount[key];
          }
        }
      }
      submitObj['id'] = id;
      submitObj['updated_by'] = staffId;
      editUserAccount(submitObj);
    }
  };

  approveAccount = () => {
    const { accounts, editUserAccount } = this.props;
    const { editAccount, staffId } = accounts;
    if (!staffId || staffId === '') {
      toast.error('Staff ID is undefined. Please refresh page');
      return;
    }
    const { id } = editAccount;
    editUserAccount({id, staff_id: staffId, updated_by: staffId});
  }

  render() {
    const { accounts } = this.props;
    const { pageLoading, editAccount, getAccountError } = accounts;
    const {
      surname,
      first_name: firstName,
      middle_name: middleName,
      phone_number: phoneNumber,
      staff_id: staffId,
      email,
      address,
      state,
      office_address: officeAddress,
      identification_type: identificationType,
      identification_others: identificationTypeOthers,
      id_image: idImage,
      picture,
      account_type: accountType,
      account_type_others: accountTypeOthers,
      account_number: accountNumber,
      sector,
      branch_code: branchCode
    } = editAccount;

    const stateList = states.map(currentState => (
      <option key={currentState} value={currentState}>
        {currentState}
      </option>
    ));

    const accountTypesList = accountTypes.map(currentAccount => (
      <option key={currentAccount} value={currentAccount}>
        {currentAccount}
      </option>
    ));

    const identificationTypesList = identificationTypes.map(
      currentIdentificationType => (
        <option
          key={currentIdentificationType}
          value={currentIdentificationType}
        >
          {currentIdentificationType}
        </option>
      )
    );

    const sectorsList = sectors.map(currentSector => (
      <option key={currentSector} value={currentSector}>
        {currentSector}
      </option>
    ));

    return (
      <Container className='loader-container'>
        {pageLoading && (
          <div className='loading-screen'>
            <Spinner
              color='success'
              style={{
                width: '3rem',
                height: '3rem'
              }}
            />
          </div>
        )}
        <Row className='mb-2'>
          <Col className='d-flex justify-content-between'>
            <div className='flex-1'>
              <Button
                className='text-uppercase'
                outline
                color='success'
                onClick={() => this.props.history.goBack()}
              >
                <i className='fas fa-arrow-left' style={{ fontSize: 10 }}></i>
              </Button>
            </div>
            {!staffId && (
              <Button
                className='text-uppercase d-flex align-items-center mr-1 ml-1'
                color='success'
                outline
                onClick={this.approveAccount}
              >
                <small className='font-weight-bold'>Approve account</small>
              </Button>
            )}
          </Col>
        </Row>
        <Form>
          <Row form className='justify-content-space-between'>
            <Col xs={12}>
              <Row>
                <Col>
                  <FormGroup className='d-flex justify-content-center'>
                    <div>
                      <Label
                        className='mx-auto'
                        style={{ position: 'relative' }}
                      >
                        <i
                          style={{ position: 'absolute', bottom: 5, right: 5 }}
                          className='fas fa-pen bg-white rounded-circle p-1'
                        ></i>
                        <img
                          className='rounded-circle'
                          style={{ width: 100, height: 100 }}
                          src={picture || imagePlaceholder}
                          alt='imgPlaceholder'
                        />
                        <Input
                          accept='image/jpeg, image/gif'
                          onChange={this.handleChange}
                          type='file'
                          name='picture'
                          className='d-none'
                        />
                      </Label>
                      <p className='text-center text-success'>Picture</p>
                    </div>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className='d-flex justify-content-center'>
                    <div>
                      <Label
                        className='mx-auto'
                        style={{ position: 'relative' }}
                      >
                        <i
                          style={{ position: 'absolute', bottom: 5, right: 5 }}
                          className='fas fa-pen bg-white rounded-circle p-1'
                        ></i>
                        <img
                          className='rounded-circle'
                          style={{ width: 100, height: 100 }}
                          src={idImage || imagePlaceholder}
                          alt='imgPlaceholder'
                        />
                        <Input
                          accept='image/jpeg, image/gif'
                          onChange={this.handleChange}
                          type='file'
                          name='id_image'
                          className='d-none'
                        />
                      </Label>
                      <p className='text-center text-success'>ID IMAGE</p>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className='pr-lg-3'>
              <Row>
                <Col md={6} lg={4}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Surname</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='surname'
                      value={surname}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={4}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>First name</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='first_name'
                      value={firstName}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} lg={4}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Middle name</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='middle_name'
                      value={middleName}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Email</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='email'
                      value={email}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>State</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='select'
                      name='state'
                      value={state}
                    >
                      <option>Select state</option>
                      {stateList}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Phone number</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='phone_number'
                      value={phoneNumber}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Address</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='textarea'
                      name='address'
                      value={address}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className='pl-lg-3'>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Identification type</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='select'
                      name='identification_type'
                      value={identificationType || ''}
                    >
                      <option value='' disabled={true}>
                        Select identification type
                      </option>
                      {identificationTypesList}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Others ( Identification )</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='identification_others'
                      value={identificationTypeOthers || ''}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Account type</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='select'
                      name='account_type'
                      value={accountType || ''}
                    >
                      <option disabled={true} value=''>
                        Select account type
                      </option>
                      {accountTypesList}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Others ( Account type )</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='account_type_others'
                      value={accountTypeOthers || ''}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small> Sector </small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='select'
                      name='sector'
                      value={sector}
                    >
                      <option disabled={true}> Select sector </option>
                      {sectorsList}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Branch code</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='text'
                      name='branch_code'
                      value={branchCode}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Label className='mb-0'>
                      <small>Office address</small>
                    </Label>
                    <Input
                      onChange={this.handleChange}
                      type='textarea'
                      name='office_address'
                      value={officeAddress}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='text-center d-flex justify-content-center'>
              <Button
                className='text-uppercase d-flex align-items-center mr-1 ml-1'
                color='success'
                outline
                onClick={this.handleSubmit}
              >
                <small className='font-weight-bold'>Edit account</small>
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { accounts } = state;
  return { accounts };
};

const mapDispatchToProps = {
  getAccount,
  editAccountProperty,
  editUserAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAccount);

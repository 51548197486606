import { toast } from 'react-toastify';

export const notify = (message, type, autoClose = true) => {
  switch (type) {
    case 'success':
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: autoClose
      });
      break;
    case 'error':
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: autoClose
      });
      break;
    default:
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: autoClose
      });
  }
};

export default notify;

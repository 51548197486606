import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getSalaries,
  getUsersLoan,
  postUserLoan,
} from '../../../actions/loanActions';
import Loader from '../Transactions/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import $ from 'jquery';

const data = [
  {
    accountNo: '1232029282',
    bankCode: '057',
    companyName: 'Spring Nigeria',
    countSalaryCr: '6',
    customerId: 'SPR0571232029282',
    customerName: 'James Oni Peters',
    salaryPaymentDetails: [
      {
        amount: '12000.00',
        paymentDate: '2017-09-06',
      },
      {
        amount: '12000.00',
        paymentDate: '2017-08-06',
      },
      {
        amount: '12000.00',
        paymentDate: '2017-07-06',
      },
    ],
  },
];

class CustomerLoan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      incomingData: undefined,
      submitLoanLoader: false,
      loanAmount: 0,
      collectionAmount: 0,
      numberOfRepayments: 0,
      totalCollectionAmount: 0,
    };
  }

  componentDidMount() {
    // console.log('states', this.props);
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    this.setState({ incomingData: this.props.location.state }, () => {
      if (this.state.incomingData !== undefined) {
        const payload = {
          orgId,
          customerId: this.state.incomingData.fromSalary.customerId,
        };
        this.props.getUsersLoan(payload);
      } else {
        const payload = { orgId };
        this.props.getUsersLoan(payload);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loan !== this.props.loan) {
      this.setState({ loading: false });
    }
  }

  activeUsersLists = () => {
    const activeUsers = this.props.loan.loanHistories.data.map(
      (data, index) => (
        <tr key={`${index}`}>
          <td>{data.accountNumber}</td>
          <td>{data.loanAmount}</td>
          <td>{data.totalCollectionAmount}</td>
          <td>{data.collectionAmount}</td>
          <td>{data.customerId}</td>
          <td>{data.phoneNumber}</td>
          <td>{data.status}</td>
          <td>{data.dateOfCollection}</td>
          <td>{data.dateOfDisbursement}</td>
          {/* <td>{moment(data.dateOfDisbursement).format("yyyy-MM-dd HH:mmZ")}</td> */}
        </tr>
      )
    );

    return activeUsers;
  };

  handleLoanForm = () => {
    // const modal= document.getElementById('loanUserForm');
    console.log('state', this.state);
    // modal.classList.remove("show")
    // modal.style.display = 'none'
    // $('#loanUserForm').modal('toggle')
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    const {
      customerId,
      authorisationCode,
      phoneNumber,
      accountNumber,
    } = this.state.incomingData.fromSalary;
    const {
      loanAmount,
      collectionAmount,
      numberOfRepayments,
      totalCollectionAmount,
    } = this.state;

    if (loanAmount.length <= 0) {
      toast.error("Loan Amount can't be empty");
      return;
    }
    if (collectionAmount.length <= 0) {
      toast.error("Collection Amount can't be empty");
      return;
    }
    if (numberOfRepayments.length <= 0) {
      toast.error("Number of repayments can't be empty");
      return;
    }
    if (totalCollectionAmount.length <= 0) {
      toast.error("Total Collection Amount can't be empty");
      return;
    }

    if (
      loanAmount.length > 0 &&
      collectionAmount.length > 0 &&
      numberOfRepayments.length > 0 &&
      totalCollectionAmount.length > 0
    ) {
      const payload = {
        customerId,
        authorisationCode,
        authorisationChannel: 'USSD',
        phoneNumber,
        accountNumber,
        currency: 'NGN',
        loanAmount,
        collectionAmount,
        dateOfDisbursement: '27-07-2020 11:49:25+0000',
        dateOfCollection: '30-07-2019 11:49:25+0000',
        totalCollectionAmount,
        numberOfRepayments,
        orgId,
      };
      this.setState({ submitLoanLoader: true }, () => {
        this.props.postUserLoan(payload, this.handleCallback);
      });
    } else {
      toast.error("Total Collection Amount can't be empty");
    }
  };

  handleCallback = (res) => {
    if (res === 'success') {
      toast.success('Loan request successful');
      this.setState(
        {
          loanAmount: 0,
          collectionAmount: 0,
          numberOfRepayments: 0,
          totalCollectionAmount: 0,
          submitLoanLoader: false,
        },
        () => {}
      );
    } else {
      this.setState({ submitLoanLoader: false });
    }
  };

  render() {
    return (
      <div className='p-4'>
        {this.state.loading && <Loader />}
        {this.state.incomingData !== undefined && (
          <div className='dropdown'>
            <button
              className='btn btn-secondary'
              data-toggle='modal'
              data-target='#loanUserForm'
            >
              Loan User
            </button>
          </div>
        )}
        <div className='p-4'>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th>{'Account Number'}</th>
                  <th>{'Loan Amount'}</th>
                  <th>{'Total collection amount'}</th>
                  <th>{'Collection amount'}</th>
                  <th>{'CustomerId'}</th>
                  <th>{'Phone'}</th>
                  <th>{'Status'}</th>
                  <th>{'Collection Date'}</th>
                  <th>{'Disbursement Date'}</th>
                </tr>
              </thead>
              <tbody>{this.activeUsersLists()}</tbody>
            </table>
          </div>
        </div>

        {/* Enter Phone number modal */}
        <div
          className='modal fade'
          id='loanUserForm'
          data-backdrop='static'
          data-keyboard='false'
          tabindex='-1'
          role='dialog'
          aria-labelledby='loanUserFormLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='loanUserFormLabel'>
                  Loan Form
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  data-target='#loanUserForm'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label for='loanAmount'>Loan Amount</label>
                    <input
                      type='number'
                      className='form-control'
                      id='phoneNloanAmountumber'
                      aria-describedby='loanAmount'
                      value={this.state.loanAmount}
                      onChange={(value) =>
                        this.setState({
                          loanAmount:
                            value.target.value < 0 ? 0 : value.target.value,
                        })
                      }
                    />
                  </div>
                  <div className='form-group'>
                    <label for='collectionAmount'>Collection Amount</label>
                    <input
                      type='number'
                      className='form-control'
                      id='collectionAmount'
                      aria-describedby='collectionAmountHelp'
                      value={this.state.collectionAmount}
                      onChange={(value) =>
                        this.setState({
                          collectionAmount:
                            value.target.value < 0 ? 0 : value.target.value,
                        })
                      }
                    />
                  </div>
                  <div className='form-group'>
                    <label for='numberOfRepayments'>Number Of Repayments</label>
                    <input
                      type='number'
                      className='form-control'
                      id='numberOfRepayments'
                      aria-describedby='numberOfRepaymentsHelp'
                      value={this.state.numberOfRepayments}
                      onChange={(value) =>
                        this.setState({
                          numberOfRepayments:
                            value.target.value < 0 ? 0 : value.target.value,
                        })
                      }
                    />
                  </div>
                  <div className='form-group'>
                    <label for='totalCollectionAmount'>
                      Total Collection Amount
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      id='totalCollectionAmount'
                      aria-describedby='totalCollectionAmountHelp'
                      value={this.state.totalCollectionAmount}
                      onChange={(value) =>
                        this.setState({
                          totalCollectionAmount:
                            value.target.value < 0 ? 0 : value.target.value,
                        })
                      }
                    />
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button
                  onClick={() => this.handleLoanForm()}
                  type='button'
                  className='btn btn-primary'
                >
                  {this.state.submitLoanLoader ? (
                    <i class='fa fa-spinner fa-spin'></i>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  states: state,
  loan: state.loan,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSalaries: (payload, cd) => dispatch(getSalaries(payload, cd)),
    getUsersLoan: (payload, cd) => dispatch(getUsersLoan(payload, cd)),
    postUserLoan: (payload, cd) => dispatch(postUserLoan(payload, cd)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLoan);

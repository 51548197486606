import React from 'react';
// import { userLocation } from '../../../reducers/home';
import {
  Form,
  FormGroup,
  Label,
  Input,
  CardBody,
  Col,
  Row,
  // CustomInput,
  // ListGroup,
  // ListGroupItem,
  // style,
} from 'reactstrap';
import { listPermission } from '../../../actions/permission';

const UserForm = ({
  info,
  toggle,
  toggleSave,
  handleSetState,
  saveEditedUser,
}) => {
  // console.log('logging info ', info);

  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div>
          <button
            type='button'
            className='back-btn btn-success m-3'
            onClick={toggleSave}
          >
            Back
          </button>
        </div>
        <div>
          <button
            type='button'
            className='back-btn btn-success m-3'
            onClick={saveEditedUser}
          >
            Save
          </button>
        </div>
      </div>

      <CardBody>
        <Form>
          <Row form>
            <Col md={3}>
              <div className='form-group' style={{ margin: 3 }}>
                <label htmlFor='first-name'>First name</label>
                <input
                  type='text'
                  className='form-control'
                  onChange={text =>
                    handleSetState('firstname', text.target.value)
                  }
                  value={info.firstname}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className='form-group' style={{ margin: 3 }}>
                <label htmlFor='last-name'>Last name</label>
                <input
                  type='text'
                  className='form-control'
                  onChange={text =>
                    handleSetState('lastname', text.target.value)
                  }
                  value={info.lastname}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className='form-group' style={{ margin: 3 }}>
                <label htmlFor='first-name'>Staff Id</label>
                <input
                  type='text'
                  className='form-control'
                  onChange={text =>
                    handleSetState('staff_id', text.target.value)
                  }
                  value={info.staff_id}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className='form-group' style={{ margin: 3 }}>
                <label htmlFor='branch_code'>Branch Code</label>
                <input
                  type='text'
                  className='form-control'
                  onChange={text =>
                    handleSetState('branch_code', text.target.value)
                  }
                  value={info.branch_code}
                />
              </div>
            </Col>
            <Col md={6}>
              <div style={{ margin: 3 }}>
                <label htmlFor='email'>Email</label>
                <input
                  type='email'
                  className='form-control'
                  onChange={text => handleSetState('email', text.target.value)}
                  value={info.email}
                />
              </div>
            </Col>
            {listPermission(7) && (
              <Col md={3}>
                <div style={{ margin: 3 }}>
                  <label htmlFor='authorize'>Authorize User</label>
                  <select
                    onChange={text =>
                      handleSetState('authorize', text.target.value)
                    }
                    value={info.authorize}
                    className='form-control'
                  >
                    <option value={true}>Authorize</option>
                    <option value={false}>Block</option>
                  </select>
                </div>
              </Col>
            )}
            <Col md={3}>
              <div style={{ margin: 3 }}>
                <label htmlFor='gender'>Gender</label>
                <select
                  onChange={text => handleSetState('gender', text.target.value)}
                  value={info.gender}
                  className='form-control'
                >
                  <option value=''>Gender</option>
                  <option value='Male'>Male</option>
                  <option value='Female'>Female</option>
                </select>
              </div>
            </Col>
            {listPermission(8) && (
              <Col md={3}>
                <div style={{ margin: 3 }}>
                  <label htmlFor='organisation'>Organisation</label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={text =>
                      handleSetState('org_id', text.target.value)
                    }
                    value={info.org_id}
                  />
                </div>
              </Col>
            )}
            <Col md={3}>
              <FormGroup style={{ margin: 3 }}>
                <Label htmlFor='phone'>Number</Label>
                <Input
                  type='number'
                  onChange={text => handleSetState('phone', text.target.value)}
                  value={info.phone}
                  placeholder='Phone No'
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <div style={{ margin: 3 }}>
                <label htmlFor='user_type'>User Type</label>
                <select
                  onChange={text =>
                    handleSetState('user_type', text.target.value)
                  }
                  value={info.user_type}
                  className='form-control'
                >
                  <option value=''>Choose user type</option>
                  {listPermission(8) && (
                    <option value='Super_Admin'>Super-Admin</option>
                  )}
                  {listPermission(7) && (
                    <option value='Authorizer'>Authorizer</option>
                  )}
                  {listPermission(6) && (
                    <option value='Basic_Admin'>Basic-Admin</option>
                  )}
                  {listPermission(5) && (
                    <option value='Operational_Staff'>Operational-Staff</option>
                  )}
                  {listPermission(4) && (
                    <option value='Super_Agent'>Super-Agent</option>
                  )}
                  {listPermission(3) && (
                    <option value='Basic_Agent'>Basic-Agent</option>
                  )}
                  {listPermission(2) && <option value='Teller'>Teller</option>}
                  <option value='Customer'>Customer</option>
                </select>
              </div>
            </Col>
            <Col md={6}>
              <div style={{ margin: 3 }}>
                <label htmlFor='city'>City</label>
                <input
                  type='Address'
                  className='form-control'
                  autocomplete='new-password'
                  onChange={text => {
                    handleSetState('city', text.target.value);
                  }}
                  value={info.address.city}
                />
              </div>
            </Col>
          </Row>
          {listPermission(8) && (
            <Row>
              <Col md={6}>
                <div style={{ margin: 3 }}>
                  <label htmlFor='password'>Password</label>
                  <input
                    type='password'
                    autocomplete='new-password'
                    className='form-control'
                    onChange={text => {
                      handleSetState('password', text.target.value);
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div style={{ margin: 3 }}>
                  <label htmlFor='Confirm password'>Confirm password</label>
                  <input
                    type='password'
                    className='form-control'
                    onChange={text => {
                      handleSetState('confirmPassword', text.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </CardBody>
    </div>
  );
};
export default UserForm;

import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { toast } from "react-toastify";

const _DEV = "localhost";
let URL_PATH = "/api/graphql";

if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}

const apolloClient = new ApolloClient({
  uri: `${URL_PATH}`,
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token ? token : "",
      },
    });
  },
});



export const createJournal = async (
  {
    narration = "",
    branch,
    note = "",
    operationDate,
    folio,
    journal_record = [],
  },
  handleSuccess,
  handleLoading
) => {
  const { user } = window.localStorage;
  const { org_id } = JSON.parse(user);

  try {
    const newJournal = await apolloClient.mutate({
      variables: {
        narration,
        branch,
        note,
        operationDate,
        folio,
        journal_record,
        org_id,
      },
      mutation: gql`
        mutation createJournal(
          $narration: String!
          $branch: String!
          $org_id: String!
          $note: String
          $operationDate: String!
          $folio: Int!
          $journal_record: [EntryRecord_INPUT!]!
        ) {
          createJournal(
            narration: $narration
            branch: $branch
            org_id: $org_id
            note: $note
            operationDate: $operationDate
            folio: $folio
            journal_record: $journal_record
          ) {
            status
            message
            entry {
              id
              org_id
              narration
              branch
              note
              operationDate
              folio
              created_date
              status
              journal_records {
                id
                entry_id
                amount
                type
                account_code
                sub_narration
                created_at
              }
            }
          }
        }
      `,
    });
    handleLoading(false);
    if (newJournal.errors) {
      toast.error(newJournal.errors);
      return;
    }
    let response = newJournal.data.createJournal;
    if (response.status === "success") {
      toast.success(response.message);
      // remove existing entry records.
      handleSuccess([]);
    } else {
      toast.warn(response.message);
    }
    return response;
  } catch (error) {
    handleLoading(false);
    toast.error(error.message);
  }
};



export const getAccountGL = async()=>{

  const { user } = window.localStorage;
  const { org_id } = JSON.parse(user);
  try {
    const response = await apolloClient.query({
      variables:{
        org_id
      },
      query: gql`
        query getGLNumber(
          $org_id:  String!
        ){
          getGLNumber(
            org_id: $org_id
          ){
            status
            data{
              id
              subclass
              code
              description
              tags
              created
              expiration
              type
              currency
              root
              parent
            }
          }
        }
      `
    })

    if (response.errors) {
      console.log(response.errors);
    }
    // console.log({res:response.data.getPendingPostings});
    return response.data.getGLNumber;
  } catch (err) {
    toast.error(err.message);
  }  
}


export const getOrgJournals = async( {
  limit = 100,
  page,
  order= 'ASC'
}) => {
  const { user } = window.localStorage;
  const { org_id } = JSON.parse(user);
  try {
    const response = await apolloClient.query({
      variables: {
        limit,
        page,
        org_id,
        order,
      },
      query: gql`
        query getJournals(
          $limit: Int
          $page: Int
          $org_id: String!
          $order: String
        ) {
          getOrgJournalEntries(
            limit: $limit
            page: $page
            org_id: $org_id
            order: $order
          ) {
            status
            message
            entries{
              id
              org_id
              narration
              branch
              note
              operationDate
              folio
              created_date
              status
              journal_records{
                id
                entry_id
                amount
                type
                account_code
                sub_narration
                created_at
              }
            }
            pagination{
              current_page
              total_pages
              per_page
              limit
              previous_page
              next_page
              current_page_total
              total
            }
          
          }
        }
      `,
    });

    if (response.errors) {
      console.log(response.errors);
    }
    // console.log({res:response.data.getPendingPostings});
    return response.data.getOrgJournalEntries;
  } catch (err) {
    console.log(err)
    toast.error(err.message);
  }
};

export const getAccountDetails = async (
  acct_number,
  bank_code,
  dashboard,
  origin
) => {
  try {
    const response = await apolloClient.query({
      variables: {
        acct_number,
        bank_code,
        dashboard,
        origin,
      },
      query: gql`
        query GetAccountInfo(
          $acct_number: String
          $bank_code: String!
          $dashboard: Boolean
          $origin: Boolean
        ) {
          getAccountName(
            acct_number: $acct_number
            bank_code: $bank_code
            dashboard: $dashboard
            origin: $origin
          ) {
            acct_name
            bank_name
            balance
          }
        }
      `,
    });
    return response.data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const getBranches = async () => {
  const { user } = window.localStorage;

const { org_id } = JSON.parse(user);

  try {
    const response = await apolloClient.query({
      variables: {
        org_code:org_id,
      },
      query: gql`
        query getBranchesList($org_code: String!) {
          getOrgBranches(org_code: $org_code) {
            status
            data{
              id
              name
              code
              status
              created
              updated
              address
              org_id
              created_at
            }
          }
        }
      `,
    });
    if (response.errors) {
      toast.error(response.errors);
    } else {
      // console.log(response.data.getOrgBranches);
      return response.data.getOrgBranches;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

// {
//     "_id" : ObjectId("5d55f7e70ea03f14f89a7b0b"),
//     "address" : {
//         "city" : "",
//         "zipcode" : ""
//     },
//     "name" : "",
//     "firstname" : "test1",
//     "lastname" : "testing",
//     "password" : "abcd",
//     "email" : "test@gmail.com",
//     "gender" : "male",
//     "status" : "",
//     "img_url" : "",
//     "user_type" : "Agent",
//     "customer_type" : "rider",
//     "phone" : "",
//     "website" : "",
//     "company" : "",
//     "id" : 2,
//     "updated_at" : 1565915111856.0,
//     "__v" : 0
// }
import  { textFilter } from 'react-bootstrap-table2-filter';

const pointer = {
  cursor: 'pointer'
}

export const columns = [
  {
    dataField: 'id',
    text: 'User ID',
    align: 'center',
    style: {
      fontWeight: 'bold',
      fontSize: '18px',
      cursor: 'pointer'
    },
    headerStyle: {
      padding: '10px',
      margin: '0px',
      width: '10%',
    },
    headerAlign: 'center',
    filter: textFilter()
  },
  {
    dataField: 'firstname',
    text: 'Firstname',
    sort: true,
    headerAlign: 'center',
    style: pointer,
    filter: textFilter()
  },
  {
    dataField: 'lastname',
    text: 'Lastname',
    sort: true,
    headerAlign: 'center',
    style: pointer,
    filter: textFilter()
  },
  {
    dataField: 'email',
    text: 'Email',
    headerAlign: 'center',
    style: pointer,
    filter: textFilter()
  },
  {
    dataField: 'phone',
    text: 'Phone',
    sort: true,
    headerAlign: 'center',
    style: pointer,
    filter: textFilter()
  },
  {
    dataField: 'address.city',
    text: 'Address',
    sort: true,
    headerAlign: 'center',
    style: pointer,
    filter: textFilter()
  }
];

export const defaultSorted = [{
  dataField: 'id',
  order: 'asc'
}];

// const column = { columns , defaultSorted}

// export default column

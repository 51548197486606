import React from 'react';

// image_url: { type: String, default: '' },
// plate_no: { type: String, default: '' },
// car_name: { type: String, default: '' },
// car_model: { type: String, default: '' },
// available: { type: String, default: '' },
// description: { type: String, default: '' },
// created_at: { type: Number, default: Date.now },
// updated_at: { type: Number, default: Date.now },
function urlFormatter(image_url, row) {

  return (
    <span><img src={image_url} alt='' width={100} style={{borderRadius:50}}/> </span>
  );
}
const columns = [
  {
    dataField: 'image_url',
    text: 'Image',
    formatter: urlFormatter,
    align: 'center'
  },
  {
    dataField: 'plate_no',
    text: 'Plate No',
    sort: true
  },
  {
    dataField: 'car_name',
    text: 'Car Name'
  },
  {
    dataField: 'car_model',
    text: 'Car model',
    sort: true
  },
  {
    dataField: 'available',
    text: 'Available',
    sort: true
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: true
  },
  {
    dataField: 'created_at',
    text: 'Created At',
    sort: true
  },
  {
    dataField: 'updated_at',
    text: 'Updated At',
    sort: true
  }
];

export default columns;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  UncontrolledTooltip
} from 'reactstrap';
import { getBills } from './../../../actions/billsPaymentActions';
import Pagination from './Pagination';
import FilterView from './FilterView';
class BillsPayment extends Component {
  componentDidMount() {
    this.props.getBills({ limit: 10, offset: 0 });
  }
  state = {
    filterObj: {}
  };

  onPageChange = pageNumber => {
    const { billsPayment } = this.props;
    const { filterObj } = this.state;
    const { pagination } = billsPayment;
    const { limit, offset } = pagination;
    const paginationObj = { limit, offset };
    paginationObj.offset = (pageNumber - 1) * limit;
    this.props.getBills({ ...filterObj, ...paginationObj });
  };

  mapBillList = () => {
    const { billsPayment } = this.props;
    const { billList } = billsPayment;
    let numbering = 1;
    const mappedBillList = billList.map(bill => {
      const {
        id,
        name,
        accountNumber,
        sub_category: subCategory,
        teller_no: tellerNo,
        trans_type: transType,
        variation,
        created_at: createdAt,
        amount
      } = bill;
      return (
        <tr className='tr' key={id}>
          <th scope='row'>{numbering++}</th>
          <td>{name}</td>
          <td>{accountNumber}</td>
          <td>{tellerNo}</td>
          <td>
            {subCategory}({variation})
          </td>
          <td className='text-center'>
            <strong
              className={`${
                transType === 'CR' ? 'text-success' : 'text-danger'
              }`}
            >
              <span className='mr-2'>
                {transType === 'CR' ? (
                  <i className='fas fa-level-up-alt'></i>
                ) : (
                  <i className='fas fa-level-down-alt'></i>
                )}
              </span>
              {transType}
            </strong>
          </td>

          <td>NGN{amount}</td>
          <td>{createdAt}</td>
          <td>
            <UncontrolledTooltip target={`downloadButton${numbering}`}>
              Download receipt
            </UncontrolledTooltip>
            <a
              href={`https://api.jekalo.com/api/receipts/transactions?teller_no=${tellerNo}`}
              target='_blank'
              rel='noopener noreferrer'
              className=''
              id={`downloadButton${numbering}`}
            >
              <i className='fas fa-cloud-download-alt'></i>
            </a>
          </td>
        </tr>
      );
    });
    return mappedBillList;
  };

  onFilter = payload => {
    const { getBills } = this.props;
    this.setState({ filterObj: payload });
    getBills({ ...payload, ...{ limit: 10, offset: 0 } });
  };

  render() {
    const { billsPayment } = this.props;
    const { billListLoading, pagination } = billsPayment;
    const mappedBillList = this.mapBillList();
    const { total_count: totalCount, offset, limit } = pagination;
    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = offset / limit + 1;

    return (
      <Container>
        <Row>
          <Col>
            <FilterView onFilter={this.onFilter} exclude={['status']} />
          </Col>
          <Col className='d-flex justify-content-between'>
            <div></div>
            <div>
              <Button
                className='text-uppercase d-flex align-items-center'
                color='success'
                onClick={() =>
                  this.props.history.push('/transactions/pay-bills')
                }
              >
                <i class='fas fa-plus mr-2' style={{ fontSize: 10 }}></i>{' '}
                <small className='font-weight-bold'>Pay bill</small>
              </Button>
            </div>
          </Col>
        </Row>
        {billListLoading && (
          <div className='loading-screen'>
            <Spinner
              color='success'
              style={{
                width: '3rem',
                height: '3rem'
              }}
            />
          </div>
        )}
        <Row>
          <Col>
            <Table borderless>
              <thead>
                <tr className='text-uppercase'>
                  <th></th>
                  <th>
                    <small className='font-weight-bold'>Name</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Account ref</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Trans Ref</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Summary</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Trans type</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Amount</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Date</small>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{mappedBillList}</tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className='d-flex justify-content-end mr-2'>
            {totalPages > 1 && (
              <Pagination
                pageHistory={{ totalPages, currentPage }}
                onPageChange={this.onPageChange}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { billsPayment } = state;
  return { billsPayment };
};

const mapDispatchToProps = {
  getBills
};

export default connect(mapStateToProps, mapDispatchToProps)(BillsPayment);

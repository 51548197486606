import React from "react";
import CustomPeriod from "./customPeriod";
import SpecificPeriod from "./specificPeriod";
class HistoryForm extends React.Component {
  state = {
    editDetails: {
      all_detail: '',
      custom_period: '',
      start_date: '',
      end_date: ''
    },
    inputSpecialDate: {
      specialDate: ''
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    const filterDetails = { ...this.props.filterDetails };
    filterDetails[name] = value;
    this.props.setFilterDetails(filterDetails);
    console.log('look ma, I WAS CLICKED', name, value);
  };

  fillReccuringForm = event => {
    const { name, value } = event.target;
    const newEditDetails = { ...this.state.editDetails };
    newEditDetails[name] = value;
    this.setState({ editDetails: newEditDetails });
  };
  handleReset = event => {
    event.preventDefault();
    const newDetails = {
      trancType: '',
      dateFilter: '',
      specificDate: '',
      startDate: '',
      endDate: ''
    };
    this.props.setFilterDetails(newDetails);
  };
  handleSubmit = event => {
    event.preventDefault();
    this.props.handleSubmit();
  };
  render() {
    return (
      <form>
        <div className='form-group'>
          <label className='text-dark'>Type</label>
          <select
            className='form-control-sm w-100'
            id='exampleFormControlSelect1'
            name='trancType'
            value={this.props.filterDetails.trancType}
            onChange={this.handleChange}
          >
            <option value=''>Show All</option>
            <option value='Auto Topup'>Auto Topup</option>
            <option value='Transaction'>Transaction</option>
            <option value='Transfer'>Transfer</option>
            <option value='Reversed Transfer'>Reversed Transfer</option>
            <option value='Tranfer Charge'>Tranfer Charge</option>
            <option value='BVN Request CHarge'>BVN Request CHarge</option>
            <option value='BVN Match Charge'>BVN Match Charge</option>
            <option value='Paystack Payout'>Paystack Payout</option>
            <option value='Refund'>Refund</option>
            <option value='External'>External</option>
          </select>
        </div>
        <div className='form-group'>
          <label className='text-dark'>Date Period</label>
          <select
            className='form-control-sm w-100'
            id='exampleFormControlSelect1'
            name='dateFilter'
            value={this.props.filterDetails.dateFilter}
            onChange={this.handleChange}
          >
            <option value=''>All Time</option>
            <option value='today'>Today</option>
            <option value='specific'>Specific Day</option>
            <option value='interval'>Custom Period</option>
          </select>
        </div>

        {this.props.filterDetails.dateFilter === 'specific' && (
          <div className='form-group'>
            <label className='text-dark'>Choose specific day</label>
            <input
              type='date'
              className='form-control-sm col-12'
              placeholder='Start date'
              name='specificDate'
              value={this.props.filterDetails.specificDate}
              onChange={this.handleChange}
            />
          </div>
        )}
        {this.props.filterDetails.dateFilter === 'interval' && (
          <div className='form-group'>
            <label className='text-dark'>Choose date ranges</label>
            <div className='clearfix'>
              {' '}
              <div className='form-group'>
                <label>Start date</label>
                <input
                  type='date'
                  className='form-control-sm col-12 m-1'
                  placeholder='Start date'
                  name='startDate'
                  value={this.props.filterDetails.startDate}
                  onChange={this.handleChange}
                />
              </div>
              <div className='form-group'>
                <label>End date</label>
              <input
                type='date'
                className='form-control-sm col-12 m-1'
                placeholder='End date'
                name='endDate'
                value={this.props.filterDetails.endDate}
                onChange={this.handleChange}
                />
                </div>
            </div>
          </div>
        )}
        <div className='clearfix'>
          <button
            type='submit'
            className='btn btn-secondary btn-sm float-left '
            onClick={this.handleReset}
          >
            Reset
          </button>
          <button
            onClick={this.handleSubmit}
            className='btn btn-success btn-sm float-right'
          >
            Filter
          </button>
        </div>
      </form>
    );
  }
}

HistoryForm.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};
export default HistoryForm;

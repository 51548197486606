import React, { Component } from 'react'
import { connect } from 'react-redux'

export class AgentsDetails extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       
    }
  }
  

  render() {
    return (
      <div>
        <h1>
            AgentsDetails
        </h1>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentsDetails)

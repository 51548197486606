import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { accountsConstants } from "./../constants/accountsConstants";
import { toast } from "react-toastify";

const _DEV = "localhost";
let URL_PATH = "/api/graphql";
const { SET_EDIT_ACCOUNT, SET_ACCOUNT_FIELD } = accountsConstants;
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}



const apolloClient = new ApolloClient({
  uri: `${URL_PATH}`,
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token ? token : "",
      },
    });
  },
});

/**
 * Function for getting accounts
 * @return {object}
 */
export const getAccounts = (payload = {}) => {
  const { user } = window.localStorage;
  const { org_id: orgId } = JSON.parse(user);
  const queryList = [];
  const {
    limit,
    offset,
    dateFilter: date_filter,
    startDate: start_date,
    endDate: end_date,
    specificDate: specific_date,
    queryFilter: query_filter,
  } = payload;

  queryList.push(`org_id: "${orgId}"`);

  if (limit) {
    queryList.push(`limit: "${limit}"`);
  }

  if (date_filter) {
    queryList.push(`date_filter: "${date_filter}"`);
  }

  if (offset) {
    queryList.push(`offset: "${offset}"`);
  }

  if (start_date) {
    queryList.push(`start_date: "${start_date}"`);
  }

  if (end_date) {
    queryList.push(`end_date: "${end_date}"`);
  }

  if (specific_date) {
    queryList.push(`specific_date: "${specific_date}"`);
  }

  if (query_filter) {
    queryList.push(` query_filter: "${query_filter}"`);
  }

  const queryString = queryList.join(", ");

  return async (dispatch) => {
    try {
      dispatch({
        type: SET_ACCOUNT_FIELD,
        payload: { pageLoading: true },
      });
      const client = new ApolloClient({
        uri: `${URL_PATH}`,
      });

      const response = await client.query({
        query: gql`
          query {
            getAccounts(${queryString}) {
              error
              message
              data{
                id
                phone_id
                surname
                first_name
                middle_name
                phone_number
                email
                address
                state
                office_address
                identification_type
                identification_others
                id_image
                picture
                account_type
                account_type_others
                account_number
                sector
                staff_id
                customer_id
                branch_code,
                nuban,
                org_id
                updated_by
                updated_at
                created_at
              }
              pagination{
                offset
                limit
                total_count
              }
            }
          }
        `,
      });
      const { data } = response;
      const { getAccounts } = data;
      const { data: accountList, error, message, pagination } = getAccounts;

      if (error) {
        toast.error(message);
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: { pageLoading: false },
        });
        return;
      }

      const { offset, limit, total_count } = pagination;

      const payload = {
        accountList,
        pagination,
      };

      dispatch({ type: SET_ACCOUNT_FIELD, payload });
    } catch (err) {
      toast.error("Network error");
    } finally {
      dispatch({ type: SET_ACCOUNT_FIELD, payload: { pageLoading: false } });
    }
  };
};

/**
 * Function for getting individual account
 * @param {integer} id
 * @return {object}
 */
export const getAccount = (id) => {
  const { user } = window.localStorage;
  const { phone, org_id: orgId } = JSON.parse(user);

  return async (dispatch) => {
    try {
      const client = new ApolloClient({
        uri: `${URL_PATH}`,
      });

      const getStaffId = await client.query({
        query: gql`
          query {
            getUserIdAndPhoneId (
              phone_number: "${phone}"
            ) {
              user_id
              phone_id
            }
          }
        `,
      });
      const { getUserIdAndPhoneId } = getStaffId.data;

      if (getUserIdAndPhoneId.error) {
        toast.error(getUserIdAndPhoneId.error);
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: {
            pageLoading: false,
          },
        });
        return;
      }

      const { user_id: userId } = getUserIdAndPhoneId;
      dispatch({
        type: SET_ACCOUNT_FIELD,
        payload: {
          pageLoading: true,
          staffId: userId,
          editAccount: {
            surname: "",
            first_name: "",
            middle_name: "",
            phone_number: "",
            email: "",
            address: "",
            state: "",
            office_address: "",
            identification_type: "",
            identification_others: "",
            id_image: null,
            picture: null,
            account_type: "",
            account_type_others: "",
            account_number: "",
            sector: "",
          },
          defaultEditAccount: {
            surname: "",
            first_name: "",
            middle_name: "",
            phone_number: "",
            email: "",
            address: "",
            state: "",
            office_address: "",
            identification_type: "",
            identification_others: "",
            id_image: null,
            picture: null,
            account_type: "",
            account_type_others: "",
            account_number: "",
            sector: "",
          },
        },
      });

      const response = await client.query({
        query: gql`
          query {
            getAccounts(org_id: "${orgId}", id: "${id}") {
              data{
                id
                phone_id
                surname
                first_name
                middle_name
                phone_number
                email
                address
                state
                office_address
                identification_type
                identification_others
                id_image
                picture
                account_type
                account_type_others
                account_number
                sector
                staff_id
                customer_id
                branch_code
                org_id
                updated_by
                updated_at
                created_at
              }
            }
          }
        `,
      });
      const { data } = response;
      const { getAccounts } = data;
      const { data: accountList, error, message } = getAccounts;

      if (error) {
        toast.error(message);
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: { pageLoading: false, getAccountError: true },
        });
        return;
      }
      if (accountList.length !== 1) {
        toast.error("Account not found");
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: { pageLoading: false, getAccountError: true },
        });
        return;
      }

      const payload = {
        editAccount: accountList[0],
        defaultEditAccount: accountList[0],
        getAccountError: false,
        pageLoading: false,
      };
      dispatch({ type: SET_ACCOUNT_FIELD, payload });
    } catch (err) {
      console.log(err);
      toast.error("Network error");
      dispatch({ type: SET_ACCOUNT_FIELD, payload: { getAccountError: true } });
    } finally {
      dispatch({ type: SET_ACCOUNT_FIELD, payload: { pageLoading: false } });
    }
  };
};

/**
 * edits a user account
 * @param { object } payload
 * @returns { object }
 */
export const editUserAccount = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ACCOUNT_FIELD,
      payload: {
        pageLoading: true,
      },
    });

    const editFields = [];

    const { user } = window.localStorage;

    const { firstname, lastname, org_id: orgId } = JSON.parse(user);
    payload["updated_by_name"] = `${firstname} ${lastname}`;
    payload["org_id"] = orgId;

    const client = new ApolloClient({
      uri: `${URL_PATH}`,
    });

    try {
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          editFields.push(`${key}: "${payload[key]}"`);
        }
      }

      const updateResponse = await client.mutate({
        mutation: gql`
          mutation {
            updateUserAccount (
              ${editFields.join(" , ")}
            ) {
              status
              message
              error
            }
          }
        `,
      });
      const { updateUserAccount } = updateResponse.data;
      if (updateUserAccount.error) {
        toast.error(updateUserAccount.message);
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: {
            pageLoading: false,
          },
        });
      } else {
        toast.success("User account updated successfully");
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: {
            pageLoading: false,
          },
        });
      }
    } catch (err) {
      toast.error("Network error occured");
      dispatch({
        type: SET_ACCOUNT_FIELD,
        payload: {
          pageLoading: false,
        },
      });
    }
    dispatch(getAccount(payload.id));
    dispatch({
      type: SET_ACCOUNT_FIELD,
      payload: {
        pageLoading: false,
      },
    });
  };
};

/**
 * set a property of editAccount property state
 * @param {object} payload - the properties to be edited
 * @return {object}
 */
export const editAccountProperty = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_EDIT_ACCOUNT, payload });
  };
};

/**
 * set a property of accounts state
 * @param {object} payload - the properties to be edited
 * @return {object}
 */
export const setAccountProperty = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_ACCOUNT_FIELD, payload });
  };
};

/**
 * create user account
 * @param { object } payload
 * @returns { object }
 */
export const createAccount = (payload, resetForm) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ACCOUNT_FIELD,
      payload: {
        pageLoading: true,
      },
    });

    try {
      const { user } = window.localStorage;

      const { org_id: orgId, staff_id } = JSON.parse(user);

      const {
        first_name,
        middle_name,
        phone_id,
        surname,
        email,
        address,
        phone_number,
        customer_id,
        state,
        identification_type,
        organization_type,
        account_type,
        sector,
        id_image,
        picture,
        branch_code,
        account_id,
        account_name,
        street,
        tell_no,
        fax_no,
        opening_date,
        account_status,
        sex,
        birthday_incorp,
        next_of_kin,
        next_of_kin_address,
        next_of_kin_phone_no,
        next_of_kin_relation,
        tax_number,
        bvn,
        cot_rate,
        deposit_rate,
        debit_rate,
      } = payload;

      const client = new ApolloClient({
        uri: `${URL_PATH}`,
        request: (operation) => {
          const token = localStorage.getItem("token");
          operation.setContext({
            headers: {
              authorization: token ? token : "",
            },
          });
        },
      });
      const newAccountResponse = await client.mutate({
        variables: {
          first_name,
          phone_id,
          surname,
          email,
          address,
          phone_number,
          customer_id,
          state,
          identification_type,
          organization_type,
          account_type,
          sector,
          id_image,
          staff_id,
          org_id: orgId,
          picture,
          branch_code,
          account_id,
          account_name,
          street,
          tell_no,
          fax_no,
          opening_date,
          account_status,
          sex,
          birthday_incorp,
          next_of_kin,
          next_of_kin_address,
          next_of_kin_phone_no,
          next_of_kin_relation,
          tax_number,
          bvn,
          cot_rate,
          deposit_rate,
          debit_rate,
        },
        mutation: gql`
          mutation CreateAccount(
            $first_name: String!
            $phone_id: String!
            $surname: String!
            $email: String!
            $address: String!
            $phone_number: String!
            $customer_id: String!
            $state: String!
            $identification_type: String!
            $account_type: String!
            $sector: String!
            $id_image: String!
            $picture: String!
            $org_id: String!
            $staff_id: String!
            $branch_code: String!
            $account_id: String!
            $account_name: String!
            $street: String!
            $tell_no: String!
            $fax_no: String
            $opening_date: String!
            $account_status: String!
            $sex: String!
            $birthday_incorp: String!
            $next_of_kin: String!
            $next_of_kin_address: String!
            $next_of_kin_phone_no: String!
            $next_of_kin_relation: String!
            $tax_number: String!
            $bvn: String!
            $organization_type: String!
            $cot_rate: String!
            $deposit_rate: String!
            $debit_rate: String!
          ) {
            createAccount(
              first_name: $first_name
              phone_id: $phone_id
              surname: $surname
              email: $email
              address: $address
              phone_number: $phone_number
              customer_id: $customer_id
              state: $state
              identification_type: $identification_type
              account_type: $account_type
              sector: $sector
              id_image: $id_image
              picture: $picture
              org_id: $org_id
              staff_id: $staff_id
              branch_code: $branch_code
              account_id: $account_id
              account_name: $account_name
              street: $street
              opening_date: $opening_date
              account_status: $account_status
              sex: $sex
              birthday_incorp: $birthday_incorp
              next_of_kin: $next_of_kin
              next_of_kin_address: $next_of_kin_address
              next_of_kin_phone_no: $next_of_kin_phone_no
              next_of_kin_relation: $next_of_kin_relation
              tax_number: $tax_number
              bvn: $bvn
              tell_no: $tell_no
              fax_no: $fax_no
              organization_type: $organization_type
              cot_rate: $cot_rate
              deposit_rate: $deposit_rate
              debit_rate: $debit_rate
            ) {
              status
              account_number
              message
            }
          }
        `,
      });
      //console.log(newAccountResponse, "*********");
      //const { updateUserAccount } = newAccountResponse.data;
      if (newAccountResponse.error) {
        //console.log(newAccountResponse.error);
        toast.error(newAccountResponse.errors[0].message);
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: {
            pageLoading: false,
          },
        });
      } else {
        //console.log(newAccountResponse.data)
        toast.success("Account created successfully");
        dispatch({
          type: SET_ACCOUNT_FIELD,
          payload: {
            pageLoading: false,
          },
        });
      }
    } catch (err) {
      //console.log(err, "Error here%%%%%%");
      toast.error(err.message);
      dispatch({
        type: SET_ACCOUNT_FIELD,
        payload: {
          pageLoading: false,
        },
      });
      resetForm({});
    }
  };
};


export const getGLAccounts = async () => {
  const { user } = window.localStorage;
const { org_id } = JSON.parse(user);
  try {
    const response = await apolloClient.query({
      variables: {
        org_code:org_id,
      },
      query: gql`
        query GetGLAccounts($org_code: String!) {
          getGLAccount(org_code: $org_code) {
            status
            data{
              id
              subclass
              code
              description
              tags
              created 
              expiration
              type
              currency
              root
              parent

            }
          }
        }
      `,
    });
    if (response.errors) {
      toast.error(response.errors);
    } else {
      return response.data.getGLAccount;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import {
  editMyProfile,
  addNewUser,
  changePassword,
} from '../../../../reducers/home';
import { toast } from 'react-toastify';
import Loader from '../../Transactions/Loader';
import { listPermission } from '../../../../actions/permission';
import {
  objectReset,
  inputValidation,
} from '../../../../actions/inputValidation';
import { getConfig, updateConfig } from '../../../../actions/postingAction';

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUserSaveBtn: false,
      config: {
        _id: '',
        postingLimit: '',
        authorizationLimit: '',
        operationDate: '',
      },
      user: {
        firstname: '',
        lastname: '',
        email: '',
        gender: '',
        phone: '',
        user_type: '',
      },
      newUser: {
        firstname: '',
        lastname: '',
        email: '',
        staff_id: '',
        branch_code: '',
        phone: '',
        user_type: '',
        gender: '',
        password: '',
        re_password: '',
      },
      changePassword: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      loading: false,
    };
  }
  fillEditMeForm = (text, type) => {
    let editMe = this.state.editMe;
    editMe[type] = text;
    this.setState({ editMe });
  };
  fillNewUserForm = (text, type) => {
    let newUser = this.state.newUser;
    newUser[type] = text;
    this.setState({ newUser });
  };
  fillConfigForm = (text, type) => {
    let config = this.state.config;
    config[type] = text;
    this.setState({ config });
  };

  fillChangePassword = (text, type) => {
    let changePassword = this.state.changePassword;
    changePassword[type] = text;
    this.setState({ changePassword });
  };

  editMyProfile = () => {
    const { email, firstname, lastname, phone, gender } = this.state.editMe;
    const { _id } = this.state.user;

    const payload = {
      email,
      _id,
      phone,
      firstname,
      lastname,
      gender,
    };

    const checkInput = inputValidation({
      email,
      firstname,
      lastname,
      phone,
      gender,
    });
    if (checkInput.result) {
      this.setState({ editMeSaveBtn: true, loading: true }, () => {
        //console.log('payload', payload);
        this.props.editMyProfile(payload);
      });
    } else {
      this.setState({ loginError: `${checkInput.message}` }, () => {
        toast.error(`${this.state.loginError}`);
      });
    }
  };
  changeMyPassword = () => {
    const { changePassword } = this.state;
    const { confirm_password, new_password } = changePassword;
    const { _id } = this.state.user;
    const payload = {
      ...changePassword,
      _id,
    };
    const checkInput = inputValidation({ ...changePassword });
    if (checkInput.result) {
      if (new_password === confirm_password) {
        this.setState({ loading: true }, () => {
          //console.log('payload', payload);
          this.props.changePassword(payload);
        });
      } else {
        this.setState({ loginError: 'check password and try again' }, () => {
          toast.error(`${this.state.loginError}`);
        });
      }
    } else {
      this.setState({ loginError: `${checkInput.message}` }, () => {
        toast.error(`${this.state.loginError}`);
      });
    }
  };

  addNewUser = () => {
    const { newUser, user } = this.state;
    const { re_password, password } = newUser;
    const { org_id, _id } = user;

    //console.log(newUser)



    const checkInput = inputValidation({ ...newUser });
    const payload = {
      ...newUser,
      org_id,
      _id,
    };

    //console.log(checkInput)

    if (checkInput.result) {
      if (password === re_password) {
        this.setState({ newUserSaveBtn: true, loading: true }, () => {
         // console.log('payload', payload);
          this.props.addNewUser(payload);
        });
      } else {
        this.setState({ loginError: 'check password and try again' }, () => {
          toast.error(`${this.state.loginError}`);
        });
      }
    } else {
      this.setState({ loginError: `${checkInput.message}` }, () => {
        toast.error(`${this.state.loginError}`);
      });
    }
  };

  handleEditMe = e => {
    e.preventDefault();
    this.editMyProfile();
  };
  handleChangePassword = e => {
    e.preventDefault();
    this.changeMyPassword();
  };
  handleCloseModal = () => {
    $('[data-dismiss=modal]').trigger({ type: 'click' });
  };

  handleGetConfig = async () => {
    const res = await getConfig()
    return res.getConfig
  }
  handleUpdateConfig = async () => {
    const userInfoFromStorage = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (userInfoFromStorage.user_type !== "Super_Admin") {
      toast.error("Not Authorized")
    } else {
      const { postingLimit, authorizationLimit, operationDate } = this.state.config
      const res = await updateConfig(postingLimit, authorizationLimit, operationDate, userInfoFromStorage.email)
      console.log(res)
      this.setState({ config: { ...res } });
    }
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    //console.log(user);
    const adminConfig = await this.handleGetConfig()
    console.log(adminConfig, 'adminConfig')
    this.setState({ user: { ...user }, editMe: { ...user }, config: { ...adminConfig } }, () => {
      //console.log(this.state.editMe);
    });
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showEditMe !== this.props.showEditMe) {
      //console.log('server', this.props.showEditMe);
      const { error, data } = this.props.showEditMe;
      if (data && Object.keys(data).length) {
        const { updateAdmin } = data;
        this.setState(
          {
            user: updateAdmin,
            editMe: updateAdmin,
            editMeSaveBtn: false,
            loading: false,
          },
          () => {
            //console.log('got here 1', data);
            localStorage.setItem('user', JSON.stringify(updateAdmin));
            this.handleCloseModal();
          }
        );
      } else if (error && Object.keys(error).length) {
        this.setState({ editMeSaveBtn: false, loading: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ editMeSaveBtn: false, loading: false }, () => {
          toast.error('Network error!');
        });
      }
    }
    if (prevProps.showNewUser !== this.props.showNewUser) {
      //console.log('server', this.props.showNewUser);
      const { error, data } = this.props.showNewUser;
      if (data && Object.keys(data).length) {
        // const { createAdmin } = data;
        this.setState(
          {
            newUserSaveBtn: false,
            newUser: objectReset(this.state.newUser),
            loading: false,
          },
          () => {
            toast.success('User added succesfully !!!');
            //console.log('got here 1', data);
            this.handleCloseModal();
          }
        );
      } else if (error && Object.keys(error).length) {
        this.setState({ newUserSaveBtn: false, loading: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ newUserSaveBtn: false, loading: false }, () => {
          toast.error('Network error!');
        });
      }
    }
    if (prevProps.showChangePassword !== this.props.showChangePassword) {
      //console.log('server', this.props.showChangePassword);
      const { error, data } = this.props.showChangePassword;
      if (data && Object.keys(data).length) {
        // const { createAdmin } = data;
        this.setState(
          {
            changePassword: objectReset(this.state.changePassword),
            loading: false,
          },
          () => {
            toast.success(data.changePassword);
            //console.log('got here 1');
          }
        );
      } else if (error && Object.keys(error).length) {
        this.setState({ newUserSaveBtn: false, loading: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ newUserSaveBtn: false, loading: false }, () => {
          toast.error('Network error!');
        });
      }
    }
    if (prevProps.showNewAdmin !== this.props.showNewAdmin) {
      const showNewAdmin = this.props.showNewAdmin;
      this.setState({ saveBtn: false }, () => {
        if (showNewAdmin.result) {
        }
      });
      //console.log(this.props.showNewAdmin);
    }
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      gender,
      phone,
      user_type,
    } = this.state.user;
    // ? this.state.user
    // : {};
    const {
      firstname: newUserFirstname,
      lastname: newUserLastname,
      email: newUserEmail,
      phone: newUserPhone,
      password: newUserPassword,
      re_password: newUserRePassword,
      user_type: newUserType,
      gender: newUserGender,
      staff_id: newUserStaffId,
      branch_code: newUserBranchCode,
    } = this.state.newUser;
    return (
      <div>
        {this.state.loading && <Loader />}
        <div className='p-2'>
          <nav>
            <div className='nav nav-tabs' id='nav-tab' drole='tablist'>
              <a
                className='nav-item nav-link active font-weight-bold'
                id='nav-profile-tab'
                data-toggle='tab'
                href='#nav-profile'
                role='tab'
                aria-controls='nav-profile'
                aria-selected='true'
                style={{ fontSize: 18, color: 'green' }}>
                Profile
              </a>
              <a
                className='nav-item nav-link font-weight-bold'
                id='nav-settings-tab'
                data-toggle='tab'
                href='#nav-settings'
                role='tab'
                aria-controls='nav-settings'
                aria-selected='false'
                style={{ fontSize: 18, color: 'green' }}>
                Change Password
              </a>
              <a
                className='nav-item nav-link font-weight-bold'
                id='nav-park-tab'
                data-toggle='tab'
                href='#nav-park'
                role='tab'
                aria-controls='nav-park'
                aria-selected='false'
                style={{ fontSize: 18, color: 'green' }}>
                Create Admin
              </a>
              {user_type == 'Super_Admin' && (
                <a
                  className='nav-item nav-link font-weight-bold'
                  id='nav-config-tab'
                  data-toggle='tab'
                  href='#nav-config'
                  role='tab'
                  aria-controls='nav-config'
                  aria-selected='false'
                  style={{ fontSize: 18, color: 'green' }}>
                  Config
                </a>
              )}
            </div>
          </nav>
          <div className='tab-content' id='nav-tabContent'>
            <div
              className='tab-pane fade show active'
              id='nav-profile'
              role='tabpanel'
              aria-labelledby='nav-profile-tab'>
              <div className='card text-center'>
                <h4 className='card-header text-dark'>YOUR PROFILE</h4>
                <div className='container mx-auto p-4 text-center'>
                  <i
                    className='fas fa-user-circle mb-3'
                    aria-hidden='true'
                    style={{
                      fontSize: 150,
                      color: 'green',
                      cursor: 'pointer',
                    }}
                  />
                  <h5 className='text-dark'>
                    <span className='text-secondary'>FULL NAME: </span>
                    {firstname} {lastname}
                  </h5>
                  <h5 className='text-dark'>
                    <span className='text-secondary'>EMAIL: </span>
                    {email}
                  </h5>
                  <h5 className='text-dark'>
                    <span className='text-secondary'>GENDER: </span>
                    {gender}
                  </h5>
                  <h5 className='text-dark'>
                    <span className='text-secondary'>PHONE NUMBER: </span>
                    {phone}
                  </h5>
                  <button
                    className='btn btn-success m-2'
                    type='button'
                    data-toggle='modal'
                    data-target='#exampleModal'>
                    Edit Profile
                  </button>
                  <div
                    className='modal fade'
                    id='exampleModal'
                    tabIndex='-1'
                    role='dialog'
                    aria-labelledby='exampleModalLabel'
                    aria-hidden='true'>
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title' id='exampleModalLabel'>
                            Edit Profile
                          </h5>
                          <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            aria-label='Close'>
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        <div className='modal-body container mx-5'>
                          <div className='d-flex flex-row m-2'>
                            <label
                              htmlFor='full-name'
                              className=' text-right font-weight-bold input-margin-right align-self-center mr-1'>
                              Full Name
                            </label>
                            <input
                              type='text'
                              placeholder='Firstname'
                              name='firstname'
                              value={
                                this.state.editMe && this.state.editMe.firstname
                                  ? this.state.editMe.firstname
                                  : ''
                              }
                              className='form-control col-md-3 input-margin'
                              onChange={text =>
                                this.fillEditMeForm(
                                  text.target.value,
                                  'firstname'
                                )
                              }
                            />
                            <input
                              type='text'
                              placeholder='Lastname'
                              name='lastname'
                              value={
                                this.state.editMe && this.state.editMe.lastname
                                  ? this.state.editMe.lastname
                                  : ''
                              }
                              className='form-control col-md-3 input-margin'
                              onChange={text =>
                                this.fillEditMeForm(
                                  text.target.value,
                                  'lastname'
                                )
                              }
                            />
                          </div>
                          <div className='d-flex flex-row-center m-2'>
                            <label
                              htmlFor='name'
                              className='font-weight-bold align-self-center float-left text-right'
                              style={{ marginRight: 50 }}>
                              Email
                            </label>
                            <input
                              type='email'
                              placeholder='Email'
                              name='email'
                              value={
                                this.state.editMe && this.state.editMe.email
                                  ? this.state.editMe.email
                                  : ''
                              }
                              className='form-control m-2 col-md-6'
                              onChange={text =>
                                this.fillEditMeForm(text.target.value, 'email')
                              }
                            />
                          </div>
                          <div className='d-flex flex-row m-2'>
                            <label
                              htmlFor='number'
                              className='font-weight-bold align-self-center float-left text-right'
                              style={{ marginRight: 14 }}>
                              Phone Number
                            </label>
                            <input
                              type='number'
                              placeholder='Phone number'
                              name='phonenumber'
                              value={
                                this.state.editMe && this.state.editMe.phone
                                  ? this.state.editMe.phone
                                  : ''
                              }
                              className='form-control m-2 col-md-5'
                              onChange={text =>
                                this.fillEditMeForm(text.target.value, 'phone')
                              }
                            />
                          </div>
                          <div className='d-flex flex-row m-2'>
                            <label
                              htmlFor='number'
                              className='font-weight-bold align-self-center float-left text-right'
                              style={{ marginRight: 34 }}>
                              Gender
                            </label>
                            <select
                              className='form-control m-2 col-md-6'
                              id='exampleFormControlSelect1'
                              value={
                                this.state.editMe && this.state.editMe.gender
                                  ? this.state.editMe.gender
                                  : ''
                              }
                              onChange={text =>
                                this.fillEditMeForm(text.target.value, 'gender')
                              }>
                              <option value='Male'>Male</option>
                              <option value='Female'>Female</option>
                            </select>
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            type='button'
                            className='btn btn-success'
                            onClick={this.handleEditMe}
                            disabled={this.state.disableBtn}>
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-settings'
              role='tabpanel'
              aria-labelledby='nav-settings-tab'>
              <div className='p-4 px-5'>
                <form action='/action_page.php' className='w-50 m-auto d-block'>
                  <div className='form-group '>
                    <label htmlFor='oldPassword' type='oldPassword'>
                      Old Password:
                    </label>
                    <input
                      type='password'
                      placeholder='Old Password'
                      name='old_password'
                      value={this.state.changePassword.old_password}
                      className='form-control'
                      id='old_password'
                      onChange={text =>
                        this.fillChangePassword(
                          text.target.value,
                          'old_password'
                        )
                      }
                    />
                  </div>

                  <div className='form-group '>
                    <label htmlFor='newPassword' type='newPassword'>
                      New Password:
                    </label>
                    <input
                      type='password'
                      placeholder='New Password'
                      name='new_password'
                      value={this.state.changePassword.new_password}
                      className='form-control mb-3'
                      id='new_password'
                      onChange={text =>
                        this.fillChangePassword(
                          text.target.value,
                          'new_password'
                        )
                      }
                    />
                  </div>
                  <div className='form-group '>
                    <label htmlFor='confirmPassword' type='confirmPassword'>
                      Confirm Password:
                    </label>
                    <input
                      type='password'
                      placeholder='confirm Password'
                      name='confirm_password'
                      value={this.state.changePassword.confirm_password}
                      className='form-control mb-3'
                      id='confirm_password'
                      onChange={text =>
                        this.fillChangePassword(
                          text.target.value,
                          'confirm_password'
                        )
                      }
                    />
                  </div>
                  <button
                    type='submit'
                    onClick={this.handleChangePassword}
                    className='btn btn-success m-auto d-block'>
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-park'
              role='tabpanel'
              aria-labelledby='nav-park-tab'>
              <div className='text-center formLeftRight'>
                <h4 className='pt-3'>Admin type: {user_type}</h4>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='text'
                    placeholder='Firstname'
                    name='firstname'
                    className='form-control col-5 m-1'
                    value={newUserFirstname}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'firstname')
                    }
                  />
                  <input
                    type='text'
                    placeholder='Lastname'
                    name='lastname'
                    className='form-control col-5 m-1'
                    value={newUserLastname}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'lastname')
                    }
                  />
                </div>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='email'
                    placeholder='Email'
                    name='email'
                    className='form-control col-5 m-1'
                    value={newUserEmail}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'email')
                    }
                  />
                  <input
                    type='number'
                    placeholder='Phone Number'
                    name='phone'
                    className='form-control col-5 m-1'
                    value={newUserPhone}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'phone')
                    }
                  />
                </div>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='number'
                    placeholder='Staff Id'
                    name='staffId'
                    className='form-control col-5 m-1'
                    value={newUserStaffId}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'staff_id')
                    }
                  />
                  <input
                    type='number'
                    placeholder='Branch Code'
                    name='branchCode'
                    className='form-control col-5 m-1'
                    value={newUserBranchCode}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'branch_code')
                    }
                  />
                </div>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <select
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'user_type')
                    }
                    value={newUserType}
                    className='form-control col-5 m-1 btn btn-white border border-3'>
                    <option value=''>Choose user type</option>
                    {listPermission(8) && (
                      <option value='Super_Admin'>Super-Admin</option>
                    )}
                    {listPermission(7) && (
                      <option value='Authorizer'>Authorizer</option>
                    )}
                    {listPermission(6) && (
                      <option value='Basic_Admin'>Basic-Admin</option>
                    )}
                    {listPermission(5) && (
                      <option value='Operational_Staff'>
                        Operational-Staff
                      </option>
                    )}
                    {listPermission(4) && (
                      <option value='Super_Agent'>Super-Agent</option>
                    )}
                    {listPermission(3) && (
                      <option value='Basic_Agent'>Basic-Agent</option>
                    )}
                    {listPermission(2) && (
                      <option value='Teller'>Teller</option>
                    )}
                    <option value='Customer'>Customer</option>
                  </select>
                  <select
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'gender')
                    }
                    value={newUserGender}
                    className='form-control col-5 m-1 btn btn-white border border-3'>
                    <option>Gender</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </select>
                </div>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='password'
                    placeholder='Password'
                    name='password'
                    className='form-control col-5 m-1 align-self-start'
                    value={newUserPassword}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 'password')
                    }
                  />
                  <input
                    type='password'
                    placeholder='Re-Password'
                    name='re-password'
                    className='form-control col-5 m-1 align-self-start'
                    value={newUserRePassword}
                    onChange={text =>
                      this.fillNewUserForm(text.target.value, 're_password')
                    }
                  />
                </div>

                <div className='d-flex flex-row justify-content-center m-3'>
                  <button
                    disabled={this.state.newUserSaveBtn}
                    onClick={() => this.addNewUser()}
                    className='btn btn-success'
                    type='button'>
                    SAVE
                  </button>
                </div>
              </div>
            </div>
            <div
              className='tab-pane fade'
              id='nav-config'
              role='tabpanel'
              aria-labelledby='nav-config-tab'>
              <div className='text-center formLeftRight'>
                <h4 className='pt-3'>Admin type: {user_type}</h4>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='text'
                    placeholder='Posting Limit'
                    name='postingLimit'
                    className='form-control col-5 m-1'
                    value={this.state.config.postingLimit}
                    onChange={text =>
                      this.fillConfigForm(text.target.value, 'postingLimit')
                    }
                  />
                </div>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='text'
                    placeholder='Authorization Limit'
                    name='authorizationLimit'
                    className='form-control col-5 m-1'
                    value={this.state.config.authorizationLimit}
                    onChange={text =>
                      this.fillConfigForm(text.target.value, 'authorizationLimit')
                    }
                  />
                </div>
                <div className='d-flex flex-row justify-content-center mb-3'>
                  <input
                    type='date'
                    placeholder='Operation Date'
                    name='operationDate'
                    className='form-control col-5 m-1'
                    value={this.state.config.operationDate}
                    onChange={text =>
                      this.fillConfigForm(text.target.value, 'operationDate')
                    }
                  />
                </div>

                <div className='d-flex flex-row justify-content-center m-3'>
                  <button
                    disabled={this.state.newUserSaveBtn}
                    onClick={() => this.handleUpdateConfig()}
                    className='btn btn-success'
                    type='button'>
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showNewAdmin: state.home.showNewAdmin,
  showEditMe: state.home.showEditMe,
  showNewUser: state.home.showNewUser,
  showChangePassword: state.home.showChangePassword,
});

const mapDispatchToProps = {
  editMyProfile,
  addNewUser,
  changePassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);

/*!
 * spur-template - An admin template based on Bootstrap 4
 * Version v1.0.2
 * Copyright 2016 - 2019 Alexander Rechsteiner
 * https://hackerthemes.com
 */
import $ from "jquery";

const mobileBreakpoint = window.matchMedia("(max-width: 991px )");

$(document).ready(function(){
    $(".dash-nav-dropdown-toggle").click(function(){
        $(this).closest(".dash-nav-dropdown")
            .toggleClass("show")
            .find(".dash-nav-dropdown")
            .removeClass("show");

        $(this).parent()
            .siblings()
            .removeClass("show");
    });

    $(".menu-toggle").click(function(){
        if (mobileBreakpoint.matches) {
            console.log('got here!!!')
            $(".dash-nav").toggleClass("mobile-show");
        } else {
            $(".dash").toggleClass("dash-compact");
        }
    });

    $(".searchbox-toggle").click(function(){
        $(".searchbox").toggleClass("show");
    });

    $(".dash-nav-item").click(function () {
        $(".dash-nav-item").removeClass("active-bar");
        // $(".tab").addClass("active"); // instead of this do the below 
        $(this).addClass("active-bar");   
        $(".dash-nav").toggleClass("mobile-show");
    });

    $(".goto-profile").click(function () {
        $(".dash-nav-item").removeClass("active-bar");
        $(".settings").addClass("active-bar");  
        $(".dash-nav").toggleClass("mobile-show");
    })

    // $(".top-notification").click(function () {
    //     $(".dash-nav-item").removeClass("active-bar");
    //     $(".settings").addClass("active-bar");  
    //     $(".dash-nav").toggleClass("mobile-show");
    // })

    // Dev utilities
    // $("header.dash-toolbar .menu-toggle").click();
    // $(".searchbox-toggle").click();
});

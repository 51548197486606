import constants from './actionConstants';

const { PAGE_LOADING, ERROR } = constants;
const initialState = {
  loading: false,
  error: null
}

const PageLoaderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PAGE_LOADING :
      return Object.assign({}, state, { loading: payload });
    case ERROR:
      return Object.assign({}, state, { error: payload });
    default: return state;
  }
}

export default PageLoaderReducer;
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import constants from './actionConstants';
import update from 'react-addons-update';
const _DEV = 'localhost';
let URL_PATH = '/api/graphql';
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}
const { GET_DASHBOARD_DETAILS } = constants;

export const getDashboardDetails = payload => {
  let queryConcat = [];

  const { orgId, userId } = payload;
  if (userId) {
    queryConcat.push(`user_id: "${userId}"`)  
  }

  queryConcat = queryConcat.join(', ');

  const client = new ApolloClient({
    uri: `${URL_PATH}`
  });
  return dispatch => {
    client
      .query({
        query: gql`
          query {
            getDashboardDetails(org_id: "${orgId}", ${queryConcat}) {
              transfer_count
              bills_paid
              airtime_count
              wallet_balance
              balance_history{
              id
              user_id
              balance
              transaction_type
              amount
              reason
              credit
              created_at
            }
            virtual_account_numbers{
              virtual_account_name
              virtual_account_no
            }
          }
        }
        `
      })
      .then(
        response => {
          dispatch({
            type: GET_DASHBOARD_DETAILS,
            payload: response.data.getDashboardDetails
          });
        },
        err => console.log('Theres error in json')
      );
  };
};

const populateDashboardState = (state, action) => {
  return update(state, {
    dashboardDetails: {
      $set: action.payload
    }
  });
};

const ACTION_HANDLERS = {
  [GET_DASHBOARD_DETAILS]: populateDashboardState
};
const initialState = {};

export default function DashboardReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

const constant = {
  GET_SHOW_CHANGE: 'GET_SHOW_CHANGE',
  GET_TODO: 'GET_TODO',
  GET_USERSLIST: 'GET_USERSLIST',
  GET_APP_USERSLIST: 'GET_APP_USERSLIST',
  GET_CAR_DETAILS: 'GET_CAR_DETAILS',
  GET_ADMIN_STATUS: 'GET_ADMIN_STATUS',
  GET_EDIT_ME: 'GET_EDIT_ME',
  GET_EDIT_USER: 'GET_EDIT_USER',
  GET_ADD_NEW_USER: 'GET_ADD_NEW_USER',
  GET_NEW_ADMIN: 'GET_NEW_ADMIN',
  GET_TRANSACTION_STATUS: 'GET_TRANSACTION_STATUS',
  GET_CREATE_TRANSACTION: 'GET_CREATE_TRANSACTION',
  GET_ACCOUNT_DETAILS: 'GET_ACCOUNT_DETAILS',
  GET_ICAD_ACCOUNT_DETAILS: 'GET_ICAD_ACCOUNT_DETAILS',
  GET_USER_RECCURING: 'GET_USER_RECCURING',
  CREATE_SETUP_RECCURRING: 'CREATE_SETUP_RECCURRING',
  GET_ACCOUNT_NAME: 'GET_ACCOUNT_NAME',
  CREATE_TRANSFER: 'CREATE_TRANSFER',
  GET_BALANCE_HISTORY: 'GET_BALANCE_HISTORY',
  GET_DASHBOARD_DETAILS: 'GET_DASHBOARD_DETAILS',
  PAGE_LOADING: 'PAGE_LOADING',
  ERROR: 'ERROR',
  GET_CHANGE_PASSWORD: 'GET_CHANGE_PASSWORD',
  UPDATE_TRANSFER_STATUS: 'UPDATE_TRANSFER_STATUS',
  RETRY_TRANSFER: 'RETRY_TRANSFER',
  TRANSACTION_POSTING_LOADING: 'TRANSACTION_POSTING_LOADING',
  TRANSACTION_POSTING_SUCCESS: 'TRANSACTION_POSTING_SUCCESS',
  TOGGLE_POSTING_MODAL: 'TOGGLE_POSTING_MODAL',
  LOADING_STATEMENT: 'LOADING_STATEMENT',
  STATEMENT_AVAILABLE: 'STATEMENT_AVAILABLE',
  ACCOUNT_STATEMENT: 'ACCOUNT_STATEMENT',
  GET_MANDATE_DETAILS: 'GET_MANDATE_DETAILS',
  CREATE_SETUP_MANDATE: 'CREATE_SETUP_MANDATE',
  UPDATE_MANDATE_STATUS: 'UPDATE_MANDATE_STATUS',
};

export default constant;

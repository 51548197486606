export default [
  {
    name: 'Access Bank',
    slug: 'access-bank',
    code: '044',
    longcode: '044150149',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 1,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'ALAT by WEMA',
    slug: 'alat-by-wema',
    code: '035A',
    longcode: '035150103',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 27,
    createdAt: '2017-11-15T12:21:31.000Z',
    updatedAt: '2017-11-15T12:21:31.000Z',
  },
  {
    name: 'ASO Savings and Loans',
    slug: 'aso-savings-and-loans',
    code: '401',
    longcode: '',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 63,
    createdAt: '2018-09-23T05:52:38.000Z',
    updatedAt: '2018-09-23T05:52:38.000Z',
  },
  {
    name: 'Citibank Nigeria',
    slug: 'citibank-nigeria',
    code: '023',
    longcode: '023150005',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 2,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Chams Mobile/Kegow/Maxisoft',
    slug: 'chams-mobile',
    code: '303',
    longcode: '303',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 303,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Diamond Bank',
    slug: 'diamond-bank',
    code: '063',
    longcode: '063150162',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 3,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Ecobank Nigeria',
    slug: 'ecobank-nigeria',
    code: '050',
    longcode: '050150010',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 4,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Ekondo Microfinance Bank',
    slug: 'ekondo-microfinance-bank',
    code: '562',
    longcode: '',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 64,
    createdAt: '2018-09-23T05:55:06.000Z',
    updatedAt: '2018-09-23T05:55:06.000Z',
  },
  {
    name: 'Enterprise Bank',
    slug: 'enterprise-bank',
    code: '084',
    longcode: '084150015',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 5,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Fidelity Bank',
    slug: 'fidelity-bank',
    code: '070',
    longcode: '070150003',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 6,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'First Bank of Nigeria',
    slug: 'first-bank-of-nigeria',
    code: '011',
    longcode: '011151003',
    gateway: 'emandate-disabled',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 7,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'First City Monument Bank',
    slug: 'first-city-monument-bank',
    code: '214',
    longcode: '214150018',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 8,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Guaranty Trust Bank',
    slug: 'guaranty-trust-bank',
    code: '058',
    longcode: '058152036',
    gateway: 'ibank',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 9,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Heritage Bank',
    slug: 'heritage-bank',
    code: '030',
    longcode: '030159992',
    gateway: 'etz',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 10,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Jaiz Bank',
    slug: 'jaiz-bank',
    code: '301',
    longcode: '301080020',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 22,
    createdAt: '2016-10-10T17:26:29.000Z',
    updatedAt: '2016-10-10T17:26:29.000Z',
  },
  {
    name: 'Jubilee Life',
    slug: 'jubilee-life',
    code: '402',
    longcode: '402150000',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 801,
    createdAt: '2016-10-10T17:26:29.000Z',
    updatedAt: '2016-10-10T17:26:29.000Z',
  },
  {
    name: 'Keystone Bank',
    slug: 'keystone-bank',
    code: '082',
    longcode: '082150017',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 11,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'MainStreet Bank',
    slug: 'mainstreet-bank',
    code: '014',
    longcode: '014150331',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 12,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Parallex Bank',
    slug: 'parallex-bank',
    code: '526',
    longcode: '',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 26,
    createdAt: '2017-03-31T13:54:29.000Z',
    updatedAt: '2017-03-31T13:54:29.000Z',
  },
  {
    "id": 304,
    "name": "PremiumTrust Bank",
    "slug": "premiumtrust-bank-ng",
    "code": "105",
    "longcode": "000031",
    "gateway": "",
    "pay_with_bank": false,
    "active": true,
    "country": "Nigeria",
    "currency": "NGN",
    "type": "nuban",
    "is_deleted": false,
    "createdAt": "2022-06-01T14:16:02.000Z",
    "updatedAt": "2022-08-17T08:13:08.000Z"
},
{
  "id": 630,
  "name": "Prospects MFB",
  "slug": "prospects-mfb",
  "code": "229056",
  "longcode": "",
  "gateway": "",
  "pay_with_bank": false,
  "active": true,
  "country": "Nigeria",
  "currency": "NGN",
  "type": "nuban",
  "is_deleted": false,
  "createdAt": "2022-09-02T08:51:15.000Z",
  "updatedAt": "2023-09-05T16:02:21.000Z"
},
  {
    name: 'Providus Bank',
    slug: 'providus-bank',
    code: '101',
    longcode: '',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 25,
    createdAt: '2017-03-27T16:09:29.000Z',
    updatedAt: '2017-03-27T16:09:29.000Z',
  },
  {
    name: 'Polaris(Skye) Bank',
    slug: 'skye-bank',
    code: '076',
    longcode: '076151006',
    gateway: 'etz',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 13,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Stanbic IBTC Bank',
    slug: 'stanbic-ibtc-bank',
    code: '221',
    longcode: '221159522',
    gateway: 'etz',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 14,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Standard Chartered Bank',
    slug: 'standard-chartered-bank',
    code: '068',
    longcode: '068150015',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 15,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Sterling Bank',
    slug: 'sterling-bank',
    code: '232',
    longcode: '232150016',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 16,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Suntrust Bank',
    slug: 'suntrust-bank',
    code: '100',
    longcode: '',
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 23,
    createdAt: '2016-10-10T17:26:29.000Z',
    updatedAt: '2016-10-10T17:26:29.000Z',
  },
  {
    name: 'Union Bank of Nigeria',
    slug: 'union-bank-of-nigeria',
    code: '032',
    longcode: '032080474',
    gateway: 'etz',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 17,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'United Bank For Africa',
    slug: 'united-bank-for-africa',
    code: '033',
    longcode: '033153513',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 18,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Unity Bank',
    slug: 'unity-bank',
    code: '215',
    longcode: '215154097',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 19,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Wema Bank',
    slug: 'wema-bank',
    code: '035',
    longcode: '035150103',
    gateway: '',
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 20,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Zenith Bank',
    slug: 'zenith-bank',
    code: '057',
    longcode: '057150013',
    gateway: 'emandate',
    pay_with_bank: true,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 21,
    createdAt: '2016-07-14T10:04:29.000Z',
    updatedAt: '2016-07-14T10:04:29.000Z',
  },
  {
    name: 'Jekalo',
    slug: 'jekalo',
    code: '229199',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 22,
    createdAt: null,
    updatedAt: null,
  },
  {
    name: 'Aramoko MFB',
    slug: 'Aramoko MFB',
    code: '229004',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 23,
    createdAt: null,
    updatedAt: null,
  },
  {
    name: 'Octopus MFB',
    slug: 'Octopus MFB',
    code: '229005',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 24,
    createdAt: null,
    updatedAt: null,
  },
  {
    name: 'Supreme MFB',
    slug: 'Supreme MFB',
    code: '229027',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 25,
    createdAt: null,
    updatedAt: null,
  },
  {
    name: 'Abulesoro MFB',
    slug: 'Abulesoro MFB',
    code: '229183',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 26,
    createdAt: null,
    updatedAt: null,
  },
  {
    name: 'First Heritage MFB',
    slug: 'First Heritage MFB',
    code: '229021',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 27,
    createdAt: null,
    updatedAt: null,
  },
  {
    name: 'Ikire MFB',
    slug: 'Ikire MFB',
    code: '229009',
    longcode: null,
    gateway: null,
    pay_with_bank: false,
    active: true,
    is_deleted: null,
    country: 'Nigeria',
    currency: 'NGN',
    type: 'nuban',
    id: 28,
    createdAt: null,
    updatedAt: null,
  },
  {
    "name": "Globus Bank",
    "slug": "globus-bank",
    "code": "00103",
    "longcode": "103015001",
    "gateway": null,
    "pay_with_bank": false,
    "active": true,
    "is_deleted": false,
    "country": "Nigeria",
    "currency": "NGN",
    "type": "nuban",
    "id": 70,
    "createdAt": "2020-02-11T15:38:57.000Z",
    "updatedAt": "2020-02-11T15:38:57.000Z"
},
  {
            "name": "Safe Haven MFB",
            "slug": "safe-haven-mfb-ng",
            "code": "51113",
            "longcode": "51113",
            "gateway": "",
            "pay_with_bank": false,
            "active": true,
            "is_deleted": false,
            "country": "Nigeria",
            "currency": "NGN",
            "type": "nuban",
            "id": 286,
            "createdAt": "2022-02-18T13:11:59.000Z",
            "updatedAt": "2022-02-18T13:11:59.000Z"
        },
  {
            "name": "Kuda Bank",
            "slug": "kuda-bank",
            "code": "50211",
            "longcode": "",
            "gateway": "digitalbankmandate",
            "pay_with_bank": true,
            "active": true,
            "is_deleted": false,
            "country": "Nigeria",
            "currency": "NGN",
            "type": "nuban",
            "id": 67,
            "createdAt": "2019-11-15T17:06:54.000Z",
            "updatedAt": "2020-07-01T15:05:18.000Z"
        },
        {
          "id": 171,
          "name": "Opay",
          "slug": "paycom",
          "code": "999992",
          "longcode": "",
          "gateway": null,
          "pay_with_bank": false,
          "active": true,
          "country": "Nigeria",
          "currency": "NGN",
          "type": "nuban",
          "is_deleted": false,
          "createdAt": "2020-11-24T10:20:45.000Z",
          "updatedAt": "2020-11-24T10:20:54.000Z"
      },
      {
        "id": 688,
        "name": "Moniepoint MFB",
        "slug": "moniepoint-mfb-ng",
        "code": "50515",
        "longcode": "null",
        "gateway": null,
        "pay_with_bank": false,
        "active": true,
        "country": "Nigeria",
        "currency": "NGN",
        "type": "nuban",
        "is_deleted": false,
        "createdAt": "2023-03-20T12:53:58.000Z",
        "updatedAt": "2023-03-20T12:53:58.000Z"
    },
    {
      "id": 169,
      "name": "PalmPay",
      "slug": "palmpay",
      "code": "999991",
      "longcode": "",
      "gateway": null,
      "pay_with_bank": false,
      "active": true,
      "country": "Nigeria",
      "currency": "NGN",
      "type": "nuban",
      "is_deleted": false,
      "createdAt": "2020-11-24T09:58:37.000Z",
      "updatedAt": "2020-11-24T10:03:19.000Z"
  },
  {
    "id": 82,
    "name": "Carbon",
    "slug": "carbon",
    "code": "565",
    "longcode": "",
    "gateway": null,
    "pay_with_bank": false,
    "active": true,
    "country": "Nigeria",
    "currency": "NGN",
    "type": "nuban",
    "is_deleted": false,
    "createdAt": "2020-06-16T08:15:31.000Z",
    "updatedAt": "2021-08-05T15:25:01.000Z"
},
{
  "id": 629,
  "name": "Paystack-Titan",
  "slug": "titan-paystack",
  "code": "100039",
  "longcode": "",
  "gateway": "",
  "pay_with_bank": false,
  "active": true,
  "country": "Nigeria",
  "currency": "NGN",
  "type": "nuban",
  "is_deleted": false,
  "createdAt": "2022-09-02T08:51:15.000Z",
  "updatedAt": "2023-09-05T16:02:21.000Z"
},
{
  "id": 71,
  "name": "VFD Microfinance Bank Limited",
  "slug": "vfd",
  "code": "566",
  "longcode": "",
  "gateway": null,
  "pay_with_bank": false,
  "supports_transfer": true,
  "active": true,
  "country": "Nigeria",
  "currency": "NGN",
  "type": "nuban",
  "is_deleted": false,
  "createdAt": "2020-02-11T15:44:11.000Z",
  "updatedAt": "2020-10-28T09:42:08.000Z"
},
{
  "id": 738,
  "name": "Davenport MICROFINANCE BANK",
  "slug": "davenport-microfinance-bank-ng",
  "code": "51334",
  "longcode": "51334",
  "gateway": "",
  "pay_with_bank": false,
  "supports_transfer": true,
  "active": true,
  "country": "Nigeria",
  "currency": "NGN",
  "type": "nuban",
  "is_deleted": false,
  "createdAt": "2023-11-20T13:30:56.000Z",
  "updatedAt": "2023-11-20T13:30:56.000Z"
},
{
  "id": 180,
  "name": "Links MFB",
  "slug": "links-mfb",
  "code": "50549",
  "longcode": "",
  "gateway": null,
  "pay_with_bank": false,
  "supports_transfer": true,
  "active": true,
  "country": "Nigeria",
  "currency": "NGN",
  "type": "nuban",
  "is_deleted": false,
  "createdAt": "2021-07-12T14:41:18.000Z",
  "updatedAt": "2021-07-12T14:41:18.000Z"
}
];

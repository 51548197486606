import React from 'react';
import Spinner from 'react-spinner-material';
import './utils/Transfer.css';

const Loader = () => (
  <div className='mask d-flex justify-content-center align-items-center'>
    <Spinner size={60} spinnerColor={'#333'} spinnerWidth={2} visible={true} />
  </div>
);

export default Loader;
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import actionConstants from "../reducers/actionConstants";
import postConstants from "../constants/postingConstants";
const {
  POSTINGS,
  FETCTHING_POSTS,
  FETCHED,
  PENDING_POSTING_REQUEST,
  PENDING_POSTING_SUCCESS,
  PENDING_POSTING_FAIL,
} = postConstants;

const _DEV = "localhost";
let URL_PATH = "/api/graphql";

if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}

const apolloClient = new ApolloClient({
  uri: `${URL_PATH}`,
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token ? token : "",
      },
    });
  },
});

export const getAccountDetails = async (
  acct_number,
  bank_code,
  dashboard,
  origin
) => {
  try {
    const response = await apolloClient.query({
      variables: {
        acct_number,
        bank_code,
        dashboard,
        origin,
      },
      query: gql`
        query GetAccountInfo(
          $acct_number: String
          $bank_code: String!
          $dashboard: Boolean
          $origin: Boolean
        ) {
          getAccountName(
            acct_number: $acct_number
            bank_code: $bank_code
            dashboard: $dashboard
            origin: $origin
          ) {
            acct_name
            bank_name
            balance
          }
        }
      `,
    });
    return response.data;
  } catch (err) {
    toast.error(err.message);
  }
};

export const getPostingAccountDetails = async ({
  search_key,
  value,
  limit = 100,
  page = 1,
}) => {
  // return async (dispatch) => {
  try {
    const response = await apolloClient.query({
      variables: {
        search_key,
        value,
        limit,
        page,
      },
      query: gql`
        query getAccountDetails(
          $search_key: String!
          $value: String!
          $limit: Int!
          $page: Int!
        ) {
          getPostingAccountDetails(
            search_key: $search_key
            value: $value
            limit: $limit
            page: $page
          ) {
            error
            accounts {
              fullname
              internal
              nuban
            }
            pagination {
              current_page
              total_pages
              per_page
              limit
              previous_page
              next_page
              current_page_total
              total
            }
          }
        }
      `,
    });
    return response.data;
  } catch (err) {
    console.log(err.message);
    toast.error(err.message);
  }
};
// };

export const getAllPosting = ({ limit = 100, page = 1 }) => {
  return async (dispatch) => {
    const { user } = window.localStorage;

    const { org_id } = JSON.parse(user);
    try {
      dispatch({ type: FETCTHING_POSTS, payload: true });
      const response = await apolloClient.query({
        variables: {
          limit,
          page,
          org_id,
        },
        query: gql`
          query getPostings($limit: Int, $page: Int, $org_id: String) {
            getPostings(limit: $limit, page: $page, org_id: $org_id) {
              error
              postings {
                id
                status
                use_account
                account_name_check
                naration
                account_number
                balance
                account_name
                cash_transaction_type
                operation_date
                teller_cheque_no
                amount
                transaction_type
                transaction_cue
                value_date
                GL_account_code_id
                account_title
                staff_id
                stamp_duty_charge
                org_id
                created_at
              }
              pagination {
                current_page
                total_pages
                per_page
                limit
                previous_page
                next_page
                current_page_total
                total
              }
            }
          }
        `,
      });
      if (response.errors) return dispatch({ type: FETCHED, payload: false });

      dispatch({ type: FETCHED, payload: true });
      // console.log(response.data)
      return dispatch({ type: POSTINGS, payload: response.data.getPostings });
    } catch (err) {
      // console.log(err)
      toast.error(err.message);
    } finally {
      return dispatch({ type: FETCTHING_POSTS, payload: false });
    }
  };
};

export const getPendingPostings = ({
  use_op = "false",
  op_date = "",
  limit = 100,
  page = 1,
}) => {
  return async (dispatch) => {
    const { user } = window.localStorage;

    const { org_id } = JSON.parse(user);
    try {
      dispatch({
        type: PENDING_POSTING_REQUEST,
      });
      const response = await apolloClient.query({
        variables: {
          limit,
          page,
          org_id,
          use_op,
          op_date,
        },
        query: gql`
          query getPostings(
            $limit: Int
            $page: Int
            $org_id: String!
            $use_op: String
            $op_date: String
          ) {
            getPendingPostings(
              limit: $limit
              page: $page
              org_id: $org_id
              use_op: $use_op
              op_date: $op_date
            ) {
              status
              postings {
                id
                status
                use_account
                account_name_check
                naration
                account_number
                balance
                account_name
                cash_transaction_type
                operation_date
                teller_cheque_no
                amount
                transaction_type
                transaction_cue
                value_date
                GL_account_code_id
                account_title
                staff_id
                stamp_duty_charge
                org_id
                created_at
              }
              pagination {
                current_page
                total_pages
                per_page
                limit
                previous_page
                next_page
                current_page_total
                total
              }
            }
          }
        `,
      });
      if (response.errors) {
        console.log(response.errors);
        return dispatch({
          type: PENDING_POSTING_FAIL,
          payload: response.errors,
        });
      }
      // console.log({res:response.data.getPendingPostings});
      return dispatch({
        type: PENDING_POSTING_SUCCESS,
        payload: response.data.getPendingPostings,
      });
    } catch (err) {
      // console.log(err)
      toast.error(err.message);
    }
  };
};

export const approvePendingPosting = async (id, email) => {
  // const { user } = window.localStorage;

  // const { org_id } = JSON.parse(user);
  try {
    const response = await apolloClient.query({
      variables: {
        id,
        email,
      },
      query: gql`
        query approvePosting($id: Int!, $email: String!) {
          approvePendingPosting(id: $id, email: $email) {
            status
            post {
              id
              status
              use_account
              account_name_check
              naration
              account_number
              balance
              account_name
              cash_transaction_type
              operation_date
              teller_cheque_no
              amount
              transaction_type
              transaction_cue
              value_date
              GL_account_code_id
              account_title
              staff_id
              stamp_duty_charge
              org_id
              created_at
            }
          }
        }
      `,
    });
    if (response.errors) toast.error(response.errors[0].message);
    console.log(response.errors);
    return response.data.approvePendingPosting;
  } catch (err) {
    // console.log(err)
    toast.error(err.message);
  }
};

export const createPosting = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: actionConstants.TRANSACTION_POSTING_LOADING,
      payload: {
        pageLoading: true,
        openModal: false,
      },
    });

    try {
      const { user } = window.localStorage;

      const { org_id: orgId, staff_id } = JSON.parse(user);

      const {
        use_account,
        account_name_check,
        naration,
        account_number,
        balance,
        account_name,
        cash_transaction_type,
        operation_date,
        teller_cheque_no,
        amount,
        transaction_type,
        transaction_cue,
        value_date,
        GL_account_code_id,
        account_title,
        stamp_duty_charge,
      } = payload;

      const newPostingResponse = await apolloClient.mutate({
        variables: {
          use_account,
          account_name_check,
          naration,
          account_number,
          balance,
          account_name,
          cash_transaction_type,
          operation_date,
          teller_cheque_no,
          amount,
          transaction_type,
          transaction_cue,
          value_date,
          GL_account_code_id,
          account_title,
          stamp_duty_charge,
          staff_id,
          org_id: orgId,
        },
        mutation: gql`
          mutation createPosting(
            $use_account: String!
            $account_name_check: String!
            $naration: String!
            $account_number: String!
            $balance: String!
            $account_name: String!
            $cash_transaction_type: String
            $operation_date: String!
            $teller_cheque_no: Int!
            $amount: Int!
            $transaction_type: String
            $transaction_cue: String
            $value_date: String
            $GL_account_code_id: String
            $account_title: String
            $staff_id: String!
            $stamp_duty_charge: String
            $org_id: String!
          ) {
            createPosting(
              use_account: $use_account
              account_name_check: $account_name_check
              naration: $naration
              account_number: $account_number
              balance: $balance
              account_name: $account_name
              cash_transaction_type: $cash_transaction_type
              operation_date: $operation_date
              teller_cheque_no: $teller_cheque_no
              amount: $amount
              transaction_type: $transaction_type
              transaction_cue: $transaction_cue
              value_date: $value_date
              GL_account_code_id: $GL_account_code_id
              account_title: $account_title
              staff_id: $staff_id
              stamp_duty_charge: $stamp_duty_charge
              org_id: $org_id
            ) {
              status
              message
            }
          }
        `,
      });
      console.log(newPostingResponse, "*********");
      //const { updateUserAccount } = newAccountResponse.data;
      if (newPostingResponse.error) {
        console.log(newPostingResponse.error);
        toast.error(newPostingResponse.errors[0].message);
        dispatch({
          type: actionConstants.TRANSACTION_POSTING_LOADING,
          payload: {
            pageLoading: false,
            openModal: false,
          },
        });
      } else {
        //console.log(newAccountResponse.data)

        dispatch({
          type: actionConstants.TRANSACTION_POSTING_SUCCESS,
          payload: {
            pageLoading: false,
            openModal: true,
          },
        });
      }
    } catch (err) {
      console.log(err.message, "Error here%%%%%%");
      dispatch({
        type: actionConstants.TRANSACTION_POSTING_LOADING,
        payload: {
          pageLoading: false,
          openModal: false,
        },
      });
      toast.error(err.message);
    }
  };
};

export const toggleModal = () => {
  return async (dispatch) => {
    dispatch({
      type: actionConstants.TOGGLE_POSTING_MODAL,
    });
  };
};

export const getOperationDate = async (handleLoading) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query {
          getOperationDate
        }
      `,
    });
    return response.data;
  } catch (err) {
    if (handleLoading) {
      handleLoading(false);
    }

    toast.error(err.message);
  }
};

export const getConfig = async (handleLoading) => {
  try {
    const response = await apolloClient.query({
      query: gql`
        query {
          getConfig {
            _id
            postingLimit
            authorizationLimit
            operationDate
          }
        }
      `,
    });
    return response.data;
  } catch (err) {
    if (handleLoading) {
      handleLoading(false);
    }
    toast.error(err.message);
  }
};
export const updateConfig = async (
  postingLimit,
  authorizationLimit,
  operationDate,
  email
) => {
  // const { user } = window.localStorage;

  // const { org_id } = JSON.parse(user);
  try {
    const response = await apolloClient.query({
      variables: {
        postingLimit,
        authorizationLimit,
        operationDate,
        email,
      },
      query: gql`
        query updateConfig(
          $postingLimit: Int!
          $authorizationLimit: Int!
          $operationDate: String!
          $email: String!
        ) {
          updateConfig(
            postingLimit: $postingLimit
            authorizationLimit: $authorizationLimit
            operationDate: $operationDate
            email: $email
          ) {
            postingLimit
            authorizationLimit
            operationDate
          }
        }
      `,
    });
    if (response.errors) {
      toast.error(response.errors[0].message);
    } else {
      toast.success("Updated successfully");
    }
    return response.data.updateConfig;
  } catch (err) {
    toast.error(err.message);
  }
};

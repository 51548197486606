import { useStore } from 'react-redux';
import axios from 'axios';

const apiURL = 'https://app.jekalo.com/api/remita';

export const saveSalaryHistories = (salaryHistories) => {
  return {
    type: 'SAVE_SALARY_HISTORIES',
    salaryHistories,
  };
};
export const saveLoanHistories = (loanHistories) => {
  return {
    type: 'SAVE_LOAN_HISTORIES',
    loanHistories,
  };
};

export const getSalaries = (orgId, cb) => {
  // const user = useStore.getState().authReducer.userData;
  return (dispatch) => {
    axios
      .get(apiURL + '/salary', { params: orgId })
      .then((response) => {
        if (response.status === 200 && response.data.status === 200) {
          console.log(response);
          const salaryHistories = response.data;
          // console.log('paysalaryHistoriesload', salaryHistories);
          dispatch(saveSalaryHistories(salaryHistories));
          cb && cb('success');
        }
      })
      .catch((err) => {
        // console.log(err.response);
        cb && cb('failed');
      });
  };
};

export const getUsersLoan = (orgId, cb) => {
  // const user = useStore.getState().authReducer.userData;
  return (dispatch) => {
    axios
      .get(apiURL + '/loan', { params: orgId })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          const loanHistories = response.data;
          console.log('paysalaryHistoriesload', loanHistories);
          dispatch(saveLoanHistories(loanHistories));
          cb && cb('success');
        }
      })
      .catch((err) => {
        // console.log(err.response);
        cb && cb('failed');
      });
  };
};
export const postUserLoan = (payload, cb) => {
  // const user = useStore.getState().authReducer.userData;
  return (dispatch) => {
    axios
      .post(apiURL + '/loan', payload)
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          const loanHistories = response.data;
          console.log('paysalaryHistoriesload', loanHistories);
          cb && cb('success');
        }
      })
      .catch((err) => {
        console.log(err.response);
        cb && cb('failed');
      });
  };
};

export const getUserSalary = (payload, cb) => {
  // const user = useStore.getState().authReducer.userData;
  return (dispatch) => {
    axios
      .post(apiURL + '/salary', payload)
      .then((response) => {
        if (response.status === 200) {
          // console.log(response);
          const salaryUpdateData = response.data.data.salaryPaymentDetails;
          cb && cb('success', salaryUpdateData);
        }
      })
      .catch((err) => {
        // console.log(err.response);
        cb && cb('failed', []);
      });
  };
};

import { combineReducers } from 'redux';
import HomeReducer from '../reducers/home';
import TransfersReducer from '../reducers/transfers';
import DashboardReducer from '../reducers/dashboard';
import PageLoaderReducer from '../reducers/pageLoaderReducer';
import billsPaymentReducer from './../reducers/billsPaymentReducer';
import accountsReducer from './../reducers/accountsReducer';
import loanReducer from '../reducers/loanReducer';
import { postingReducer, pendingPostingsReducer } from '../reducers/postingReducer'
export const makeRootReducer = () => {
  return combineReducers({
    home: HomeReducer,
    transfer: TransfersReducer,
    dashboard: DashboardReducer,
    loading: PageLoaderReducer,
    billsPayment: billsPaymentReducer,
    accounts: accountsReducer,
    loan: loanReducer,
    posting: postingReducer,
    pendingPostings: pendingPostingsReducer,
  });
};

export default makeRootReducer;

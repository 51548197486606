import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './utils/Transfer.css';

const FilterView = props => {
  const { onFilter, label = 'Filter', exclude = [] } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [state, setState] = useState({
    queryFilter: '',
    dateFilter: '',
    specificDate: '',
    endDate: '',
    startDate: '',
    status: ''
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setState({ ...state, ...{ [name]: value } });
  };

  const handleDirectChange = (name, value) => {
    setState({ ...state, ...{ [name]: value } });
  };

  const handleReset = () => {
    setState({
      queryFilter: '',
      dateFilter: '',
      specificDate: '',
      endDate: '',
      startDate: '',
      status: ''
    });
  };

  const getDay = value => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = () => {
    const payload = { ...state };
    payload.specificDate =
      payload.specificDate !== '' ? getDay(payload.specificDate) : '';
    payload.endDate = payload.endDate !== '' ? getDay(payload.endDate) : '';
    payload.startDate =
      payload.startDate !== '' ? getDay(payload.startDate) : '';
    // console.log(payload);
    onFilter(payload);
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className='mt-2 mb-2'>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          outline
          className='text-uppercase d-flex align-items-center'
          color='success'
          caret
        >
          <small className='font-weight-bold'>{label}</small>
        </DropdownToggle>
        <DropdownMenu>
          {!exclude.includes('keyword') && (
            <DropdownItem className='pb-0 pt-0' header>
              <FormGroup>
                <Label for='queryFilter'>
                  <small>Search by keyword</small>
                </Label>
                <Input
                  type='text'
                  style={{ width: 225 }}
                  name='queryFilter'
                  id='queryFilter'
                  value={state.queryFilter}
                  onChange={handleChange}
                  placeholder='Enter keyword'
                />
              </FormGroup>
            </DropdownItem>
          )}
          {!exclude.includes('status') && (
            <DropdownItem className='pb-0 pt-0' header>
              <FormGroup>
                <Label for='exampleSelect'>
                  <small>Status</small>
                </Label>
                <Input
                  type='select'
                  style={{ width: 225 }}
                  name='status'
                  value={state.status}
                  id='exampleSelect'
                  onChange={handleChange}
                >
                  <option value=''>All</option>
                  <option value='Initiated'>Initiated</option>
                  <option value='Pending'>Pending</option>
                  <option value='Failed'>Failed</option>
                  <option value='Successful'>Successful</option>
                  <option value='Rejected'>Rejected</option>
                </Input>
              </FormGroup>
            </DropdownItem>
          )}
          {!exclude.includes('date') && (
            <DropdownItem className='pb-0 pt-0' header>
              <FormGroup>
                <Label for='daySelect'>
                  <small>Date</small>
                </Label>
                <Input
                  type='select'
                  style={{ width: 225 }}
                  name='dateFilter'
                  value={state.dateFilter}
                  id='daySelect'
                  onChange={handleChange}
                >
                  <option value=''>All</option>
                  <option value='interval'>Interval</option>
                  <option value='specific'>Specific day</option>
                  <option value='today'>Today</option>
                </Input>
              </FormGroup>
            </DropdownItem>
          )}
          {state.dateFilter === 'specific' && (
            <DropdownItem className='pb-0 pt-0' header>
              <FormGroup>
                <Label for='specificDate'>
                  <small>Specific date</small>
                </Label>
                <div>
                  <DatePicker
                    customInput={
                      <Input
                        type='text'
                        style={{ width: 225 }}
                        placeholder='select date'
                        className='form-control'
                      />
                    }
                    selected={state.specificDate}
                    onChange={date => handleDirectChange('specificDate', date)}
                  />
                </div>
              </FormGroup>
            </DropdownItem>
          )}
          {state.dateFilter === 'interval' && (
            <DropdownItem className='pb-0 pt-0' header>
              <FormGroup>
                <Label for='startDate'>
                  <small>Interval</small>
                </Label>
                <div className='d-flex justify-content-around'>
                  <DatePicker
                    customInput={
                      <Input
                        type='text'
                        style={{ width: 110 }}
                        placeholder='select date'
                        className='form-control'
                        id='startDate'
                      />
                    }
                    selected={state.startDate}
                    onChange={date => handleDirectChange('startDate', date)}
                  />
                  <div className='d-flex justify-content-center align-items-center'>
                    -
                  </div>
                  <DatePicker
                    customInput={
                      <Input
                        type='text'
                        style={{ width: 110 }}
                        placeholder='select date'
                        className='form-control'
                        id='startDate'
                      />
                    }
                    selected={state.endDate}
                    onChange={date => handleDirectChange('endDate', date)}
                  />
                </div>
              </FormGroup>
            </DropdownItem>
          )}
          <DropdownItem
            className='pb-0 pt-0 d-flex justify-content-between'
            header
          >
            <Button outline color='success' size='sm' onClick={handleReset}>
              Reset
            </Button>
            <Button color='success' size='sm' onClick={handleSubmit}>
              {label}
            </Button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default FilterView;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSalaries, getUserSalary } from '../../../actions/loanActions';
import Loader from '../Transactions/Loader';
import { Link } from 'react-router-dom';
const data = [
  {
    accountNo: '1232029282',
    bankCode: '057',
    companyName: 'Spring Nigeria',
    countSalaryCr: '6',
    customerId: 'SPR0571232029282',
    customerName: 'James Oni Peters',
    salaryPaymentDetails: [
      {
        amount: '12000.00',
        paymentDate: '2017-09-06',
      },
      {
        amount: '12000.00',
        paymentDate: '2017-08-06',
      },
      {
        amount: '12000.00',
        paymentDate: '2017-07-06',
      },
    ],
  },
];

class Salary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedSalary: [],
      selectedPhoneNumber: '',
      updateSalaryLoader: false,
    };
  }

  componentDidMount() {
    const { org_id: orgId } = this.handleUserData();
    const payload = { orgId };
    console.log('salary payload', payload);
    this.props.getSalaries(payload);
  }

  handleUserData = () => {
    const { user } = window.localStorage;
    return JSON.parse(user);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loan.salaryHistories !== this.props.loan.salaryHistories) {
      this.setState({ loading: false });
    }
  }

  handleGetUserSalary = (phoneNumber) => {
    const { org_id: orgId } = this.handleUserData();
    console.log('phoneNumber', phoneNumber);
    const payload = {
      orgId,
      phoneNumber: this.state.selectedPhoneNumber,
      authorisationChannel: 'USSD',
    };
    this.setState({ updateSalaryLoader: true }, () => {
      this.props.getUserSalary(payload, this.handleCallback);
    });
  };

  handleCallback = (msg, data) => {
    if (msg === 'success') {
      console.log('data', data);
      this.setState({ selectedSalary: data, updateSalaryLoader: false });
    } else {
      console.log(msg, data);
      this.setState({ updateSalaryLoader: false });
    }
  };

  activeUsersLists = () => {
    const activeUsers = this.props.loan.salaryHistories.data.map(
      (data, index) => (
        <tr key={index}>
          <td>{data.customerId}</td>
          <td>{data.accountNumber}</td>
          <td>{data.phoneNumber}</td>
          <td>{data.companyName}</td>
          <td>
            <div className='dropdown'>
              <button
                className='btn btn-secondary dropdown-toggle'
                type='button'
                id='dropdownMenuButton'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                Options
              </button>
              <div
                className='dropdown-menu p-3'
                aria-labelledby='dropdownMenuButton'
                style={{ width: 300 }}
              >
                <div>
                  <button
                    onClick={() =>
                      this.setState({
                        selectedSalary: data.salaryPaymentDetails,
                        selectedPhoneNumber: data.phoneNumber,
                      })
                    }
                    type='button'
                    class='btn btn-light btn-block'
                    data-toggle='modal'
                    data-target='#salaryDetails'
                  >
                    <p>View salary Details</p>
                  </button>
                  <Link
                    to={{
                      pathname: '/loan/customerloan',
                      state: { fromSalary: data },
                    }}
                  >
                    <button type='button' class='btn btn-light btn-block'>
                      <p>Loan disbursement</p>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    );

    return activeUsers;
  };
  render() {
    console.log('this.props state', this.props.loan);

    return (
      <div className='p-4'>
        {this.state.loading && <Loader />}
        <div className='dropdown'>
          <button
            className='btn btn-secondary'
            data-toggle='modal'
            data-target='#enterPhone'
          >
            Add new user
          </button>
        </div>

        <div className='p-4'>
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th>{'Customer Id'}</th>
                  <th>{'Account No'}</th>
                  <th>{'Phone number'}</th>
                  <th>{'Company Name'}</th>
                  <th>{''}</th>
                </tr>
              </thead>
              <tbody>{this.activeUsersLists()}</tbody>
            </table>
          </div>
        </div>

        {/* Enter Phone number modal */}
        <div
          className='modal fade'
          id='enterPhone'
          data-backdrop='static'
          data-keyboard='false'
          tabindex='-1'
          role='dialog'
          aria-labelledby='enterPhoneLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='enterPhoneLabel'>
                  Phone Number
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  data-target='#enterPhone'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label for='phoneNumber'>Enter Phone Number</label>
                    <input
                      type='tel'
                      className='form-control'
                      id='phoneNumber'
                      aria-describedby='phoneNumberHelp'
                    />
                    <small id='phoneNumberHelp' class='form-text text-muted'>
                      OTP code would be sent to the user for confirmation.
                    </small>
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  data-dismiss='modal'
                  className='btn btn-primary'
                  data-toggle='modal'
                  data-target='#confirmOtp'
                >
                  Send Sms
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Confirm OTP code modal */}
        <div
          className='modal fade'
          id='confirmOtp'
          data-backdrop='static'
          data-keyboard='false'
          tabindex='-1'
          role='dialog'
          aria-labelledby='confirmOtpLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='confirmOtpLabel'>
                  OTP Code
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <form>
                  <div className='form-group'>
                    <label for='otpCode'>Enter Otp Code</label>
                    <input
                      type='email'
                      className='form-control'
                      id='otpCode'
                      aria-describedby='otpCodeHelp'
                    />
                    <small id='otpCodeHelp' className='form-text text-muted'>
                      Enter the OTP code sent to the user or click retry if not
                      received.
                    </small>
                  </div>
                </form>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-dismiss='modal'
                >
                  Confirm OTP
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* View Salary Details modal */}
        <div
          className='modal fade'
          id='salaryDetails'
          data-backdrop='static'
          data-keyboard='false'
          tabindex='-1'
          role='dialog'
          aria-labelledby='salaryDetailsLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='salaryDetailsLabel'>
                  Salary Details
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <ul className='list-group'>
                  {this.state.selectedSalary.map((data, index) => (
                    <li
                      key={`${index}`}
                      className='list-group-item'
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div>
                        <p>Amount</p>
                        <p>{data.amount}</p>
                      </div>
                      <div>
                        <p>Date</p>
                        <p>{data.paymentDate}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='modal-footer'>
                <button
                  onClick={() => this.handleGetUserSalary(data)}
                  type='button'
                  className='btn btn-primary'
                  // data-dismiss='modal'
                >
                  {this.state.updateSalaryLoader ? (
                    <i class='fa fa-spinner fa-spin'></i>
                  ) : (
                    'Refresh list'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loan: state.loan,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSalaries: (payload, cd) => dispatch(getSalaries(payload, cd)),
    getUserSalary: (payload, cd) => dispatch(getUserSalary(payload, cd)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Salary);

import React from 'react';
import { Container, Table } from 'reactstrap';
import { NairaUnit } from '../../../Utils/Units';

const Records=(entry=[])=>{

  const sum = entry.journal_records.reduce((accumulation, current_record) => {
    if (current_record.type === "DEBIT") {
      accumulation["sum_of_debit"] = accumulation["sum_of_debit"]
        ? accumulation["sum_of_debit"] + +current_record.amount
        : +current_record.amount;
    } else {
      accumulation["sum_of_credit"] = accumulation["sum_of_credit"]
        ? accumulation["sum_of_credit"] + +current_record.amount
        : +current_record.amount;
    }

    return accumulation;
  }, {});


  return( <Container>
    <div className='row'>
      <div className='col-sm-6 col-md-4 mb-2'>
        <div className="font-weight-bold text-uppercase">Operation Date</div>
        <div>{entry.operationDate}</div>
      </div>

      <div className='col-sm-6 col-md-4 mb-2'>
        <div className="font-weight-bold text-uppercase">Status</div>
        <div>{entry.status}</div>
      </div>

      <div className='col-sm-6 col-md-4 mb-2'>
        <div className="font-weight-bold text-uppercase">Branch</div>
        <div>{entry.branch}</div>
      </div>     

      <div className='col-sm-6 col-md-4 mb-2'>
        <div className="font-weight-bold text-uppercase">Folio</div>
        <div>{entry.folio}</div>
      </div>

      <div className='col-sm-6 col-md-4 mb-2'>
        <div className="font-weight-bold text-uppercase">Created On</div>
        <div>{entry.created_date}</div>
      </div>

      <div className='col-sm-6 mb-2'>
        <div className="font-weight-bold text-uppercase">Narration</div>
        <div>{entry.narration}</div>
      </div>

      <div className='col-sm-6 mb-2'>
        <div className="font-weight-bold text-uppercase">Note</div>
        <div>{entry.narration}</div>
      </div>

    </div>
    <hr />
    <Table borderless>
      <thead>
        <tr className="text-uppercase">
          <th>
            <small className="font-weight-bold">Account Code</small>
          </th>
          <th>
            <small className="font-weight-bold">Sub Narration</small>
          </th>
          <th>
            <small className="font-weight-bold">Amount</small>
          </th>
          <th>
            <small className="font-weight-bold">Type</small>
          </th>
        </tr>
      </thead>
      <tbody>
        {entry.journal_records.map((item, index) => (
          <tr key={index}>
            <td>{item.account_code}</td>
            <td>{item.sub_narration}</td>
            <td>{NairaUnit(item.amount)}</td>
            <td>{item.type}</td>
          </tr>
        ))}
      </tbody>
    </Table>

    <div className="row">
      <div className="col-sm-6">
        <span className="pr-2"> Sum Of Debit: </span>
        <span>
          {NairaUnit(sum.sum_of_debit)}
        </span>
      </div>
      <div className="col-sm-6">
        <span className="pr-2"> Sum Of Credit: </span>
        <span>
          {NairaUnit(sum.sum_of_credit)}
        </span>
      </div>
    </div>
    </Container>
  )
}

export default Records;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { sendBulkSms } from './../../../actions/bulkSmsAction';
import Loader from './../Transactions/Loader';

class BulkSms extends Component {
  state = {
    loading: false,
    orgId: '',
    accountTypes: [
      'savings',
      'personal current',
      'corporate current',
      'fixed deposit',
      'target',
      'loan',
      'esusu',
    ],
    messageTextArea: '',
    selectedAccounts: [
      'savings',
      'personal current',
      'corporate current',
      'fixed deposit',
      'target',
      'loan',
      'esusu',
    ],
    addedAccountNumbers: [],
    excludedAccountNumbers: [],
    addedAccountRange: [],
    excludedAccountRange: [],
    addedPhoneNumbers: [],
    fromAccountNumber: '',
    toAccountNumber: '',
    accountNumbersTextArea: '',
    phoneNumbersTextArea: '',
    selectedSendBy: 'account',
  };

  componentDidMount = () => {
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    this.setState({ orgId });
  };

  changeSmsMode = (event) => {
    this.setState({ selectedSendBy: event.target.value });
  };

  handleTextAreaChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  addPhoneNumbers = () => {
    let { addedPhoneNumbers, phoneNumbersTextArea } = this.state;
    let newAddedPhoneNumbers = [...addedPhoneNumbers];
    phoneNumbersTextArea = phoneNumbersTextArea.replace(/,/g, ' ');
    phoneNumbersTextArea = phoneNumbersTextArea.replace(/\s+/g, ' ').trim();

    if (phoneNumbersTextArea === '') {
      return;
    }

    let phoneNumberArray = phoneNumbersTextArea.split(' ');
    phoneNumberArray.forEach((pna) => {
      newAddedPhoneNumbers.push(pna);
    });

    newAddedPhoneNumbers = Array.from(new Set(newAddedPhoneNumbers));
    this.setState({
      addedPhoneNumbers: newAddedPhoneNumbers,
      phoneNumbersTextArea: '',
    });
  };

  includeAccountNumbers = (type = 'include') => {
    let {
      addedAccountNumbers,
      excludedAccountNumbers,
      accountNumbersTextArea,
    } = this.state;
    let newAddedAccountNumbers =
      type === 'include'
        ? [...addedAccountNumbers]
        : [...excludedAccountNumbers];
    accountNumbersTextArea = accountNumbersTextArea.replace(/,/g, ' ');
    accountNumbersTextArea = accountNumbersTextArea.replace(/\s+/g, ' ').trim();
    let accountNumberArray = accountNumbersTextArea.split(' ');

    if (accountNumbersTextArea === '') {
      return;
    }
    accountNumberArray.forEach((pna) => {
      newAddedAccountNumbers.push(pna);
    });

    newAddedAccountNumbers = Array.from(new Set(newAddedAccountNumbers));

    type === 'include'
      ? this.setState({
          addedAccountNumbers: newAddedAccountNumbers,
          accountNumbersTextArea: '',
        })
      : this.setState({
          excludedAccountNumbers: newAddedAccountNumbers,
          accountNumbersTextArea: '',
        });
  };

  removePhoneNumber = (index) => {
    let { addedPhoneNumbers } = this.state;
    addedPhoneNumbers.splice(index, 1);
    this.setState({ addedPhoneNumbers: addedPhoneNumbers });
  };

  removeAddedAccountNumber = (index) => {
    let { addedAccountNumbers } = this.state;
    addedAccountNumbers.splice(index, 1);
    this.setState({ addedAccountNumbers: addedAccountNumbers });
  };

  removeExcludedAccountNumber = (index) => {
    let { excludedAccountNumbers } = this.state;
    excludedAccountNumbers.splice(index, 1);
    this.setState({ excludedAccountNumbers: excludedAccountNumbers });
  };

  addOrExcludeRange = (type = 'include') => {
    const {
      fromAccountNumber,
      toAccountNumber,
      addedAccountRange,
      excludedAccountRange,
    } = this.state;
    switch (type) {
      case 'exclude': {
        if (fromAccountNumber.length > 0 && toAccountNumber.length > 0) {
          excludedAccountRange.push({
            from: fromAccountNumber,
            to: toAccountNumber,
          });
          this.setState({
            excludedAccountRange: excludedAccountRange,
            fromAccountNumber: '',
            toAccountNumber: '',
          });
        }
        break;
      }
      default: {
        if (fromAccountNumber.length > 0 && toAccountNumber.length > 0) {
          addedAccountRange.push({
            from: fromAccountNumber,
            to: toAccountNumber,
          });
          this.setState({
            addedAccountRange: addedAccountRange,
            fromAccountNumber: '',
            toAccountNumber: '',
          });
        }
      }
    }
  };

  removeAddedAccountRange = (index) => {
    let { addedAccountRange } = this.state;
    addedAccountRange.splice(index, 1);
    this.setState({ addedAccountRange: addedAccountRange });
  };

  removeExcludedAccountRange = (index) => {
    let { excludedAccountRange } = this.state;
    excludedAccountRange.splice(index, 1);
    this.setState({ excludedAccountRange: excludedAccountRange });
  };

  sendBulkSms = () => {
    const {
      selectedSendBy,
      messageTextArea,
      addedAccountNumbers,
      excludedAccountNumbers,
      addedAccountRange,
      excludedAccountRange,
      addedPhoneNumbers,
      selectedAccounts,
      orgId,
    } = this.state;
    const { sendBulkSms: propSendBulkSms } = this.props;
    const newExcludeRange = [];
    const newIncludeRange = [];
    excludedAccountRange.forEach((excludedAccount) => {
      newExcludeRange.push(`${excludedAccount.from}-${excludedAccount.to}`);
    });
    addedAccountRange.forEach((includedAccount) => {
      newIncludeRange.push(`${includedAccount.from}-${includedAccount.to}`);
    });

    this.setState({ loading: true });
    switch (selectedSendBy) {
      case 'account': {
        propSendBulkSms({
          orgId,
          queryType: selectedSendBy,
          message: messageTextArea,
          includeList: addedAccountNumbers,
          excludeList: excludedAccountNumbers,
          includeRange: newIncludeRange,
          excludeRange: newExcludeRange,
          phoneList: [],
          accountTypes: selectedAccounts,
        })
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
        break;
      }
      case 'phone': {
        propSendBulkSms({
          orgId,
          queryType: selectedSendBy,
          message: messageTextArea,
          includeList: [],
          excludeList: [],
          includeRange: [],
          excludeRange: [],
          phoneList: addedPhoneNumbers,
          accountTypes: [],
        })
          .then((res) => {
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
        break;
      }
      default: {
      }
    }
  };

  clearAll = () => {
    this.setState({
      accountTypes: [
        'savings',
        'personal current',
        'corporate current',
        'fixed deposit',
        'target',
        'loan',
        'esusu',
      ],
      messageTextArea: '',
      selectedAccounts: [
        'savings',
        'personal current',
        'corporate current',
        'fixed deposit',
        'target',
        'loan',
        'esusu',
      ],
      addedAccountNumbers: [],
      excludedAccountNumbers: [],
      addedAccountRange: [],
      excludedAccountRange: [],
      addedPhoneNumbers: [],
      fromAccountNumber: '',
      toAccountNumber: '',
      accountNumbersTextArea: '',
      phoneNumbersTextArea: '',
      selectedSendBy: 'account',
    });
  };

  render() {
    const {
      accountTypes,
      selectedAccounts,
      selectedSendBy,
      phoneNumbersTextArea,
      accountNumbersTextArea,
      fromAccountNumber,
      toAccountNumber,
      messageTextArea,
      addedPhoneNumbers,
      addedAccountNumbers,
      excludedAccountNumbers,
      addedAccountRange,
      excludedAccountRange,
      loading,
    } = this.state;

    const selectedPhoneNumbers = addedPhoneNumbers.map((apn, index) => {
      return (
        <button key={index} className={`m-1 c-button`}>
          <i
            className={`fas fa-close mr-2`}
            onClick={() => this.removePhoneNumber(index)}
          ></i>
          {apn}
        </button>
      );
    });

    const showAddedAccountNumbers = addedAccountNumbers.map((apn, index) => {
      return (
        <button key={index} className={`m-1 c-button`}>
          <i
            className={`fas fa-close mr-2`}
            onClick={() => this.removeAddedAccountNumber(index)}
          ></i>
          {apn}
        </button>
      );
    });

    const showExcludedAccountNumbers = excludedAccountNumbers.map(
      (apn, index) => {
        return (
          <button key={index} className={`m-1 c-button`}>
            <i
              className={`fas fa-close mr-2`}
              onClick={() => this.removeExcludedAccountNumber(index)}
            ></i>
            {apn}
          </button>
        );
      }
    );
    const showAddedAccountRanges = addedAccountRange.map(
      (addedRange, index) => {
        return (
          <button key={index} className={`m-1 c-button`}>
            <i
              className={`fas fa-close mr-2`}
              onClick={() => this.removeAddedAccountRange(index)}
            ></i>
            {`${addedRange.from} - ${addedRange.to}`}
          </button>
        );
      }
    );

    const showExcludedAccountRanges = excludedAccountRange.map(
      (excludedRange, index) => {
        return (
          <button key={index} className={`m-1 c-button`}>
            <i
              className={`fas fa-close mr-2`}
              onClick={() => this.removeExcludedAccountRange(index)}
            ></i>
            {`${excludedRange.from} - ${excludedRange.to}`}
          </button>
        );
      }
    );

    const buttonLists = accountTypes.map((act, index) => {
      const isSelected = selectedAccounts.includes(act);
      return (
        <button
          key={index}
          className={`m-1 c-button ${isSelected ? 'selected' : ''}`}
          onClick={() => {
            let newSelectedAccounts = [...selectedAccounts];
            const index = newSelectedAccounts.indexOf(act.toLowerCase());
            if (index > -1) {
              newSelectedAccounts.splice(index, 1);
            } else {
              newSelectedAccounts.push(act.toLowerCase());
            }
            this.setState({ selectedAccounts: newSelectedAccounts });
          }}
        >
          <i className={`fas fa-check mr-2 ${isSelected ? '' : 'd-none'}`}></i>
          {act}
        </button>
      );
    });

    return (
      <Container>
        <Row>
          <Col>{loading && <Loader />}</Col>
        </Row>
        <Row>
          <Col md={7}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='exampleSelect'>
                    <small>Message</small>
                  </Label>
                  <Input
                    type='textarea'
                    name='messageTextArea'
                    id='message'
                    className='textarea'
                    onChange={this.handleTextAreaChange}
                    value={messageTextArea}
                    placeholder='Type in your message'
                  />
                </FormGroup>
                <FormGroup>
                  <Button color='primary' onClick={this.sendBulkSms}>
                    Send
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Summary</h4>
                {messageTextArea.length > 0 && (
                  <div className='d-flex mb-3'>
                    <span
                      className='text-primary'
                      style={{ minWidth: '200px' }}
                    >
                      Message
                    </span>
                    <div className='pl-4'>{messageTextArea}</div>
                  </div>
                )}

                {addedPhoneNumbers.length > 0 && (
                  <div className='d-flex mb-3'>
                    <span
                      className='text-primary'
                      style={{ minWidth: '200px' }}
                    >
                      Phone Numbers
                    </span>
                    <div className='pl-4 pill-list-container'>
                      {selectedPhoneNumbers}
                    </div>
                  </div>
                )}
                {addedAccountNumbers.length > 0 && (
                  <div className='d-flex mb-3'>
                    <span
                      className='text-primary'
                      style={{ minWidth: '130px' }}
                    >
                      Account Numbers
                    </span>
                    <div className='pl-4 pill-list-container'>
                      {showAddedAccountNumbers}
                    </div>
                  </div>
                )}
                {excludedAccountNumbers.length > 0 && (
                  <div className='d-flex mb-3'>
                    <span
                      className='text-primary'
                      style={{ minWidth: '130px' }}
                    >
                      Excluded Accounts
                    </span>
                    <div className='pl-4 pill-list-container'>
                      {showExcludedAccountNumbers}
                    </div>
                  </div>
                )}

                {addedAccountRange.length > 0 && (
                  <div className='d-flex mb-3'>
                    <span
                      className='text-primary'
                      style={{ minWidth: '130px' }}
                    >
                      Added Ranges
                    </span>
                    <div className='pl-4 pill-list-container'>
                      {showAddedAccountRanges}
                    </div>
                  </div>
                )}

                {excludedAccountRange.length > 0 && (
                  <div className='d-flex mb-3'>
                    <span
                      className='text-primary'
                      style={{ minWidth: '130px' }}
                    >
                      Excluded Ranges
                    </span>
                    <div className='pl-4 pill-list-container'>
                      {showExcludedAccountRanges}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row>
              <Col>
                <div className='d-flex text-center'>
                  <Button size={'sm'} onClick={this.clearAll}>
                    CLEAR ALL FIELDS
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='exampleSelect'>
                    <small>Select recipients by</small>
                  </Label>
                  <Input
                    type='select'
                    name='select'
                    onChange={this.changeSmsMode}
                    value={selectedSendBy}
                  >
                    <option value='account'>Account numbers</option>
                    <option value='phone'>Phone numbers</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            {selectedSendBy === 'account' && (
              <Row>
                <Col>{buttonLists}</Col>
                <Col xs={12}>
                  <hr className='mt-3 mb-3' />
                </Col>
              </Row>
            )}

            {selectedSendBy === 'phone' && (
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type='textarea'
                      name='phoneNumbersTextArea'
                      value={phoneNumbersTextArea}
                      onChange={this.handleTextAreaChange}
                      className='textarea'
                      placeholder='please input the recipient phone numbers'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      color='primary'
                      size='sm'
                      className='mr-2'
                      onClick={this.addPhoneNumbers}
                    >
                      Add numbers
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            )}

            {selectedSendBy === 'account' && (
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      type='textarea'
                      name='accountNumbersTextArea'
                      value={accountNumbersTextArea}
                      className='textarea'
                      onChange={this.handleTextAreaChange}
                      placeholder='please input the recipient account numbers'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      color='primary'
                      size='sm'
                      className='m-1'
                      onClick={() => this.includeAccountNumbers()}
                    >
                      Include Accounts
                    </Button>
                    <Button
                      color='danger'
                      size='sm'
                      className='m-1'
                      onClick={() => this.includeAccountNumbers('exclude')}
                    >
                      Exclude Accounts
                    </Button>
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <hr className='mt-3 mb-3' />
                </Col>
              </Row>
            )}
            {selectedSendBy === 'account' && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label for='fromAccountNumber'>
                      <small>From</small>
                    </Label>
                    <Input
                      type='text'
                      name='fromAccountNumber'
                      value={fromAccountNumber}
                      onChange={this.handleTextAreaChange}
                      id='from'
                      placeholder='From'
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for='toAccountNumber'>
                      <small>To</small>
                    </Label>
                    <Input
                      type='text'
                      name='toAccountNumber'
                      value={toAccountNumber}
                      onChange={this.handleTextAreaChange}
                      id='to'
                      placeholder='To'
                    />
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <Button
                      color='primary'
                      size='sm'
                      className='m-1'
                      onClick={this.addOrExcludeRange}
                    >
                      Include Range
                    </Button>
                    <Button
                      color='danger'
                      size='sm'
                      className='m-1'
                      onClick={() => this.addOrExcludeRange('exclude')}
                    >
                      Exclude Range
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  sendBulkSms,
};

export default connect(null, mapDispatchToProps)(BulkSms);

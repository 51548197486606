import React, { useState } from 'react';
import { Input, Button } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import './utils/Transfer.css';

const Pagination = props => {
  const [searchInput, setSearchInput] = useState('');
  const { pageHistory, onPageChange } = props;
  const { currentPage, totalPages } = pageHistory;
  return (
    <div className='mt-1 mb-2'>
      <div
        className='d-flex center align-items-center'
        style={{ fontSize: 16 }}
      >
        <span className='ml-2 mr-1'>{currentPage}</span>
        <span className='ml-1 mr-1'>-</span>
        <span className='ml-1 mr-2 text-uppercase text-success'>
          {totalPages}
        </span>
        <Button
          outline
          color='success'
          size='sm'
          disabled={currentPage <= 1}
          onClick={() => onPageChange(currentPage - 1)}
          style={{
            width: 30,
            height: 30,
            borderRadius: 0
          }}
        >
          <i className='fas fa-chevron-left'></i>
        </Button>
        <Button
          outline
          color='success'
          size='sm'
          disabled={currentPage >= totalPages}
          onClick={() => onPageChange(currentPage + 1)}
          style={{
            width: 30,
            height: 30,
            borderRadius: 0
          }}
        >
          <i className='fas fa-chevron-right'></i>
        </Button>
        <Input
          type='text'
          className='p-1 text-center'
          name='searchInput'
          color='success'
          placeholder='-'
          value={searchInput}
          onChange={event => {
            setSearchInput(event.target.value);
          }}
          style={{
            width: 45,
            height: 30,
            borderLeftWidth: 0,
            borderRightWidth: 0
          }}
        />
        <Button
          color='success'
          size='sm'
          disabled={
            !/^\d+$/.test(searchInput) ||
            parseInt(searchInput) <= 0 ||
            parseInt(searchInput) > totalPages
          }
          onClick={() => onPageChange(parseInt(searchInput))}
          style={{
            width: 40,
            height: 30,
            borderRadius: 0
          }}
        >
          <span>Go</span>
        </Button>
      </div>
    </div>
  );
};

export default Pagination;

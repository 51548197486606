import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

const _DEV = 'localhost';
let URL_PATH = '/api/graphql';
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}

/**
 * Handles sending of bulk SMS
 * @param {object} payload - The object of the state array
 */
export const sendBulkSms = (payload) => {
  return async dispatch => {
    const { orgId, queryType, message, includeList, excludeList, includeRange, excludeRange, phoneList, accountTypes } = payload;
    try {
      const client = new ApolloClient({
        uri: `${URL_PATH}`,
      });
      const response = await client.mutate({
        mutation: gql`
          mutation {
            sendBulkSms (
              org_id: "${orgId}"
              query_type: "${queryType || ''}",
              message: "${message || ''}",
              include_list: "${includeList}",
              exclude_list: "${excludeList}",
              include_range: "${includeRange}",
              exclude_range: "${excludeRange}",
              phone_list: "${phoneList}",
              account_types: "${accountTypes}"
            ) {
              status
              message
              error
            }
          }
        `,
      });
      const { data } = response;
      const { sendBulkSms } = data;

      if (sendBulkSms.error) {
        toast.error(sendBulkSms.error);
        return false;
      }
      toast.success(sendBulkSms.message);
      return true;
    } catch (err) {
      toast.error("Network error occured. Please try again");
    }
  }
};

export const requery = (payload) => {
  return async (dispatch) => {
    const {
      id, orgId
    } = payload;
    try {
      const client = new ApolloClient({
        uri: `${URL_PATH}`,
      });
      const response = await client.mutate({
        mutation: gql`
          mutation {
            requery (
              org_id: "${orgId}",
              id: ${id}
            ) {
              status
              message
              error
            }
          }
        `,
      });
      const { data } = response;
      const { requery } = data;

      if (requery.error) {
        toast.error(requery.error);
        return false;
      }
      toast.success(requery.message);
      return true;
    } catch (err) {
      toast.error('Network error occured. Please try again');
    }
  };
}

export const smsRequestsHistory = (payload) => {
         return async (dispatch) => {
           const { orgId, page = 1 } = payload;
           try {
             const client = new ApolloClient({
               uri: `${URL_PATH}`,
             });
             const response = await client.query({
               query: gql`
          query {
            smsRequestsHistory (
              org_id: "${orgId}"
              page: ${page},
            ) {
              status
              data{
                id
                message
                total_messages
                delivered_messages
                created_at
              }
              pagination{
                current_page
                total_pages
              }
              error
            }
          }
        `,
             });
             const { data } = response;
             const { smsRequestsHistory } = data;

             if (smsRequestsHistory.error) {
               toast.error(smsRequestsHistory.error);
               return false;
             }
             return smsRequestsHistory;
           } catch (err) {
             console.log(err);
             toast.error('Network error occured. Please try again');
           }
         };
       };

export const smsRecipientsHistory = (payload) => {
return async (dispatch) => {
  const {
    smsRequestId,
    page=1
  } = payload;
  try {
    const client = new ApolloClient({
      uri: `${URL_PATH}`,
    });
    const response = await client.query({
      query: gql`
          query {
            smsRecipientsHistory (
              sms_request_id: ${smsRequestId}
              page: ${page},
            ) {
              status
              data {
                id
                sms_requests_id
                message_ref
                status
                phone
                created_at
                updated_at
              }
              error
              pagination {
                current_page
                total_pages
              }
            }
          }
        `,
    });
    const { data } = response;
    const { smsRecipientsHistory } = data;

    if (smsRecipientsHistory.error) {
      toast.error(smsRecipientsHistory.error);
      return false;
    }
    return smsRecipientsHistory;
  } catch (err) {
    toast.error('Network error occured. Please try again');
  }
};
}
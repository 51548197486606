const accountTypes = [
  'Savings',
  'Personal Current',
  'Corporate Current',
  'Fixed Deposit',
  'Target',
  'Loan',
  'Esusu'
];

export default accountTypes;

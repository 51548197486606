import React from 'react';


// export let path, current_page_total, total, length, search,  limit, current, movement, range, last_page;
/**
 * 
 * @param current_page The current page. Optional, default is 1 
 * @param movement The offset movement when current page go up or down. Optional default is 3
 * @param range The range of displayed pages link e.g 1 2 3 >> or << 4 5 6 7 8 or << 4 5 6 7 >>.
 *  Optional, default is 5 
 * @param total_pages The initial length of estimated pages from server from offest and limit. Required.
 */
export const paginator=(total_pages, current_page=1, movement=3, range=5)=> {
  range= total_pages<range?total_pages:range;
  const range_array= Array(range).fill(1);
  let pages = range_array.map((page,index)=>page+index);
  // console.log(pages);
  let off_set = current_page-movement;
  if(off_set < 1){
    return pages.map((page,index)=>({
      text:(page),
      id:`${page}_${index}`
    }));    
  }else{
    off_set= off_set+range > total_pages? total_pages-range: off_set;
    return pages.map((page,index)=>({
      text:(page+off_set),
      id:`${page}_${index}`
    }));
  }  
}

const PostingPagination=({ 
    total_pages,
    current_page,
    limit,
    current_page_total,
    total,
    movement=3,
    range=5,
    caller,
    caller_params={}
  })=>{

  if(!total_pages) return null;

  const paginate=(page)=>{
    if(!caller) return;
    const pagiload = {page,limit,...caller_params};
    console.log(pagiload);
    caller(pagiload);
  }

  const pages = paginator( total_pages, current_page, movement, range );

  return(
    <div style={{backgroundColor:'#F4F4F4'}} className='row py-1'>
    <div className="col-12 col-sm-6 d-flex align-items-center ">
      <span>{(limit*(current_page-1))+1} - {limit*(current_page-1)+current_page_total} of {total}</span>
      <span className="ml-4">Page {current_page} of {total_pages}</span>

    </div>

      <div className="col-12 text-right col-sm-6">
        {/* Show initial page */}
        <button className="page-item btn"
          disabled={((current_page-(movement)) < 1)}   
          onClick={()=>{paginate(1)}}     
        >
          &lt;&lt;
        </button>
        {/* The inbetween pages */}
          {pages && pages.map((page)=>(
            <button key={page.id}
              className={
                `${page.text === current_page?'page-item active':'page-item'} btn`
              }
              disabled={page.text === current_page} 
              onClick={()=>{paginate(page.text)}}
            >
              {page.text}
            </button>       
            )
        )}      
        <button className="page-item btn"
          disabled={((total_pages-current_page) < movement)}
          onClick={()=>{paginate(total_pages)}}
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  )
}

export default PostingPagination;
import React, { Component } from 'react';
import { getBalances } from './services/fakeBalanceService';
import { paginate } from './utils/paginate';
import { connect } from 'react-redux';
class BalanceHistory extends Component {
  state = {
    balances: getBalances(),
    details: [],
    pageSize: 4,
    currentPage: 1
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handleDetailSelect = details => {
    this.setState({ selectedDetails: details, currentPage: 1 });
  };
  render() {
    const items = this.props.dashboardDetails
      ? this.props.dashboardDetails.balance_history
      : [];

    const mappedItems = items.map(item => {
      let cr,
        dr = '';
      if (item.credit === 'true') {
        cr = <td>{item.amount}</td>;
        dr = <td></td>;
      } else {
        cr = <td></td>;
        dr = <td>{item.amount}</td>;
      }
      return (
        <tr key={item.id}>
          <td>{item.created_at}</td>
          <td>{item.transaction_type}</td>
          <td>{item.id}</td>
          <td>{item.reason}</td>
          {cr}
          {dr}
          <td>{item.balance}</td>
        </tr>
      );
    });
    const { length: count } = this.state.balances;
    if (count === 0) return <p>There are no Balance History in the database</p>;
    return (
      <div className='p-4'>
        <p>Showing last 10 balance history</p>
        {/* <ListGroup
          items={this.state.details}
          onItemSelect={this.handleDetailSelect}
          selectedItem={this.state.selectedDetails}
        /> */}
        <table className='table'>
          <thead>
            <tr>
              <th>Date Time</th>
              <th>Category</th>
              <th>Reference</th>
              <th>Narration</th>
              <th>Credit</th>
              <th>Debit</th>
              <th>Balance (₦)</th>
            </tr>
          </thead>
          <tbody>{mappedItems}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dashboardDetails: state.dashboard.dashboardDetails
});

export default connect(
  mapStateToProps,
  null
)(BalanceHistory);

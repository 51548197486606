import React from 'react'
import './email-template.styles.css'

const EmailTemplate = () => {
    return (
        <div className='email-template-container'>
            <div className="email-template-top">
                <h2>How was your experience</h2>
            </div>
            <div className="email-template-bottom">
                <h1 className='email-template-amount'><sup className='email-template-sup'>₦</sup>200.00<sup className='email-template-sup'>DR</sup></h1>
                <div className="email-template-main-info">
                    <div className="email-template-info-item">
                        <span className="email-template-item-title">Account</span>
                        <span className="email-template-item-value">xxxxxx5677</span>
                    </div>
                    <div className="email-template-info-item">
                        <span className="email-template-item-title">Account Name</span>
                        <span className="email-template-item-value">AD ONI</span>
                    </div>
                    <div className="email-template-info-item">
                        <span className="email-template-item-title">Narration</span>
                        <span className="email-template-item-value">POS PURCHASE @2OT FIN INCLUSION TE LA</span>
                    </div>
                    <div className="email-template-info-item">
                        <span className="email-template-item-title">Transaction Reference</span>
                        <span className="email-template-item-value">S68405556</span>
                    </div>
                    <div className="email-template-info-item">
                        <span className="email-template-item-title">Date/Time</span>
                        <span className="email-template-item-value">{Date.now()}</span>
                    </div>
                    <div className="email-template-info-item" />
                    <div className="email-template-info-item">
                        <span className="email-template-item-title">Available Balance</span>
                        <span className="email-template-item-value"><sup>₦</sup>1,008.75<sup>CR</sup></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { EmailTemplate }
import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import { billsPaymentConstants } from './../constants/billsPaymentConstants';
import { toast } from 'react-toastify';

const _DEV = 'localhost';
let URL_PATH = '/api/graphql';
const { SET_PROPERTY, SUCCESS_MODAL_TOGGLE } = billsPaymentConstants;
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}

/**
 * Function for getting categories
 * @return {object}
 */
export const getCategories = () => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_PROPERTY,
        payload: { categoriesLoading: true }
      });
      const client = new ApolloClient({
        uri: `${URL_PATH}`
      });

      const response = await client.query({
        query: gql`
          query {
            getBillCategories {
              status
              data {
                id
                name
              }
            }
          }
        `
      });
      const { data } = response;
      const { getBillCategories } = data;
      const { data: billCategoriesData } = getBillCategories;

      const payload = {
        categories: billCategoriesData
      };
      dispatch({ type: SET_PROPERTY, payload });
    } catch (err) {
      toast.error('Network error');
      const payload = {
        categoriesLoadingError: err
      };
      dispatch({ type: SET_PROPERTY, payload });
    } finally {
      dispatch({
        type: SET_PROPERTY,
        payload: { categoriesLoading: false }
      });
    }
  };
};

/**
 * Function for getting sub categories
 * @param {number} categoryId - Requires a category id
 * @return {object}
 */
export const getSubCategories = categoryId => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_PROPERTY,
        payload: { subCategoriesLoading: true }
      });
      const client = new ApolloClient({
        uri: `${URL_PATH}`
      });

      const response = await client.query({
        query: gql`
          query {
            getBillSubCategories(category_id: "${categoryId}") {
              status
              data {
                id
                name
                category_id
              }
            }
          }
        `
      });
      const { data } = response;
      const { getBillSubCategories } = data;
      const { data: billSubCategoriesData } = getBillSubCategories;

      const payload = {
        subCategories: billSubCategoriesData
      };
      dispatch({ type: SET_PROPERTY, payload });
    } catch (err) {
      toast.error('Network error');
      const payload = {
        subCategoriesLoadingError: err
      };
      dispatch({ type: SET_PROPERTY, payload });
    } finally {
      dispatch({
        type: SET_PROPERTY,
        payload: { subCategoriesLoading: false }
      });
    }
  };
};

/**
 * Function for getting merchants
 * @param {number} subCategoryId - requires a sub category id
 * @return {object}
 */
export const getMerchants = subCategoryId => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_PROPERTY,
        payload: { merchantsLoading: true }
      });
      const client = new ApolloClient({
        uri: `${URL_PATH}`
      });

      const response = await client.query({
        query: gql`
          query {
            getBillMerchants(sub_category_id: "${subCategoryId}") {
              status
              data {
                id
                name
                sub_category_id
                label
              }
            }
          }
        `
      });
      const { data } = response;
      const { getBillMerchants } = data;
      const { data: billMerchantsData } = getBillMerchants;

      const payload = {
        merchants: billMerchantsData
      };
      dispatch({ type: SET_PROPERTY, payload });
    } catch (err) {
      toast.error('Network error');
      const payload = {
        merchantsLoadingError: err
      };
      dispatch({ type: SET_PROPERTY, payload });
    } finally {
      dispatch({
        type: SET_PROPERTY,
        payload: { merchantsLoading: false }
      });
    }
  };
};

/**
 * Function for getting variations
 * @param {number} merchantId - Requires merchant id
 * @return {object}
 */
export const getVariations = merchantId => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_PROPERTY,
        payload: { variationsLoading: true }
      });
      const client = new ApolloClient({
        uri: `${URL_PATH}`
      });

      const response = await client.query({
        query: gql`
          query {
            getBillVariations(merchant_id: "${merchantId}") {
              status
              data {
                id
                name
                merchant_id
                price
              }
            }
          }
        `
      });
      const { data } = response;
      const { getBillVariations } = data;
      const { data: billVariationsData } = getBillVariations;

      const payload = {
        variations: billVariationsData
      };
      dispatch({ type: SET_PROPERTY, payload });
    } catch (err) {
      toast.error('Network error');
      const payload = {
        variationsLoadingError: err
      };
      dispatch({ type: SET_PROPERTY, payload });
    } finally {
      dispatch({
        type: SET_PROPERTY,
        payload: { variationsLoading: false }
      });
    }
  };
};

/**
 * Function for getting variations
 * @param {number} merchantId - Requires merchant id
 * @return {object}
 */
export const getBills = (payload = {}) => {
  console.log(payload, 'is action payload');
  const { user } = window.localStorage;
  const { org_id: orgId } = JSON.parse(user);
  const queryList = [];
  const {
    limit,
    offset,
    dateFilter: date_filter,
    startDate: start_date,
    endDate: end_date,
    specificDate: specific_date,
    queryFilter: query_filter
  } = payload;

  queryList.push(`org_id: "${orgId}"`);
  queryList.push(`type: "bills"`);

  if (limit) {
    queryList.push(`limit: "${limit}"`);
  }

  if (date_filter) {
    queryList.push(`date_filter: "${date_filter}"`);
  }

  if (offset) {
    queryList.push(`offset: "${offset}"`);
  }

  if (start_date) {
    queryList.push(`start_date: "${start_date}"`);
  }

  if (end_date) {
    queryList.push(`end_date: "${end_date}"`);
  }

  if (specific_date) {
    queryList.push(`specific_date: "${specific_date}"`);
  }

  if (query_filter) {
    queryList.push(` query_filter: "${query_filter}"`);
  }

  const queryString = queryList.join(', ');

  return async dispatch => {
    try {
      dispatch({
        type: SET_PROPERTY,
        payload: { billListLoading: true }
      });
      const client = new ApolloClient({
        uri: `${URL_PATH}`
      });
      console.log(queryString);
      const response = await client.query({
        query: gql`
          query {
            getTransac(${queryString}) {
              status
              error
              message
              data {
                id
                name
                accountNumber
                amount
                trans_type
                teller_no
                user_id
                phone
                teller_no2
                variation_id
                variation
                category_id
                category
                sub_category_id
                sub_category
                merchant_id
                merchant
                created_at
              }
              pagination{
                offset
                limit
                total_count
              }
            }
          }
        `
      });
      console.log(response);
      const { data } = response;
      const { getTransac } = data;
      const { data: billList, pagination, error, message } = getTransac;

      if (error) {
        toast.error(message);
        const payload = {
          billListLoading: false
        };
        dispatch({ type: SET_PROPERTY, payload });
      } else {
        const payload = {
          billList,
          pagination,
          billListLoading: false
        };
        dispatch({ type: SET_PROPERTY, payload });
      }
    } catch (err) {
      toast.error('Network error');
      const payload = {
        variationsLoadingError: err
      };
      dispatch({ type: SET_PROPERTY, payload });
    } finally {
      dispatch({
        type: SET_PROPERTY,
        payload: { variationsLoading: false }
      });
    }
  };
};

/**
 * Handles submission of bills payment
 * @param {object} payload - The object of the state array
 */
export const createBillsPayment = userDetails => {
  return async dispatch => {
    dispatch({
      type: SET_PROPERTY,
      payload: {
        submitBillLoading: true
      }
    });
    try {
      const { user } = window.localStorage;
      const { phone, org_id: orgId, firstname, lastname } = JSON.parse(user);
      const fullName = `${firstname} ${lastname}`;
      const client = new ApolloClient({
        uri: `${URL_PATH}`
      });
      const response = await client.query({
        query: gql`
          query {
            getUserIdAndPhoneId (
              phone_number: "${phone}"
            ) {
              user_id
              phone_id
            }
          }
        `
      });
      const { getUserIdAndPhoneId } = response.data;

      if (getUserIdAndPhoneId.error) {
        toast.error(getUserIdAndPhoneId.error);
        dispatch({
          type: SET_PROPERTY,
          payload: {
            submitBillError: getUserIdAndPhoneId.message,
            submitBillLoading: false
          }
        });
        return;
      }

      const { user_id: userId } = getUserIdAndPhoneId;
      const payload = {
        ...userDetails,
        ...{ org_id: orgId, name: fullName, phone, user_id: userId }
      };

      const insertResponse = await client.mutate({
        mutation: gql`
          mutation {
            createTransac (
              name: "${payload.name}",
              accountNumber: "${payload.accountNumber}",
              amount: "${payload.amount}"
              trans_type: "${payload.trans_type}"
              user_id: "${payload.user_id}"
              phone: "${payload.phone}"
              variation_id: "${payload.variation_id}"
              org_id: "${payload.org_id}"
            ) {
              status
              teller_no
              message
              error
            }
          }
        `
      });
      const { createTransac } = insertResponse.data;
      if (createTransac.error) {
        toast.error(createTransac.message);
        dispatch({
          type: SET_PROPERTY,
          payload: {
            submitBillError: createTransac.message,
            submitBillLoading: false
          }
        });
      } else {
        dispatch({
          type: SET_PROPERTY,
          payload: {
            submitBillSuccess: createTransac.message,
            submitBillLoading: false,
            submitBillModalOpen: true,
            createdTellerNo: createTransac.teller_no
          }
        });
      }
    } catch (err) {
      toast.error('Network error occured');
      dispatch({
        type: SET_PROPERTY,
        payload: {
          submitBillError: 'Network error occured',
          submitBillLoading: false
        }
      });
    }
    dispatch({
      type: SET_PROPERTY,
      payload: {
        submitBillLoading: false
      }
    });
  };
};

/**
 * Handles select input change for bills payment
 * @param {string} name - The name of the state property to be changed
 * @param {object} valueObj - The object of the state array
 */
export const handleChange = (name, valueObj = {}) => {
  const { name: value } = valueObj;
  return async dispatch => {
    switch (name) {
      case 'selectedCategory':
        if (!value || value === '') {
          return;
        }
        dispatch({
          type: SET_PROPERTY,
          payload: {
            [name]: value,
            subCategories: [],
            selectedSubCategory: '',
            merchants: [],
            selectedMerchant: '',
            variations: [],
            selectedVariation: '',
            refName: null,
            amount: ''
          }
        });
        dispatch(getSubCategories(valueObj.id));
        break;
      case 'selectedSubCategory':
        if (!value || value === '') {
          return;
        }
        dispatch({
          type: SET_PROPERTY,
          payload: {
            [name]: value,
            merchants: [],
            selectedMerchant: '',
            variations: [],
            selectedVariation: '',
            refName: null,
            amount: ''
          }
        });
        dispatch(getMerchants(valueObj.id));
        break;
      case 'selectedMerchant':
        if (!value || value === '') {
          return;
        }
        dispatch({
          type: SET_PROPERTY,
          payload: {
            [name]: value,
            variations: [],
            selectedVariation: '',
            refName: valueObj.label,
            accountNumber: ''
          }
        });
        dispatch(getVariations(valueObj.id));
        break;
      case 'selectedVariation':
        if (!value || value === '') {
          return;
        }
        dispatch({
          type: SET_PROPERTY,
          payload: { [name]: value }
        });
        const { price } = valueObj;
        if (price) {
          dispatch({ type: SET_PROPERTY, payload: { amount: price } });
        }
        break;
      default:
        dispatch({ type: SET_PROPERTY, payload: { [name]: value } });
    }
    dispatch({
      type: SET_PROPERTY,
      payload: { [name]: value }
    });
  };
};

/**
 * set a property of bills state
 * @param {object} payload - the properties to be edited
 * @return {object}
 */
export const setBillsPaymentProperty = payload => {
  return dispatch => {
    dispatch({ type: SET_PROPERTY, payload });
  };
};

/**
 * toggle success modal to print receipt
 * @return {object}
 */
export const toggleSuccessModal = () => ({ type: SUCCESS_MODAL_TOGGLE });

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import { usersLists, editUserInfo } from '../../../reducers/home';
import { columns, defaultSorted } from './columns';
import Loader from '../Transactions/Loader';
// import { Card, Progress} from 'reactstrap';
// import { ListGroup, ListGroupItem } from 'reactstrap';
// import CardBody from 'reactstrap/lib/CardBody';
import '../../../../src/assets/css/index.css';
// import { DirectiveLocation } from 'graphql';
import UserInfoPage from './UserInfoPage';
import UserForm from './UserForm';
import { inputValidation } from '../../../actions/inputValidation';
// import { inputValidation } from '../../../actions/inputValidation';

export class UsersInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      phoneNo: '',
      userId: '',
      officeAddress: '',
      homeAddress: '',
      routeFrom: '',
      routeTo: '',
      localLocation: '',
      stateLocation: '',
      userForm: false,
      userInfo: false,
      defaultSorted: [
        {
          dataField: 'id',
          order: 'asc'
        }
      ],
      table: true,
      modal: false,
      showUserLists: {},
      info: {},
      editInfo: { password: '', confirmPassword: '' },
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.setState({ info: row }, () => {
            this.setState({
              editInfo: { ...{ password: '', confirmPassword: '' }, ...row }
            });
            this.toggleTable();
            console.log(this.state.info);
          });
        }
      },
      loading: false
    };
  }
  toggleTable = () => {
    this.setState({ table: !this.state.table, userInfo: !this.state.userInfo });
  };
  toggleEdit = () => {
    this.setState({
      userForm: !this.state.userForm,
      userInfo: !this.state.userInfo,
    });
  };
  toggleSave = () => {
    this.setState({
      userForm: !this.state.userForm,
      userInfo: !this.state.userInfo,
    });
  };

  saveEditedUser = () => {
    const { phone, gender, firstname, lastname, password, confirmPassword } = this.state.editInfo;
    const validate = inputValidation({ phone, gender, firstname, lastname });
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    if (validate.result) {
      this.setState({ loading: true }, () =>
        this.props.editUserInfo(this.state.editInfo)
      );
    } else {
      toast.error(validate.message);
    }
  };

  handleSetState = (title, value) => {
    console.log(title, 'is being changed');
    const editInfo = this.state.editInfo;
    if (title === 'city') {
      editInfo['address'][title] = value;
    } else {
     editInfo[title] = value; 
    }
    this.setState({ editInfo }, () => {
      console.log('logging editinfo', this.state.editInfo);
    });
  };

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log(user);
    const { org_id, user_type } = user;
    const payload = {
      org_id,
      user_type,
    };

    this.props.usersLists(payload);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showUserLists !== this.props.showUserLists) {
      console.log('users lists', this.props.showUserLists);
      this.setState({ showUserLists: this.props.showUserLists });
    }
    if (prevProps.showEditUser !== this.props.showEditUser) {
      console.log('this.props.showEditUser', this.props.showEditUser);
      const { showEditUser } = this.props;
      const { error } = showEditUser;
      if (showEditUser && showEditUser._id) {
        this.setState(
          {
            info: { ...showEditUser },
            editInfo: { ...showEditUser, ...{password: '', confirmPassword: ''} },
            loading: false,
          },
          () => {
            const { org_id, user_type } = { ...showEditUser };
            const payload = {
              org_id,
              user_type,
            };
            toast.success('User edited succesfully !!!');
            this.toggleSave();
            this.props.usersLists(payload);
          }
        );
      } else if (error && Object.keys(error).length) {
        this.setState({ loading: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ loading: false }, () => {
          toast.error('Network error!');
        });
      }
    }
  };

  indication = () => {
    return <span>Loading...</span>;
  };

  render() {
    const { showUserLists } = this.state;
    return (
      <main className='dash-content'>
        {this.state.loading && <Loader />}
        {this.state.table && (
          <div className='container-fluid' style={{ minWidth: '1500px' }}>
            <BootstrapTable
              bootstrap4
              striped={true}
              hover={true}
              keyField='id'
              data={this.props.showUserLists ? this.props.showUserLists : []}
              columns={columns}
              defaultSorted={defaultSorted}
              rowEvents={this.state.rowEvents}
              noDataIndication={this.indication} //Item List preloader
              pagination={paginationFactory()}
              filter={filterFactory()}
              headerClasses='header-class'
            />
          </div>
        )}
        {this.state.userInfo && (
          <UserInfoPage
            toggle={this.toggleTable}
            info={this.state.info}
            toggleEdit={this.toggleEdit}
            toggleBack={this.toggleBack}
          />
        )}
        {/* { this.state.userForm && <UserForm info={this.state.info} toggleEdit={this.toggleEdit}  toggleSave={this.toggleSave}/>} */}
        {this.state.userForm && (
          <UserForm
            info={this.state.editInfo}
            toggleEdit={this.toggleEdit}
            handleSetState={this.handleSetState}
            toggleSave={this.toggleSave}
            saveEditedUser={this.saveEditedUser}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = state => ({
  showUserLists: state.home.showUserLists,
  showEditUser: state.home.showEditUser,
});

const mapDispatchToProps = {
  usersLists,
  editUserInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersInfo);

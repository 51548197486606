import React, { Component } from "react";
import { Provider } from "react-redux";
import createStore from "./store/createStore";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/styles.css";
import "./assets/js/scripts.js";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Home from "./components/index";
import { Router, } from 'react-router'
import { createBrowserHistory } from "history";
// import { BrowserRouter as Router, browserHistory } from "react-router-dom";
// import Login from './components/loginSingup/Login';

const initialState = window.___INTITIAL_STATE__;
const store = createStore(initialState);
const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: false,
      name: "Adelaja Ibrahim Abiola"
    };
  }

  componentDidMount = () => {};

  render() {
    return (
      <Provider store={store}>
        <ToastContainer />
        <Router history={history}>
          {/* <Route path='/' component={Home} /> */}
          <Home />
        </Router>
      </Provider>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import { appUsersLists } from '../../../reducers/home';
import { columns, defaultSorted } from './columns';
import AppUserInfo from './AppUsersInfo';
import {navPermission} from '../../../actions/permission';


export class AppUsers extends Component {
  constructor(props) {
    super(props);

    this.state = this.state = {
      defaultSorted: [
        {
          dataField: 'id',
          order: 'asc'
        }
      ],
      table: true,
      modal: false,
      info: {},
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.setState({ info: row }, () => {
            console.log(row);
            this.toggleTable();
          });
        }
      }
    };
  }

  toggleTable = () => this.setState({ table: !this.state.table });

  componentDidMount = () => {
    if (!navPermission(5)) {
      return (window.location.href = '/');
    } else {
    console.log('window', window.location.hostname);
    this.props.appUsersLists()
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showAppUserLists !== this.props.showAppUserLists) {
      console.log(this.props.showAppUserLists);
    }
  };

  indication=()=>{
    return <span>Loading...</span>
  }

  render() {
    return (
      <main className='dash-content'>
        {this.state.table && (
          <div className='container-fluid' style={{ minWidth: '1500px' }}>
            {/* <div  style={{minWidth: '1200px'}}> */}
            <BootstrapTable
              bootstrap4
              striped={true}
              hover={true}
              keyField='id'
              data={
                this.props.showAppUserLists ? this.props.showAppUserLists : []
              }
              columns={columns}
              tabIndexCell
              defaultSorted={this.state.defaultSorted}
              rowEvents={this.state.rowEvents}
              noDataIndication= {this.indication} //Item List preloader
              pagination={paginationFactory()}
              filter={filterFactory()}
              headerClasses='header-class'
            />
            {/* </div> */}
          </div>
        )}
       {/* {!this.state.table && <div>{this.state.info.name}</div>} */}
        {!this.state.table && <AppUserInfo toggleTable={this.toggleTable} Info={this.state.info}/>}
      </main>
    );
  }
}

const mapStateToProps = state => ({
  showAppUserLists: state.home.showAppUserLists
});

const mapDispatchToProps = {
  appUsersLists
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppUsers);

function inputValidation(input) {
  for (var property1 in input) {
    if (input[property1] === undefined) {
      return { message: `${property1} is undefined`, result: false };
    }
    if (input[property1] === '') {
      return { message: `${property1} cannot be Empty`, result: false };
    }
  }
  return { message: 'complete', result: true };
}

function objectReset(obj) {
  for (var property in obj) {
    obj[property] = '';
  }
  return obj;
}

export { inputValidation, objectReset };

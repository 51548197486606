import React, { Component } from "react";
import { getDashboardDetails } from "../../../reducers/dashboard";
import { connect } from "react-redux";
import { editMyProfile, addNewUser } from "../../../reducers/home";
import {
  getAccountName,
  getUserIdAndPhoneId,
} from "../../../reducers/transfers";
import RecentHistory from "./recentTransaction";
import bankDetails from "../../../assets/js/bankCodes";

class Dashboard extends Component {
  state = {
    bankName: "",
    accountBalance: "",
  };
  componentDidMount = async () => {
    const { user } = window.localStorage;
    const { org_id: orgId, phone } = JSON.parse(user);
    const ids = await this.props.getUserIdAndPhoneId({ phone });
    const { user_id: userId } = ids;
    await this.props.getAccountName({
      acct_number: "",
      bank_code: orgId,
      origin: true,
      dashboard: true,
    });
    this.setState({
      accountBalance: this.props.accountBalance,
      bankName: this.props.accountName,
    });
    this.props.getDashboardDetails({ orgId, userId });
  };
  render() {
    const vans = this.props.dashboardDetails
      ? this.props.dashboardDetails.virtual_account_numbers
      : [];
    let mappedVans = "";
    if (vans.length > 0) {
      mappedVans = vans.map((bank) => {
        return (
          <p className="text-success">
            <span className="font-weight-bold"> {bank.virtual_account_no}</span>{" "}
            - {bank.virtual_account_name}
          </p>
        );
      });
    } else {
      mappedVans = (
        <p className="text-danger font-weight-bold">
          No virtual account numbers found
        </p>
      );
    }
    console.log(this.props + "");
    const user = localStorage.getItem("user");
    const parseUser = user ? JSON.parse(user) : "";
    return (
      <div className="p-5">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="text-success">
            Welcome, {parseUser.firstname} {parseUser.lastname} -{" "}
            {this.state.bankName}
          </h1>
          <h1>Staff ID: {parseUser.staff_id}</h1>
        </div>

        <div className="row p-4">
          <div className="p-3 bg-white shadow col-4 row round-box">
            <div className="col-8 my-auto">
              <h6>Transaction Balance &nbsp;&nbsp;&nbsp;</h6>
              <h4>{this.state.accountBalance && this.state.accountBalance}</h4>
            </div>

            <div className="col my-auto">
              <i className="fas fa-wallet icon-circle text-success"></i>
            </div>
          </div>
          <div style={{ width: 45 }}></div>
          <div className="p-3 bg-white shadow col-4 row my-auto round-box scrollable">
            <div className="mb-1">Your virtual account numbers -</div>
            <div>{mappedVans}</div>
            <div className="text-info">
           
              <strong>Note: </strong>Fund your transaction account by sending money to these
             account number
            </div>
          </div>
        </div>
        <h4>Transaction Summary</h4>
        <div className="row p-4">
          <div className="p-3 bg-white shadow col-4 row my-auto round-box">
            <div className="col-8 ">
              <p>Bills paid</p>
              {this.props.dashboardDetails && (
                <h3>{this.props.dashboardDetails.bills_paid}</h3>
              )}
              <p className="text-success">View History</p>
            </div>

            <div className="col my-auto">
              <i
                className="fas fa-coins icon-circle text-warning"
                style={{ backgroundColor: "#FDE7E5" }}
              ></i>
            </div>
          </div>
          <div style={{ width: 45 }}></div>
          <div className="p-3 bg-white shadow col-4 row my-auto round-box">
            <div className="col-8 ">
              <p>Bank transfers</p>
              {this.props.dashboardDetails && (
                <h3>{this.props.dashboardDetails.transfer_count}</h3>
              )}
              <p className="text-success">View History</p>
            </div>

            <div className="col my-auto">
              <i
                className="fas fa-expand-arrows-alt icon-circle text-info"
                style={{ backgroundColor: "#BAE5FA" }}
              ></i>
            </div>
          </div>
          <div style={{ width: 45 }}></div>
          <div className="p-3 bg-white shadow col-4 row my-auto round-box">
            <div className="col-8 ">
              <p>Airtime</p>
              {this.props.dashboardDetails && (
                <h3>{this.props.dashboardDetails.airtime_count}</h3>
              )}
              <p className="text-success">View History</p>
            </div>

            <div className="col my-auto">
              <i
                className="fas fa-wallet icon-circle text-warning"
                style={{ backgroundColor: "#FBD4D2" }}
              ></i>
            </div>
          </div>
        </div>
        <h4>Recent Transaction</h4>
        <RecentHistory />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accountName: state.transfer.accountName,
  accountBalance: state.transfer.accountBalance,
  showNewAdmin: state.home.showNewAdmin,
  showEditMe: state.home.showEditMe,
  showNewUser: state.home.showNewUser,
  dashboardDetails: state.dashboard.dashboardDetails,
});

const mapDispatchToProps = {
  editMyProfile,
  addNewUser,
  getDashboardDetails,
  getAccountName,
  getUserIdAndPhoneId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

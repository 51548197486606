import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { createJournal, getBranches } from "../../../actions/journalAction";
import { getOperationDate } from "../../../actions/postingAction";
import { getGLAccounts } from "../../../actions/accountsActions";
import Loader from "../Transactions/Loader";

const CreateJournal = () => {
  const [entryRecords, setEntryRecords] = useState([]);
  const [balanceCheck, setBalanceCheck] = useState({});
  const [formFieldsMessage, setFormFieldsMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accountTitle, setAccountTitle] = useState("");
  const [gLAccounts, setGLAccounts] = useState([]);
  const [branches, setBranches] = useState([]);

  const formik = useFormik({
    initialValues: {
      operationDate: "",
      // pvNote: "",
      branch: "",
      narration: "",
      folio: "",
    },
    validationSchema: Yup.object({
      operationDate: Yup.string().required("Operation date is required"),
      // pvNote: Yup.string().required("PV Note no is required"),
      branch: Yup.string().required("Branch is required"),
      narration: Yup.string().required("Narration type is required"),
      folio: Yup.string().required("Folio 1 is required"),
    }),
    onSubmit: (values) => {
      // console.log(values)
    },
  });

  const addRecord = (event) => {
    setAccountTitle("");
    setResponse(null);
    event.preventDefault();
    setFormFieldsMessage(null);
    let { target } = event;

    let checkers = ["amount", "type", "sub_narration", "account_code"];
    let record = { };
    let valid = checkers.every((field) => {
      let value = target[field]["value"].trim();
      if (!value) {
        let error_name = field.toUpperCase().replace("_", " ");
        setFormFieldsMessage(`All fields are required, ${error_name} missing`);
        return false;
      }
      record[field] = value;
      return true;
    });
    if (!valid) return;
    setEntryRecords((entry_records) => [...entry_records, record]);
    event.target.reset();
  };

  const deleteAnEntryRecord = (id) => {
    setEntryRecords(entryRecords.filter((item) => item.id !== id));
  };


  useEffect(() => {
    let sum = entryRecords.reduce((accumulation, current_record) => {
      if (current_record.type === "DEBIT") {
        accumulation["sum_of_debit"] = accumulation["sum_of_debit"]
          ? accumulation["sum_of_debit"] + +current_record.amount
          : +current_record.amount;
      } else {
        accumulation["sum_of_credit"] = accumulation["sum_of_credit"]
          ? accumulation["sum_of_credit"] + +current_record.amount
          : +current_record.amount;
      }
      accumulation["is_balanced"] =
        accumulation["sum_of_debit"] === accumulation["sum_of_credit"];

      return accumulation;
    }, {});

    setBalanceCheck(sum);
  }, [entryRecords]);


  const createEntry = async () => {
    if (submitting) return;
    formik.submitForm();
    if (!formik.isValid) return;
    if (!balanceCheck.is_balanced) {
      return setFormFieldsMessage(
        `Entry records required with balanced debit credit rate`
      );
    }

    const payload = {
      ...formik.values,
      entryRecords,
    };
    setSubmitting(true);
    try {
      setLoading(true);
      let response = await createJournal(payload, setEntryRecords, setLoading);
      if (response) {
        let { status, message } = response;
        setResponse({ status, message });
      } else {
        let status = "failed",
          message = "Something went wrong, please retry.";
        setResponse({ status, message });
      }
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const handleAccountCode = (event) => {
    const accountName = gLAccounts.find(
      (item) => item.code === event.target.value
    );
    setAccountTitle(accountName.description);
  };

  const handleGetOperationDate = async () => {
    const responseData = await getOperationDate(setLoading);
    if (responseData && responseData.getOperationDate) {
      const operationDate = responseData.getOperationDate;
      formik.setFieldValue("operationDate", operationDate, true);
    }
  };

  const handleGetBranch = async () => {
    const branches = await getBranches();
    // console.log(branches)
    if (branches && branches.data) {
      const sortedBranches = branches.data.sort(
        (a, b) => a.code - b.code
      );
      setBranches(sortedBranches);
    }
  };

  const handleGetGLAccount = async () => {
    const glResponse = await getGLAccounts();
    // console.log(glResponse);
    if (glResponse && glResponse.data) {
      setGLAccounts(glResponse.data);
    }
  };

  useEffect(() => {
    const formData = async () => {
      setLoading(true);
      await handleGetOperationDate();
      await handleGetBranch();
      await handleGetGLAccount();
      setLoading(false);
    };

    formData().catch(err=>setLoading(false));
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <h1 className="text-center">Create New Journal</h1>
      <hr />
      <Row className="justify-content-center login-container align-items-center">
        <Col xs={12} md={12} lg={10}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="col-sm-6">
                <FormGroup>
                  <Label for="operationDate">
                    <small className="font-weight-bold">Operation Date</small>
                  </Label>
                  <Input
                    id="operationDate"
                    name="operationDate"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.operationDate}
                    readOnly={true}
                    type="date"
                  />
                  {formik.touched.operationDate &&
                  formik.errors.operationDate ? (
                    <div className="text-danger small">
                      {formik.errors.operationDate}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup>
                  <Label for="branch">
                    <small className="font-weight-bold">Branch(s)</small>
                  </Label>
                  <Input
                    type="select"
                    name="branch"
                    id="branch"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branch}
                  >
                    <option value="" hidden disabled>
                      Please Choose...
                    </option>
                    {branches.map((branch) => (
                      <option value={branch.code} key={branch.id}>{branch.code}</option>
                    ))}
                  </Input>
                  {formik.touched.branch && formik.errors.branch ? (
                    <div className="text-danger small">
                      {formik.errors.branch}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-6">
                <FormGroup>
                  <Label for="narration">
                    <small className="font-weight-bold">Narration</small>
                  </Label>
                  <Input
                    id="narration"
                    name="narration"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.narration}
                    type="textarea"
                    rows="1"
                  />
                  {formik.touched.narration && formik.errors.narration ? (
                    <div className="text-danger small">
                      {formik.errors.narration}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="col-sm-6">
                <FormGroup>
                  <Label for="folio">
                    <small className="font-weight-bold">Folio 1</small>
                  </Label>
                  <Input
                    id=""
                    name="folio"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.folio}
                    type="number"
                  />
                  {formik.touched.folio && formik.errors.folio ? (
                    <div className="text-danger small">
                      {formik.errors.folio}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            </div>
          </Form>

          {/* Entry record block */}
          <div>
            <h4>Entry Records</h4>
            <hr />
            {formFieldsMessage && (
              <div className="text-danger small">{formFieldsMessage}</div>
            )}
            <Form onSubmit={addRecord}>
              <div className="form-row align-items-center">
                {/* Account code block */}
                <div className="col-sm-6 px-sm-4">
                  <FormGroup>
                    <Label for="account_code">
                      <small className="font-weight-bold">ACCOUNT CODE</small>
                    </Label>
                    <Input
                      type="select"
                      name="account_code"
                      id="account_code"
                      defaultValue=""
                      className="form-control"
                      onChange={handleAccountCode}
                    >
                      <option value="" hidden disabled>
                        Please Choose...
                      </option>
                      {gLAccounts.map((item) => (
                        <option key={item.id}>{item.code}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>

                <div className="col-sm-6 px-sm-4">
                  <FormGroup>
                    <Label for="account title">
                      <small className="font-weight-bold">ACCOUNT TITLE</small>
                    </Label>
                    <Input
                      value={accountTitle}
                      required
                      disabled
                      rows="1"
                      name="accountTitle"
                      id="accountTitle"
                      className="form-control"
                    />
                  </FormGroup>
                </div>

                {/* Type block */}
                <div className="col-sm-6 px-sm-4">
                  <FormGroup>
                    <Label for="type">
                      <small className="font-weight-bold">TYPE</small>
                    </Label>

                    <Input
                      type="select"
                      name="type"
                      id="type"
                      defaultValue=""
                      className="form-control"
                    >
                      <option value="" hidden disabled>
                        Choose type
                      </option>
                      <option>DEBIT</option>
                      <option>CREDIT</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row align-items-center">
                {/* Amount block */}
                <div className="col-sm-6 px-sm-4">
                  <FormGroup>
                    <Label for="amount">
                      <small className="font-weight-bold">AMOUNT</small>
                    </Label>
                    <Input
                      name="amount"
                      id="amount"
                      className="form-control"
                      type="number"
                    />
                  </FormGroup>
                </div>
                {/* Sub narration block */}
                <div className="col-sm-6 px-sm-4">
                  <FormGroup>
                    <Label for="subNarration">
                      <small className="font-weight-bold">SUB NARRATION</small>
                    </Label>
                    <Input
                      type="textarea"
                      rows="1"
                      name="sub_narration"
                      id="subNarration"
                      className="form-control"
                    />
                  </FormGroup>
                </div>
              </div>

              {/* Add button's block */}
              <div className="col-sm-4 px-sm-4">
                <Button
                  outline
                  color="success"
                  className="form-control bg-success text-white font-weight-bold text-uppercase"
                >
                  Add
                </Button>
              </div>
            </Form>

            <div>
              {/*Journal Record Table */}
              <Table borderless>
                <thead>
                  <tr className="text-uppercase">
                    <th>
                      <small className="font-weight-bold">Account Code</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Sub Narration</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Amount</small>
                    </th>
                    <th>
                      <small className="font-weight-bold">Type</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {entryRecords.map((item, index) => (
                    <tr key={index}>
                      <td>{item.account_code}</td>
                      <td>{item.sub_narration}</td>
                      <td>{item.amount}</td>
                      <td>{item.type}</td>
                      <td>
                        <Button
                          variant="danger"
                          className="btn-sm"
                          onClick={() => deleteAnEntryRecord(index)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />

              {/* Total block */}
              <div className="row">
                <div className="col-sm-4">
                  <span className="pr-2"> Sum of debit: </span>
                  <span>
                    {balanceCheck.sum_of_debit ? balanceCheck.sum_of_debit : 0}
                  </span>
                </div>
                <div className="col-sm-4">
                  <span className="pr-2"> Sum of credit: </span>
                  <span>
                    {balanceCheck.sum_of_credit
                      ? balanceCheck.sum_of_credit
                      : 0}
                  </span>
                </div>

                {balanceCheck.is_balanced !== undefined && (
                  <div className="col-sm-4">
                    <span className="pr-2">Status:</span>
                    <span
                      className={balanceCheck.is_balanced ? "" : "text-danger"}
                    >
                      {balanceCheck.is_balanced ? "Balance" : " Not balanced"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {response && (
            <div
              className={`d-flex mt-4 justify-content-center 
                            ${
                              response.status === "success"
                                ? "text-success"
                                : "text-danger"
                            } `}
            >
              {response.message}
            </div>
          )}

          {/* Create Block */}
          <div className="my-4 d-flex justify-content-center">
            <Button
              outline
              color="success"
              style={{ maxWidth: "350px" }}
              className="form-control bg-success text-white font-weight-bold text-uppercase"
              onClick={createEntry}
            >
              Create Journal Entry
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateJournal;

import React, { Component } from 'react';
import { toast } from 'react-toastify';
import bankDetails from '../../../assets/js/bankCodes';
import './utils/Transfer.css';
import {
  getAccountName,
  createTransfer,
  getUserIdAndPhoneId
} from '../../../reducers/transfers';
import { connect } from 'react-redux';
import Loader from './Loader';
class NewTransfer extends Component {
  state = {
    senderAccountNo: '',
    senderAccountName: '',
    beneficiaryAccountNo: '',
    beneficiaryAccountName: '',
    beneficiaryOrgName: '',
    initiatorID: '',
    initiatorName: '',
    bank: {},
    amount: '',
    token: '',
    remarks: '',
    createTransferResponse: '',
    senderAccountNameError: false,
    beneficiaryAccountNameError: false,
    loading: false,
    userId: '',
    phoneId: '',
    orgId: '',
    senderAccountNameWithBalance: ''
  };

  notify = (message, type) => {
    switch (type) {
      case 'success':
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true
        });
        break;
      case 'error':
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
        break;
      default:
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true
        });
    }
  };

  componentDidMount = async () => {
    const { state: locationState } = this.props.location;
    this.setState({ loading: true });
    
    try {
      const { user } = window.localStorage;
      const { phone, org_id: orgId, firstname, lastname, id } = JSON.parse(user);
      const ids = await this.props.getUserIdAndPhoneId({ phone });
      const { phone_id: phoneId, user_id: userId } = ids;

      if (locationState) {
        const {
          senderAccountName,
          senderAccountNo,
          beneficiaryAccountName,
          beneficiaryAccountNo,
          beneficiaryOrgId,
          remarks,
          amount
        } = locationState;

        const bank = bankDetails.find(bnk => bnk.code === beneficiaryOrgId) || {};

        await this.props.getAccountName({
          acct_number: senderAccountNo,
          bank_code: orgId,
          dashboard: false,
          origin: true
        });

        this.setState({
          senderAccountName,
          senderAccountNo,
          beneficiaryAccountName,
          beneficiaryAccountNo,
          bank,
          remarks,
          amount,
          senderAccountNameWithBalance: `${this.props.accountName} (${this.props.accountBalance})`
        });
      }

      this.setState({
        phoneId,
        userId,
        orgId,
        loading: false,
        initiatorID: id,
        initiatorName: `${firstname} ${lastname}`
      });
    } catch (ex) {
      this.notify(ex.message, "error");
      this.setState({ loading: false });
    }
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const {
      senderAccountNo,
      senderAccountName,
      beneficiaryAccountNo,
      beneficiaryAccountName,
      bank,
      amount,
      token,
      remarks,
      userId,
      phoneId,
      orgId,
      initiatorID,
      initiatorName
    } = this.state;
    
    let formattedAmount = amount
      .split(' ')
      .join('')
      .split(',')
      .join('');
    // let selectedBank = bankDetails.find(currentBank => currentBank.code === bank.code);
    const { createTransfer } = this.props;
    const payload = {
      senderAccountNo,
      senderAccountName,
      beneficiaryAccountNo,
      beneficiaryAccountName,
      beneficiaryOrgId: bank.code,
      beneficiaryBankName: bank.name,
      amount: formattedAmount,
      orderId: `${new Date().getTime()}${userId}`,
      remarks,
      token,
      userId,
      phoneId,
      orgId,
      initiatorID,
      initiatorName
    };
    
    try {
      const response = await createTransfer(payload);
      if (response.error) {
        this.notify(response.message)
      } else {
        this.setState({ createTransferResponse: response.message });
      }
    } catch (err) {
      console.log(err);
      this.notify('Network error occured', "error");
    }
    this.setState({ loading: false });
  };
  handleAccountFields = event => {};

  handleChange = async event => {
    const { name, value } = event.target;
    // console.log(this.state);
    switch (name) {
      case 'senderAccountNo':
        !this.state.loading && this.setState({ [name]: value });
        if (value.length === 10) {
          this.setState({ loading: true });
          try {
            await this.props.getAccountName({
              acct_number: value,
              bank_code: this.state.orgId,
              dashboard: false,
              origin: true
            });

            if (this.props.accountName === 'Account Name Error') {
              this.setState({ senderAccountNameError: true });
            } else {
              this.setState({
                senderAccountNameError: false,
                senderAccountName: this.props.accountName,
                senderAccountNameWithBalance: `${this.props.accountName} (${this.props.accountBalance})`
              });
            }
          } catch (err) {
            console.log(err);
          }
          this.setState({ loading: false });
        } else {
          this.setState({ senderAccountName: '' });
        }
        break;
      case 'beneficiaryAccountNo':
        !this.state.loading && this.setState({ [name]: value });
        if (value.length === 10 && this.state.bank.code) {
          this.setState({ loading: true });
          try {
            await this.props.getAccountName({
              acct_number: value,
              bank_code: this.state.bank.code,
              origin: false,
              dashboard: false
            });
            if (this.props.accountName === 'Account Name Error') {
              this.setState({
                beneficiaryAccountNameError: true
              });
            } else {
              this.setState({
                beneficiaryAccountName: this.props.accountName,
                beneficiaryAccountNameError: false
              });
            }
          } catch (err) {
            console.log(err);
          }
          this.setState({ loading: false });
        } else {
          this.setState({ beneficiaryAccountName: '' });
        }
        break;
      case 'bank':
        const selectedBank = bankDetails.find(bnk => bnk.code === value);
        // console.log(selectedBank, 'SELECTED BANK');
        this.setState({ bank: selectedBank });
        if (this.state.beneficiaryAccountNo.length >= 10) {
          this.setState({ loading: true });
          try {
            await this.props.getAccountName({
              acct_number: this.state.beneficiaryAccountNo,
              bank_code: value,
              dashboard: false,
              origin: true
            });
            if (this.props.accountName === 'Account Name Error') {
              this.setState({
                beneficiaryAccountNameError: true
              });
            } else {
              this.setState({
                beneficiaryAccountName: this.props.accountName,
                beneficiaryAccountNameError: false
              }, ()=> {/*console.log(this.state, 'BEFORE LAST RENDER')*/});
            }
          } catch (err) {
            console.log(err);
          }
          this.setState({ loading: false }, () => {
            /*console.log(this.state, 'AFTER LAST RENDER');*/
          });
        }
        break;

      default:
    }
    const excludeSelect = name !== 'bank';
    !this.state.loading && excludeSelect && this.setState({ [name]: value });
  };

  render() {
    const {
      senderAccountNo,
      senderAccountName,
      beneficiaryAccountNo,
      beneficiaryAccountName,
      bank,
      amount,
      token,
      remarks,
      loading,
      senderAccountNameError,
      beneficiaryAccountNameError,
      createTransferResponse,
      senderAccountNameWithBalance
    } = this.state;
    // console.log(this.state, 'ON RENDER');
    const incompleteDetails =
      senderAccountName === '' ||
      senderAccountNo.length !== 10 ||
      beneficiaryAccountName === '' ||
      beneficiaryAccountNo.length !== 10 ||
      !bank.code ||
      amount === '' ||
      token.length !== 6;

    const mappedBanks = bankDetails.map(bank => {
      return (
        <option key={bank.code} value={bank.code}>
          {bank.name}
        </option>
      );
    });

    return (
      <div className='p-4'>
        {loading && <Loader />}
        <div
          className='d-flex justify-content-center align-items-center bg-success back-button'
          onClick={() => {
            this.props.history.push('/transactions/transfer');
          }}
        >
          <i className='fas fa-arrow-left'></i>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <div className='inner-new-transfer'>
            <div className='form-group'>
              {createTransferResponse !== '' && (
                <div class='alert alert-success fade show' role='alert'>
                  {createTransferResponse}
                </div>
              )}
            </div>
            <div className='form-group'>
              <h6>Sender's Account Number</h6>
              <input
                type='text'
                className='form-control'
                name='senderAccountNo'
                value={senderAccountNo}
                onChange={this.handleChange}
              />
              {senderAccountNameError && (
                <p className='text text-danger'>
                  * User with account number not found
                </p>
              )}
            </div>

            <div className='form-group'>
              <h6>Sender's Account Name</h6>
              <input
                type='text'
                className='form-control'
                name='senderAccountName'
                defaultValue={senderAccountNameWithBalance}
                disabled
              />
            </div>

            <div className='form-group'>
              <h6>Beneficiary's Account Number</h6>
              <input
                type='text'
                className='form-control'
                name='beneficiaryAccountNo'
                value={beneficiaryAccountNo}
                onChange={this.handleChange}
              />
              {beneficiaryAccountNameError && (
                <p className='text text-danger'>
                  * User with account number not found
                </p>
              )}
            </div>

            <div className='form-group'>
              <h6>Bank</h6>
              <select
                className='form-control'
                name='bank'
                value={bank.code || ''}
                onChange={this.handleChange}
              >
                <option value='' disabled={true}>
                  Select Bank
                </option>
                {mappedBanks}
              </select>
            </div>

            <div className='form-group'>
              <h6>Beneficiary's Account Name</h6>
              <input
                type='text'
                className='form-control'
                name='beneficiaryAccountName'
                defaultValue={beneficiaryAccountName}
                disabled
              />
            </div>

            <div className='form-group'>
              <h6>Amount</h6>
              <input
                type='text'
                className='form-control'
                name='amount'
                value={amount}
                onChange={this.handleChange}
              />
            </div>

            <div className='form-group'>
              <h6>Token</h6>
              <input
                type='text'
                className='form-control'
                name='token'
                value={token}
                onChange={this.handleChange}
              />
            </div>
            <div className='form-group'>
              <h6>Remarks</h6>
              <textarea
                className='form-control'
                name='remarks'
                value={remarks}
                onChange={this.handleChange}
              ></textarea>
            </div>
            <div className='form-group'>
              <button
                className='btn btn-primary w-100'
                onClick={this.handleSubmit}
                disabled={incompleteDetails}
              >
                <small className='font-weight-bold text-uppercase'>
                  Transfer
                </small>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountName: state.transfer.accountName,
    accountBalance: state.transfer.accountBalance,
    serverResponse: state.transfer.serverResponse
  };
};

const mapDispatchToProps = {
  getAccountName,
  createTransfer,
  getUserIdAndPhoneId
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTransfer);

import React from 'react';
import { Switch, Route }from 'react-router-dom';
import Journals from "./list";
import CreateJournal from "./create";

const Journal=({match:{path}})=>{
  return(
    <Switch>
      <Route exact path={path} component={Journals} />
      <Route path={`${path}/new`} component={CreateJournal} />
    </Switch>
  )
}

export default Journal;
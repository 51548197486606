import React from 'react';
import '../../../../src/assets/css/index.css'
import CardBody from 'reactstrap/lib/CardBody';

const AppUserInfo = ({Info, toggleTable}) => {

  return (
  <div>
    <div className="d-flex justify-content-between">
    <button className="back-btn btn-success" onClick={()=>toggleTable()}>back</button>
    <button className="back-btn btn-success" onClick={()=>toggleTable()}>Save</button>
    </div>
         
         <cardBody>
        <div className="row text-center">
        <div className="col-sm-6">
      <div><img src="https://www.canada.ca/content/dam/ircc/migration/ircc/english/passport/images/acceptable2-eng.jpg" alt="profile" style={{borderRadius: 100, width:210, height:210}}/></div>
      </div>
    <div className="col-sm-6 text-green m-3">
        <h6>Name: Adelaja Ibrahim</h6>
        <h6>Phone: +234</h6>
        <h6>Email: jekalousers@gmail.com</h6>
        <h6>Verified: Verified</h6>
    </div>
    </div>
    </cardBody>
    <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <a className="nav-item nav-link active font-sizes text-success font-weight-bold" id="nav-progress-tab" data-toggle="tab" 
             href="#nav-progress" role="tab" aria-controls="nav-progress" aria-selected="true"
            >Progress</a>
          <a className="nav-item nav-link font-sizes text-success font-weight-bold" id="nav-ridehistory-tab" data-toggle="tab" 
            href="#nav-ridehistory" role="tab" aria-controls="nav-ridehistory" aria-selected="false"
          >Ride History</a>
          <a className="nav-item nav-link font-weight-bold font-sizes text-success" id="nav-park-tab" data-toggle="tab" 
            href="#nav-park" role="tab" aria-controls="nav-park" aria-selected="false"
          >Park History</a>
          <a className="nav-item nav-link font-weight-bold font-sizes text-success" id="nav-paper-tab" data-toggle="tab" 
            href="#nav-paper" role="tab" aria-controls="nav-paper" aria-selected="false"
          >Paper History</a>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active p-4" id="nav-progress" 
          role="tabpanel" aria-labelledby="nav-progress-tab">
          <div className="d-flex flex-row justify-content-center">
          <h4 className="text-dark col-3 font-weight-bold text-right align-self-start"
          >Sign up:</h4> <span className="fa fa-check text-success align-self-center"
          style={{ fontSize: 22}}></span>
          </div>
          <div className="d-flex flex-row  justify-content-center">
          <h4 className="text-dark col-3 font-weight-bold text-right align-self-start"
          >Info: </h4><span className="fa fa-check text-success align-self-center"
          style={{ fontSize: 22}}></span>
          </div>
          <div className="d-flex flex-row justify-content-center">
          <h4 className="text-dark font-weight-bold col-3 text-right align-self-start"
          >BVN: </h4> <span className="fa fa-times mb-1 text-danger align-self-center"
          style={{ fontSize: 22}}></span>
          </div>
          <div className="d-flex flex-row justify-content-center">
          <h4 className="text-dark font-weight-bold col-3 text-right align-self-start"
        >BVN-Skipped:</h4> <span className="fa fa-check text-success align-self-center"
        style={{ fontSize: 22}}></span>
          </div>
      </div>
      <div className="tab-pane fade" id="nav-ridehistory" 
          role="tabpanel" aria-labelledby="nav-ridehistory-tab">...</div>
      <div className="tab-pane fade" id="nav-park" 
          role="tabpanel" aria-labelledby="nav-park-tab">...</div>
      <div className="tab-pane fade" id="nav-paper" role="tabpanel" aria-labelledby="nav-paper">...</div>
      </div>
  </div>);
};

export default AppUserInfo;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Pagination from "./commons/pagination";
import { paginate } from "./utils/paginate";
import {
  autoRepayment,
  createSetupReccurring,
  getReccurring,
  addNewUser
} from "../../../reducers/home";
import { toast } from "react-toastify";
import { navPermission } from "../../../actions/permission";

class Transactions extends Component {
  state = {
    accountName: "Account Name",
    accountNumber: "Account Number",
    card: "Cards",
    created_at: "Created_at",
    pageSize: 10,
    currentPage: 1,
    payment_id: "",
    orgId: "",
    editReccurring: {
      loan_amount: "",
      repayment_amount: "",
      day_of_the_month: new Date().getDate(),
      frequency: "month",
      meter: "",
      tenor: "",
      down_payment:  0,
      adv_month: 0,
      meter_cost:0,
      down_payment_noVAT:0
    }
  };
  componentDidMount() {
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    this.setState({ orgId });
    if (!navPermission(2)) {
      return (window.location.href = "/");
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      this.setState({ user, editReccurring: user }, () => {});
      this.props.autoRepayment({ orgId });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showAllAccountDetails !== this.props.showAllAccountDetails) {
      console.log("got here already!!", this.props.showAllAccountDetails);
    }
    if (prevProps.showUserReccurring !== this.props.showUserReccurring) {
      console.log(
        "got here showUserReccurring!",
        this.props.showUserReccurring
      );
    }
    if (
      prevProps.showCreateAllReccurring !== this.props.showCreateAllReccurring
    ) {
      this.setState({ editReccurring: {} }, () => {
        toast.error(this.props.showCreateAllReccurring.message);
      });
    }
    if (prevProps.showNewUser !== this.props.showNewUser) {
      const { error, data } = this.props.showNewUser;
      if (data && Object.keys(data).length) {
        // const { createAdmin } = data;
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error("User added succesfully !!!");
          this.handleCloseModal();
        });
      } else if (error && Object.keys(error).length) {
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error("Network error!");
        });
      }
    }
  }
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handlegetReccurring = payment_id => {
    this.setState({ payment_id }, () => {
      this.props.getReccurring(payment_id);
    });
  };
  handleCloseModal = () => {
    "[data-dismiss=modal]".trigger({ type: "click" });
  };
  populateAllAcountDetails = showAllAccountDetails => {
    const transactionsList = showAllAccountDetails.map(accountDetails => {
      const {
        account_name,
        account_number,
        card,
        created_at,
        payment_id
      } = accountDetails;
      return (
        <tr key={payment_id}>
          <td>{account_name}</td>
          <td>{account_number}</td>
          <td>{card}</td>
          <td>{created_at}</td>
          <td>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal1"
              onClick={() => this.handlegetReccurring(payment_id)}
            >
              Setup Recurring Payment
            </button>
          </td>
        </tr>
      );
    });
    return transactionsList;
  };
  populateGetReccurringDetails = getRecurring => {
    const reccurringList =
      getRecurring &&
      getRecurring.map(payload => {
        const {
          payment_id,
          loan_amount,
          repayment_amount,
          day_of_the_month,
          created_at,
          frequency
        } = payload;
        return (
          getRecurring && (
            <tr key={created_at}>
              <td>{loan_amount}</td>
              <td>{`₦${repayment_amount}`}</td>
              <td>{day_of_the_month}</td>
              <td>{created_at}</td>
              <td>{frequency}</td>
            </tr>
          )
        );
      });
    return reccurringList;
  };
  fillReccuringForm = (text, type) => {
    const { editReccurring } = this.state;
    let meterCost = 0;
    if(type == "meter") {
      if(text == "single") {
        meterCost = 44896.17;
      } else {
        meterCost = 82855.19;
      }
      const downPayment = (0.2 * meterCost).toFixed(2),
      downPaymentVAT = (1.075 * downPayment).toFixed(2);
      this.setState(
        {
          editReccurring: {
            down_payment:downPaymentVAT,
            down_payment_noVAT:downPayment,
            meter_cost:meterCost,
            meter:text,
            loan_amount:editReccurring.loan_amount,
            repayment_amount:editReccurring.repayment_amount,
            day_of_the_month:editReccurring.day_of_the_month,
            frequency:editReccurring.frequency,
            adv_month:editReccurring.adv_month,
            tenor:editReccurring.tenor
          }
        }, () => {
          console.log(this.state.editReccurring);
        });
    } else if(type == "tenor") {
      const tenor = text * 1,
      P = editReccurring.meter_cost - editReccurring.down_payment_noVAT,
      R = 21/(12 * 100),
      N = tenor;
      console.log(P);
      console.log(R);
      console.log(N);
      let EMI = (P * R * Math.pow((1+R), N))/(Math.pow((1+R), N)-1);
      EMI = EMI.toFixed(2);
      const loanAmount = (EMI * tenor).toFixed(2);
      console.log(EMI);
      this.setState(
        {
          editReccurring: {
            repayment_amount:EMI,
            tenor:text,
            meter_cost:editReccurring.meterCost,
            meter:editReccurring.meter,
            loan_amount:loanAmount,
            day_of_the_month:editReccurring.day_of_the_month,
            frequency:editReccurring.frequency,
            down_payment:editReccurring.down_payment,
            down_payment_noVAT:editReccurring.down_payment_noVAT,
            adv_month:editReccurring.adv_month
          }
        });
    } else {
      this.setState(
        {
          editReccurring: {
            loan_amount:
              type === "loan_amount" ? text : editReccurring.loan_amount,
            repayment_amount:
              type === "repayment_amount"
                ? text
                : editReccurring.repayment_amount,
            day_of_the_month:
              type === "day_of_the_month"
                ? text
                : editReccurring.day_of_the_month,
            frequency: type === "frequency" ? text : editReccurring.frequency,
            tenor: type === "tenor" ? text : editReccurring.tenor,
            adv_month: type === "adv_month" ? text : editReccurring.adv_month,
            meter: type === "meter" ? text : editReccurring.meter,
            meter_cost: meterCost > 0 ? meterCost: editReccurring.meter_cost,
            down_payment:editReccurring.down_payment,
            down_payment_noVAT:editReccurring.down_payment_noVAT
          }
        },
        () => {
          console.log(this.state.editReccurring);
        }
      );
    }
  };
  createSetupReccurring = () => {
    const {
      loan_amount,
      repayment_amount,
      day_of_the_month,
      frequency
    } = this.state.editReccurring;
    const { payment_id } = this.state;
    if (loan_amount === undefined || loan_amount.length < 1) {
      this.setState({ loginError: "Loan Amount can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (repayment_amount === undefined || repayment_amount.length < 1) {
      this.setState({ loginError: "Repayment amount can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (day_of_the_month === undefined || day_of_the_month.length < 1) {
      this.setState({ loginError: "Date can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else {
      const payload = {
        payment_id,
        loan_amount,
        repayment_amount,
        day_of_the_month,
        created_at: Date.now(),
        frequency
      };
      const newEditRecurring = {
        loan_amount: "",
        repayment_amount: "",
        day_of_the_month: "",
        frequency: ""
      };
      this.setState(
        { editReccurringSaveBtn: true, editReccurring: newEditRecurring },
        () => {
          this.props.createSetupReccurring(payload);
          toast.success("Setup Reccurring added successfully");
        }
      );
    }
  };
  handleEditReccurring = () => {
    this.createSetupReccurring();
  };

  render() {
    const { showAllAccountDetails, showUserReccurring } = this.props;
    const { pageSize, currentPage } = this.state;
    const datas = paginate(showAllAccountDetails, currentPage, pageSize);
    return (
      <div className="p-4">
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>{this.state.accountName}</th>
                <th>{this.state.accountNumber}</th>
                <th>{this.state.card}</th>
                <th>{this.state.created_at}</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody>
              {showAllAccountDetails && this.populateAllAcountDetails(datas)}
            </tbody>
          </table>

          {showAllAccountDetails && (
            <Pagination
              itemCount={showAllAccountDetails.length}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              currentPage={currentPage}
            />
          )}
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <button
                    className="modal-title btn-success btn-sm"
                    data-toggle="modal"
                    data-target="#exampleModal51"
                  >
                    ADD MORE
                  </button>

                  {(showUserReccurring && showUserReccurring.length > 0 && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Total Payment Due</th>
                          <th>Repayment Amount</th>
                          <th>Day of the month</th>
                          <th>Created At</th>
                          <th>Frequency</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.populateGetReccurringDetails(showUserReccurring)}
                      </tbody>
                    </table>
                  )) || (
                    <div>
                      <p>No repayment data yet</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal51"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              
              <div className="modal-body">
              <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Meter Type
                  </label>
                  <select
                    className="form-control mb-1 col-5  ml-auto"
                    id="exampleFormControlSelect1"
                    value={this.state.editReccurring.meter}
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "meter")
                    }
                  >
                    <option value="">Select Meter Type</option>
                    <option value="single">Single-Phase Meter</option>
                    <option value="three">Three-Phase Meter</option>
                  </select>
                </div>
              <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Tenor
                  </label>
                  <select
                    className="form-control mb-1 col-5  ml-auto"
                    id="exampleFormControlSelect1"
                    value={this.state.editReccurring.tenor}
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "tenor")
                    }
                  >
                    <option value="">Select Tenor</option>
                    <option value="12">12 months</option>
                    <option value="12">24 months</option>
                    <option value="36">36 months</option>
                  </select>
                </div>

                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Down Payment (20%)
                  </label>
                  <input
                    type="number"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="Down Payment (20%)"
                    value={
                      this.state.editReccurring &&
                      this.state.editReccurring.down_payment
                        ? this.state.editReccurring.down_payment
                        : ""
                    }
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "down_payment")
                    }
                  />
                </div>

                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Advance Payment (in Months)
                  </label>
                  <input
                    type="number"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="Advance Payment (in Months)"
                    value={
                      this.state.editReccurring &&
                      this.state.editReccurring.adv_month
                        ? this.state.editReccurring.adv_month
                        : ""
                    }
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "adv_month")
                    }
                  />
                </div>

                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Total Payment Due
                  </label>
                  <input
                    type="number"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="Total Payment Due"
                    value={
                      this.state.editReccurring &&
                      this.state.editReccurring.loan_amount
                        ? this.state.editReccurring.loan_amount
                        : ""
                    }
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "loan_amount")
                    }
                  />
                </div>
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2 ">
                    Repayment Amount
                  </label>
                  <input
                    type="number"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="REPAYMENT AMOUNT"
                    value={this.state.editReccurring.repayment_amount}
                    onChange={text =>
                      this.fillReccuringForm(
                        text.target.value,
                        "repayment_amount"
                      )
                    }
                  />
                </div>
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Day of the Month
                  </label>
                  <input
                    type="date"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="DAY OF THE MONTH"
                    value={this.state.editReccurring.day_of_the_month}
                    onChange={text =>
                      this.fillReccuringForm(
                        text.target.value,
                        "day_of_the_month"
                      )
                    }
                  />
                </div>
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Frequency
                  </label>
                  <select
                    className="form-control mb-1 col-5  ml-auto"
                    id="exampleFormControlSelect1"
                    value={this.state.editReccurring.frequency}
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "frequency")
                    }
                  >
                    <option value="day">Daily</option>
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.handleEditReccurring}
                    disabled={this.state.disableBtn}
                  >
                    Create Repayment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showNewAdmin: state.home.showNewAdmin,
  showAllAccountDetails: state.home.showAllAccountDetails,
  showUserReccurring: state.home.showUserReccurring,
  showCreateAllReccurring: state.home.showCreateAllReccurring,
  showNewUser: state.home.showNewUser
});

const mapDispatchToProps = {
  autoRepayment,
  createSetupReccurring,
  getReccurring,
  addNewUser
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Transactions)
);

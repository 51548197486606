import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Pagination from "./commons/pagination";
import { paginate } from "./utils/paginate";
import Loader from './Loader';
import FilterView from './FilterView';
import Modal from 'react-modal';
import Spinner from 'react-spinner-material';

import {
  Button,
  Container,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
} from 'reactstrap';
import {
  getMandate,
  nddAutoRepayment,
  createSetupReccurring,
  getReccurring,
  addNewUser,
  createMandate,
  updateMandateStatus
} from "../../../reducers/home";
import { toast } from "react-toastify";
import { notify } from './utils/notifications';
import { navPermission } from "../../../actions/permission";

Modal.setAppElement('#root');

const colStyle = {
  // whiteSpace: 'nowrap'
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class AutoRepayment extends Component {
  state = {
    chargeAmt:'',
    status:'',
    loading: false,
    modalIsOpen: false,
    token: '',
    submitAuthorization: false,
    accountName: "Account Name",
    accountNumber: "Account Number",
    bvn: "BVN",
    accountDesignation: "Designation",
    accountType: "Account Type",
    bankName: "Bank Name",
    created_at: "Created_at",
    pageSize: 10,
    currentPage: 1,
    payment_id: "",
    account_id:"",
    orgId: "",
    editmandate:{
      id:"",
      accountNumber:"",
      bankCode:"",
      payerName:"",
      accountName:"",
      amount:"",
      mandateCode: ""
    },
    editReccurring: {
      loan_amount: "",
      repayment_amount: "",
      day_of_the_month: new Date().getDate(),
      frequency: "monthly",
      meter: "",
      tenor: "",
      down_payment:  0,
      adv_month: 0,
      meter_cost:0,
      down_payment_noVAT:0
    }
  };
  componentDidMount = async () => {
    this.setState({ loading: true });
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    this.setState({ orgId });
    if (!navPermission(2)) {
      return (window.location.href = "/");
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      this.setState({ user}, () => {});
      await this.props.nddAutoRepayment({ orgId });
      this.setState({ loading: false });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showAllIcadAccountDetails !== this.props.showAllIcadAccountDetails) {
    }
    if (prevProps.showUserReccurring !== this.props.showUserReccurring) {
      console.log(
        "got here showUserReccurring!",
        this.props.showUserReccurring
      );
    }
    if (
      prevProps.showCreateAllReccurring !== this.props.showCreateAllReccurring
    ) {
      this.setState({ editReccurring: {} }, () => {
        toast.error(this.props.showCreateAllReccurring.message);
      });
    }
    if (prevProps.showNewUser !== this.props.showNewUser) {
      const { error, data } = this.props.showNewUser;
      if (data && Object.keys(data).length) {
        // const { createAdmin } = data;
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error("User added succesfully !!!");
          this.handleCloseModal();
        });
      } else if (error && Object.keys(error).length) {
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error(error.errors[0].message);
        });
      } else {
        this.setState({ newUserSaveBtn: false }, () => {
          toast.error("Network error!");
        });
      }
    }
  }

  refreshList = async () => {
    this.setState({ loading: true });
    try {
      const transfers =  await this.props.nddAutoRepayment({ orgId: this.state.orgId});
     /*
      const transfers = await this.props.getAllTransfers({
        orgId: this.state.orgId,
        ...this.state.filterObj,
        ...this.state.paginationObj
      });
      */
      if (transfers.error) {
        notify(transfers.message, 'error');
      } else {
        const { total_count: totalCount, offset, limit } = transfers.pagination;
        const totalPages = Math.ceil(totalCount / limit);
        const currentPage = offset / limit + 1;
        this.setState({
          transfers: transfers.data,
          pageHistory: { currentPage, totalPages }
        });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };
  handlegetReccurring = (payment_id, edit_mandate) => {
    this.setState({ payment_id, editmandate:edit_mandate }, () => {
      this.props.getReccurring(payment_id);
    });
  };
  handlegetMandate = (amount) => {
    const { editmandate } = this.state;
    this.setState({ editmandate : {
        id:editmandate.id,
        accountNumber:editmandate.accountNumber,
        bankCode:editmandate.bankCode,
        payerName:editmandate.payerName,
        accountName:editmandate.accountName,
        mandateCode: editmandate.mandateCode,
        amount:amount
    } }, () => {
      this.props.getMandate({account_id:editmandate.accountNumber});
    });
  };
  handleCloseModal = () => {
    "[data-dismiss=modal]".trigger({ type: "click" });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  };

  closeModal = () => {
    if (this.state.submitAuthorization) {
      return;
    }
    this.setState({ modalIsOpen: false, token: '', status: '' });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  
  populateAllAcountDetails = showAllIcadAccountDetails => {
    const transactionsList = showAllIcadAccountDetails.map(accountDetails => {
      const {
        accountName,
        accountNumber,
        bankName,
        accountDesignationName,
        accountTypeName,
        bvn,
        created_at,
        bankCode,
        id
      } = accountDetails;
      const editmandate = {
        id,
        accountNumber,
        bankCode,
        payerName:accountName,
        accountName:accountName,
        amount:"",
        mandateCode:""
      }
      return (
        <tr key={id}>
          <td>{accountName}</td>
          <td>{accountNumber}</td>
          <td>{bankName}</td>
          <td>{accountDesignationName}</td>
          <td>{accountTypeName}</td>
          <td>{created_at}</td>
          <td>
            <button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal1"
              onClick={() => this.handlegetReccurring(bvn, editmandate)}
            >
              Setup Recurring Payment
            </button>
          </td>
        </tr>
      );
    });
    return transactionsList;
  };
  populateGetReccurringDetails = getRecurring => {
    const reccurringList =
      getRecurring &&
      getRecurring.map(payload => {
        const {
          payment_id,
          loan_amount,
          repayment_amount,
          day_of_the_month,
          created_at,
          frequency
        } = payload;
        return (
          getRecurring && (
            <tr key={created_at}>
              <td>{loan_amount}</td>
              <td>{`₦${repayment_amount}`}</td>
              <td>{day_of_the_month}</td>
              <td>{created_at}</td>
              <td>{frequency}</td>
              <td><button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal2"
              onClick={() => this.handlegetMandate(loan_amount)}
            >
              Setup Mandate
            </button></td>
            </tr>
          )
        );
      });
    return reccurringList;
  };
  populateGetMandateDetails = getMandate => {
    const mandateList =
      getMandate &&
      getMandate.map(payload => {
        const {
          id,
          bankCode,
          payerName,
          accountNumber,
          amount,
          mandateCode,
          responseDescription,
          workFlowStatusDescription,
        } = payload;

        //refresh mandate status
       
        this.props.updateMandateStatus({mandateCode:mandateCode, orgId: this.state.orgId});


        //


        const responseDesc = responseDescription.replace("Welcome to NIBSS e-mandate authentication service, a seamless and convenient authentication experience.","");
        return (
          getMandate && (
            <tr key={id}>
              <td>{bankCode}</td>
              <td>{`₦${amount}`}</td>
              <td>{payerName}</td>
              <td>{accountNumber}</td>
              <td>{responseDesc}</td>
              <td>{mandateCode}</td>
              <td>{workFlowStatusDescription}</td>

            {workFlowStatusDescription == "Bank Approved" && (
              <td><button
              type="button"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal21"
              onClick={() => {
                this.setState({ status: 'pending' });
              }}
            >
              Charge
            </button></td>)
          }

            </tr>
          )
        );
      });
    return mandateList;
  };
  fillReccuringForm = (text, type) => {
    const { editReccurring } = this.state;
    let meterCost = 0;
    if(type == "meter") {
      if(text == "single") {
        meterCost = 44896.17;
      } else {
        meterCost = 82855.19;
      }
      const downPayment = (0.2 * meterCost).toFixed(2),
      downPaymentVAT = (1.075 * downPayment).toFixed(2);
      this.setState(
        {
          editReccurring: {
            down_payment:downPaymentVAT,
            down_payment_noVAT:downPayment,
            meter_cost:meterCost,
            meter:text,
            loan_amount:editReccurring.loan_amount,
            repayment_amount:editReccurring.repayment_amount,
            day_of_the_month:editReccurring.day_of_the_month,
            frequency:editReccurring.frequency,
            adv_month:editReccurring.adv_month,
            tenor:editReccurring.tenor
          }
        }, () => {
          console.log(this.state.editReccurring);
        });
    } else if(type == "tenor") {
      const tenor = text * 1,
      P = editReccurring.meter_cost - editReccurring.down_payment_noVAT,
      R = 21/(12 * 100),
      N = tenor;
      console.log(P);
      console.log(R);
      console.log(N);
      let EMI = (P * R * Math.pow((1+R), N))/(Math.pow((1+R), N)-1);
      EMI = EMI.toFixed(2);
      const loanAmount = (EMI * tenor).toFixed(2);
      console.log(EMI);
      this.setState(
        {
          editReccurring: {
            repayment_amount:EMI,
            tenor:text,
            meter_cost:editReccurring.meterCost,
            meter:editReccurring.meter,
            loan_amount:loanAmount,
            day_of_the_month:editReccurring.day_of_the_month,
            frequency:editReccurring.frequency,
            down_payment:editReccurring.down_payment,
            down_payment_noVAT:editReccurring.down_payment_noVAT,
            adv_month:editReccurring.adv_month
          }
        });
    } else {
      this.setState(
        {
          editReccurring: {
            loan_amount:
              type === "loan_amount" ? text : editReccurring.loan_amount,
            repayment_amount:
              type === "repayment_amount"
                ? text
                : editReccurring.repayment_amount,
            day_of_the_month:
              type === "day_of_the_month"
                ? text
                : editReccurring.day_of_the_month,
            frequency: type === "frequency" ? text : editReccurring.frequency,
            tenor: type === "tenor" ? text : editReccurring.tenor,
            adv_month: type === "adv_month" ? text : editReccurring.adv_month,
            meter: type === "meter" ? text : editReccurring.meter,
            meter_cost: meterCost > 0 ? meterCost: editReccurring.meter_cost,
            down_payment:editReccurring.down_payment,
            down_payment_noVAT:editReccurring.down_payment_noVAT
          }
        },
        () => {
          console.log(this.state.editReccurring);
        }
      );
    }
  };
  createSetupReccurring = () => {
    const {
      loan_amount,
      repayment_amount,
      day_of_the_month,
      frequency
    } = this.state.editReccurring;
    const { payment_id } = this.state;
    if (loan_amount === undefined || loan_amount.length < 1) {
      this.setState({ loginError: "Loan Amount can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (repayment_amount === undefined || repayment_amount.length < 1) {
      this.setState({ loginError: "Repayment amount can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else if (day_of_the_month === undefined || day_of_the_month.length < 1) {
      this.setState({ loginError: "Date can't be empty" }, () => {
        toast.error(`${this.state.loginError}`);
      });
    } else {
      const payload = {
        payment_id,
        loan_amount,
        repayment_amount,
        day_of_the_month,
        created_at: Date.now(),
        frequency
      };
      const newEditRecurring = {
        loan_amount: "",
        repayment_amount: "",
        day_of_the_month: "",
        frequency: "monthly"
      };
      this.setState(
        { editReccurringSaveBtn: true, editReccurring: newEditRecurring },
        () => {
          this.props.createSetupReccurring(payload);
          toast.success("Setup Reccurring added successfully");
        }
      );
    }
  };
  handleEditReccurring = () => {
    this.createSetupReccurring();
  };

  handleEditMandate = () => {
    let { editmandate, orgId} = this.state;
    editmandate = {...editmandate, orgId}
    this.props.createMandate(editmandate);
    toast.success("Mandate added successfully");
  };

  render() {
    const { showAllIcadAccountDetails, showUserReccurring, showAllMandateDetails } = this.props;
    const { pageSize, currentPage, loading,
      submitAuthorization,
      token,} = this.state;
    const datas = paginate(showAllIcadAccountDetails, currentPage, pageSize);
    const tokenError = token.length !== 6;
    return (
      <Container>
        <Row>{loading && <Loader />}</Row>
        <Row>
          <Col className='d-flex justify-content-start align-items-center'>
            <Button
              className='text-uppercase d-flex align-items-center mr-1'
              color='success'
              onClick={this.refreshList}
            >
              <i className='fas fa-redo mr-2' style={{ fontSize: 10 }}></i>{' '}
              <small className='font-weight-bold'>Refresh</small>
            </Button>

            <FilterView onFilter={this.onFilter} />
            </Col>
            </Row>
            <Row>
      <div className="p-4">
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>{this.state.accountName}</th>
                <th>{this.state.accountNumber}</th>
                <th>{this.state.bankName}</th>
                <th>{this.state.accountDesignation}</th>
                <th>{this.state.accountType}</th>
                <th>{this.state.created_at}</th>
                <th>{""}</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody>
              {showAllIcadAccountDetails && this.populateAllAcountDetails(datas)}
            </tbody>
          </table>

          {showAllIcadAccountDetails && (
            <Pagination
              itemCount={showAllIcadAccountDetails.length}
              pageSize={pageSize}
              onPageChange={this.handlePageChange}
              currentPage={currentPage}
            />
          )}
         
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <button
                    className="modal-title btn-success btn-sm"
                    data-toggle="modal"
                    data-target="#exampleModal51"
                  >
                    ADD MORE
                  </button>


                  {(showUserReccurring && showUserReccurring.length > 0 && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Total Due</th>
                          <th>Repay Amount</th>
                          <th>Pay Day</th>
                          <th>Created At</th>
                          <th>Frequency</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.populateGetReccurringDetails(showUserReccurring)}
                      </tbody>
                    </table>
                  )) || (
                    <div>
                      <p>No repayment data yet</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.handleEditMandate}
                    disabled={this.state.disableBtn}
                  >
                    ADD MORE
                  </button>

                  {(showAllMandateDetails && showAllMandateDetails.length > 0 && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Bank Code</th>
                          <th>Amount</th>
                          <th>Payer Name</th>
                          <th>Account</th>
                          <th>Message</th>
                          <th>Mandate Code</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.populateGetMandateDetails(showAllMandateDetails)}
                      </tbody>
                    </table>
                  )) || (
                    <div>
                      <p>No mandate data yet</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            className="modal fade"
            id="exampleModal21"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='form-group'>
                  <h6>Please enter Amount</h6>
                    <input
                      type='text'
                      className='form-control'
                      name='chargeAmt'
                      value={this.state.chargeAmt}
                      onChange={this.handleChange}
                    />
                    <h6>Please enter your token</h6>
                    <input
                      type='text'
                      className='form-control'
                      name='token'
                      value={this.state.token}
                      onChange={this.handleChange}
                    />
                    <button
                      onClick={this.authorizeTransaction}
                      className='btn btn-primary d-flex justify-content-center align-items-center w-100 mt-2'
                      disabled={tokenError || submitAuthorization}
                    >
                      {!submitAuthorization && (
                        <small className='font-weight-bold text-uppercase'>
                          {this.state.status === 'rejected' ? 'Reject' : 'Authorize'}
                        </small>
                      )}
                      {submitAuthorization && (
                        <Spinner
                          size={16}
                          spinnerColor={'#ffffff'}
                          spinnerWidth={2}
                          visible={true}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div
          className="modal fade"
          id="exampleModal51"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              
              <div className="modal-body">
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Total Payment Due
                  </label>
                  <input
                    type="number"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="Total Payment Due"
                    value={
                      this.state.editReccurring &&
                      this.state.editReccurring.loan_amount
                        ? this.state.editReccurring.loan_amount
                        : ""
                    }
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "loan_amount")
                    }
                  />
                </div>
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2 ">
                    Repayment Amount
                  </label>
                  <input
                    type="number"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="REPAYMENT AMOUNT"
                    value={this.state.editReccurring.repayment_amount}
                    onChange={text =>
                      this.fillReccuringForm(
                        text.target.value,
                        "repayment_amount"
                      )
                    }
                  />
                </div>
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Day of the Month
                  </label>
                  <input
                    type="date"
                    className="form-control mb-1 col-5 ml-auto"
                    placeholder="DAY OF THE MONTH"
                    value={this.state.editReccurring.day_of_the_month}
                    onChange={text =>
                      this.fillReccuringForm(
                        text.target.value,
                        "day_of_the_month"
                      )
                    }
                  />
                </div>
                <div className="d-flex flex-row container">
                  <label className="justify-content-center mt-1 mr-2">
                    Frequency
                  </label>
                  <select
                    className="form-control mb-1 col-5  ml-auto"
                    id="exampleFormControlSelect1"
                    value={this.state.editReccurring.frequency}
                    onChange={text =>
                      this.fillReccuringForm(text.target.value, "frequency")
                    }
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.handleEditReccurring}
                    disabled={this.state.disableBtn}
                  >
                    Create Repayment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Create Mandate*/}

      </div>
      </Row></Container>
    );
  }
}

const mapStateToProps = state => ({
  showNewAdmin: state.home.showNewAdmin,
  showAllIcadAccountDetails: state.home.showAllIcadAccountDetails,
  showUserReccurring: state.home.showUserReccurring,
  showAllMandateDetails:state.home.showAllMandateDetails,
  showCreateAllReccurring: state.home.showCreateAllReccurring,
  showNewUser: state.home.showNewUser
});

const mapDispatchToProps = {
  getMandate,
  nddAutoRepayment,
  createSetupReccurring,
  getReccurring,
  addNewUser,
  createMandate,
  updateMandateStatus
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AutoRepayment)
);

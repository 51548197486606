import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Spinner,
  Input,
} from 'reactstrap';
import { getAccounts } from '../../../actions/accountsActions';
import Pagination from './../Transactions/Pagination';
import FilterView from './../Transactions/FilterView';
class AccountOpening extends Component {
  componentDidMount() {
    this.props.getAccounts({ limit: 10, offset: 0 });
  }

  state = {
    filterObj: {}
  };

  onPageChange = pageNumber => {
    const { accounts, getAccounts } = this.props;
    const { filterObj } = this.state;
    const { pagination } = accounts;
    const { limit, offset } = pagination;
    const paginationObj = { limit, offset };
    paginationObj.offset = (pageNumber - 1) * limit;
    getAccounts({ ...filterObj, ...paginationObj });
  };

  editAccount = id => {
    this.props.history.push('/transactions/viewaccount', { id });
  };

  handleChange = event => {
  };

  mapAccountlList = () => {
    const { accounts } = this.props;
    const { accountList } = accounts;
    let numbering = 1;
    const mappedAccountList = accountList.map(account => {
      const {
        id,
        surname,
        first_name: firstName,
        middle_name: middleName,
        phone_number: phoneNumber,
        email,
        account_type: accountType,
        account_type_others: accountTypeOthers,
        account_number: accountNumber,
        nuban: nuban,
        branch_code: branchCode,
        staff_id: staffId,
        created_at: createdAt
      } = account;
      return (
        <tr className='tr' key={id}>
          <th scope='row'>{numbering++}</th>
          <td>
            <i
              className='fas fa-edit text-success cursor-pointer'
              onClick={() => this.editAccount(id)}
            ></i>
          </td>
          <td>
            {staffId ? (
              <span>
                <i className='fas fa-check text-info'></i>
              </span>
            ) : (
              <i className='fas fa-exclamation text-warning'></i>
            )}
          </td>
          <td>{`${surname}, ${firstName} ${middleName}`}</td>
          <td>{email}</td>
          <td>{phoneNumber}</td>
          <td>{accountType || accountTypeOthers}</td>
          <td>{accountNumber}</td>
          <td>{branchCode}</td>
          <td>{nuban}</td>
          <td>{createdAt}</td>
        </tr>
      );
    });
    return mappedAccountList;
  };

  onFilter = payload => {
    const { getAccounts } = this.props;
    this.setState({ filterObj: payload });
    getAccounts({ ...payload, ...{ limit: 10, offset: 0 } });
  };

  render() {
    const { accounts } = this.props;
    const { pageLoading, pagination } = accounts;
    const mappedAccountList = this.mapAccountlList();
    const { total_count: totalCount, offset, limit } = pagination;
    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = offset / limit + 1;
    
    return (
      <Container className='loader-container'>
        {pageLoading && (
          <div className='loading-screen'>
            <Spinner
              color='success'
              style={{
                width: '3rem',
                height: '3rem'
              }}
            />
          </div>
        )}
        <Row>
          <Col>
            <FilterView onFilter={this.onFilter} exclude={['status']} />
            <div className='flex-1'>
              <label
                className='text-uppercase d-flex align-items-center float-right mr-2'
                color='success'
              >
                <small className='font-weight-bold'>Bulk Upload</small>
                <Input
                          accept='image/csv, image/xslx'
                          onChange={this.handleChange}
                          type='file'
                          name='id_image'
                          className='d-none'
                        />
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table borderless responsive>
              <thead>
                <tr className='text-uppercase'>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <small className='font-weight-bold'>Name</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Email</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Phone number</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Account type</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Account number</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Branch code</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>NUBAN</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Date</small>
                  </th>
                </tr>
              </thead>
              <tbody>{mappedAccountList}</tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className='d-flex justify-content-end mr-2'>
            {totalPages > 1 && (
              <Pagination
                pageHistory={{ totalPages, currentPage }}
                onPageChange={this.onPageChange}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { accounts } = state;
  return {accounts}
};

const mapDispatchToProps = {
  getAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountOpening);

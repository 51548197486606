import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showChange, fetchLists } from '../../../reducers/home';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import { Button } from 'reactstrap';

// import cellEditFactory from 'react-bootstrap-table2-editor';

// const products = [
//   { userId: 10, id: 10, title: 'delectus aut autemfirst load', completed: true }
// ];

const columns = [
  {
    dataField: 'id',
    text: 'User ID',
    style: {
      fontWeight: 'bold',
      fontSize: '18px'
    },
    headerStyle: {
      padding: '10px',
      margin: '0px',
      width: '10%'
    }
  },
  {
    dataField: 'title',
    text: 'User Title',
    filter: textFilter(),
    // headerFormatter: filterFormatter
  },
  {
    dataField: 'completed',
    text: 'Completed',
    formatter: completedFormatter,
    filter: textFilter(),
  }
];

// function filterFormatter(title, { sortElement, filterElement }) {
//   return (
//     <div style={{ display: 'flex', flexDirection: 'column' }}>
//       {filterElement}
//       {title}
//       {sortElement}
//     </div>
//   );
// }

function completedFormatter(completed) {
  if (completed) {
    return (
      <span>
        <strong style={{ color: 'red' }}>true</strong>
      </span>
    );
  }
  return <span>false</span>;
}

export class UniqueUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Adelaja Ibrahim Abiola',
      products: []
    };
  }
  componentDidMount = () => {
    this.props.fetchLists();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showTodo !== this.props.showTodo) {
      console.log('didupdate', this.props.showTodo);
      const prod = [];
      prod.push(this.props.showTodo);

      this.setState({ products: this.props.showTodo });
    }
  };

  render() {
    return (
      <main className='dash-content'>
        <div className='container-fluid'>
          <BootstrapTable
            bootstrap4
            keyField='id'
            data={this.state.products}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory()}
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  showChangeUpdate: state.home.showChangeUpdate,
  showTodo: state.home.showTodo
});

const mapDispatchToProps = {
  showChange,
  fetchLists
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniqueUsers);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Modal,
  ModalBody
} from 'reactstrap';

import {
  setBillsPaymentProperty,
  getCategories,
  getSubCategories,
  getMerchants,
  getVariations,
  handleChange,
  createBillsPayment,
  toggleSuccessModal
} from '../../../actions/billsPaymentActions';

class PayBills extends Component {
  componentDidMount() {
    this.props.getCategories();
  }

  /**
   * Handles changes of bills form input
   * @param {object} event
   */
  handleChange = event => {
    const { name, value } = event.target;
    const { billsPayment } = this.props;
    let selectedObj = {};
    switch (name) {
      case 'selectedCategory':
        const { categories } = billsPayment;
        selectedObj = categories.find(category => category.name === value);
        break;
      case 'selectedSubCategory':
        const { subCategories } = billsPayment;
        selectedObj = subCategories.find(
          subCategory => subCategory.name === value
        );
        break;
      case 'selectedMerchant':
        const { merchants } = billsPayment;
        selectedObj = merchants.find(merchant => merchant.name === value);
        break;
      case 'selectedVariation':
        const { variations } = billsPayment;
        selectedObj = variations.find(variation => variation.name === value);
        break;
      default:
        selectedObj = { name: value };
    }
    this.props.handleChange(name, selectedObj);
  };

  /**
   * Handles submission of bills form
   */
  handleSubmit = event => {
    event.preventDefault();
    const { billsPayment, createBillsPayment } = this.props;
    const {
      variations,
      selectedVariation,
      amount,
      accountNumber
    } = billsPayment;

    const variation = variations.find(curr => curr.name === selectedVariation);

    const payload = {
      accountNumber,
      variation_id: variation.id,
      amount,
      trans_type: 'DR'
    };

    createBillsPayment(payload);
  };

  /**
   * Checks if details are complete to enable submit button
   * @returns {boolean}
   */
  disabledSubmit = () => {
    const { billsPayment } = this.props;
    const {
      selectedCategory,
      selectedSubCategory,
      selectedMerchant,
      selectedVariation,
      amount,
      accountNumber
    } = billsPayment;

    if (
      !selectedCategory ||
      selectedCategory === '' ||
      !selectedSubCategory ||
      selectedSubCategory === '' ||
      !selectedMerchant ||
      selectedMerchant === '' ||
      !selectedVariation ||
      selectedVariation === '' ||
      !amount ||
      amount === '' ||
      !accountNumber ||
      accountNumber === ''
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { billsPayment, toggleSuccessModal } = this.props;
    const {
      categories,
      categoriesLoading,
      selectedCategory,
      subCategories,
      subCategoriesLoading,
      selectedSubCategory,
      merchants,
      merchantsLoading,
      selectedMerchant,
      variations,
      variationsLoading,
      selectedVariation,
      amount,
      accountNumber,
      refName,
      submitBillLoading,
      submitBillModalOpen,
      createdTellerNo
    } = billsPayment;
    const submitDisabled = this.disabledSubmit();

    const mappedCategories = categories.map(category => {
      return (
        <option key={category.id} value={category.name}>
          {category.name}
        </option>
      );
    });
    const mappedSubCategories = subCategories.map(subCategory => {
      return (
        <option key={subCategory.id} value={subCategory.name}>
          {subCategory.name}
        </option>
      );
    });
    const mappedMerchants = merchants.map(merchant => {
      return (
        <option key={merchant.id} value={merchant.name}>
          {merchant.name}
        </option>
      );
    });
    const mappedVariations = variations.map(variation => {
      return (
        <option key={variation.id} value={variation.name}>
          {variation.name}
        </option>
      );
    });

    return (
      <Container>
        <Row>
          <Col
            xs={12}
            sm={{ size: 6, offset: 3 }}
            className='bg-white shadow-sm'
          >
            <Modal isOpen={submitBillModalOpen} toggle={toggleSuccessModal}>
              <ModalBody className='p-0'>
                <div
                  className='bg-success d-flex justify-content-center align-items-center'
                  style={{ height: 150 }}
                >
                  <i className='far fa-5x fa-check-circle text-white'></i>
                </div>
                <div className='text-center p-4' style={{ minHeight: 150 }}>
                  <h5 className='mb-3'>Bill paid for successfully</h5>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`https://api.jekalo.com/api/receipts/transactions?teller_no=${createdTellerNo}`}
                  >
                    <Button size='sm' color='success'>
                      <span>
                        <span className='mr-2'>
                          <i className='fas fa-cloud-download-alt'></i>
                        </span>
                        <span>Download receipt</span>
                      </span>
                    </Button>
                  </a>
                </div>
              </ModalBody>
            </Modal>
            <Form className='mt-4'>
              <Row>
                <Col className='text-success'>Bills Payment</Col>
              </Row>
              <hr />
              <FormGroup row className='mb-4'>
                <Label
                  className='font-weight-normal text-secondary'
                  style={{ fontSize: 14 }}
                  for='selectedCategory'
                  sm={4}
                >
                  Bill Category
                </Label>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                  <Input
                    className='form-control'
                    type='select'
                    name='selectedCategory'
                    id='selectedcategory'
                    value={selectedCategory}
                    onChange={this.handleChange}
                    style={{ borderRadius: 4, flex: 1 }}
                  >
                    <option value=''>Select category</option>
                    {mappedCategories}
                  </Input>
                  <div className='ml-3' style={{ width: 30 }}>
                    {categoriesLoading && (
                      <Spinner
                        color='success'
                        size='sm'
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row className='mb-4'>
                <Label
                  className='font-weight-normal text-secondary'
                  style={{ fontSize: 14 }}
                  for='selectedSubCategory'
                  sm={4}
                >
                  Bill Sub-category
                </Label>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                  <Input
                    className='form-control'
                    type='select'
                    name='selectedSubCategory'
                    id='selectedSubCategory'
                    value={selectedSubCategory}
                    onChange={this.handleChange}
                    style={{ borderRadius: 4, flex: 1 }}
                  >
                    <option value=''>Select Sub-category</option>
                    {mappedSubCategories}
                  </Input>
                  <div className='ml-3' style={{ width: 30 }}>
                    {subCategoriesLoading && (
                      <Spinner
                        color='success'
                        size='sm'
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row className='mb-4'>
                <Label
                  className='font-weight-normal text-secondary'
                  style={{ fontSize: 14 }}
                  for='selectedSubCategory'
                  sm={4}
                >
                  Merchant
                </Label>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                  <Input
                    className='form-control'
                    type='select'
                    name='selectedMerchant'
                    id='selectedMerchant'
                    value={selectedMerchant}
                    onChange={this.handleChange}
                    style={{ borderRadius: 4, flex: 1 }}
                  >
                    <option value=''>Select merchant</option>
                    {mappedMerchants}
                  </Input>
                  <div className='ml-3' style={{ width: 30 }}>
                    {merchantsLoading && (
                      <Spinner
                        color='success'
                        size='sm'
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row className='mb-4'>
                <Label
                  className='font-weight-normal text-secondary'
                  style={{ fontSize: 14 }}
                  for='selectedVariation'
                  sm={4}
                >
                  Variation
                </Label>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                  <Input
                    className='form-control'
                    type='select'
                    name='selectedVariation'
                    id='selectedVariation'
                    value={selectedVariation}
                    onChange={this.handleChange}
                    style={{ borderRadius: 4, flex: 1 }}
                  >
                    <option value=''>Select Variation</option>
                    {mappedVariations}
                  </Input>
                  <div className='ml-3' style={{ width: 30 }}>
                    {variationsLoading && (
                      <Spinner
                        color='success'
                        size='sm'
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row className='mb-4'>
                <Label
                  className='font-weight-normal text-secondary'
                  style={{ fontSize: 14 }}
                  for='amount'
                  sm={4}
                >
                  Amount
                </Label>
                <Col xs={12} md={8} className='d-flex align-items-center'>
                  <Input
                    className='form-control'
                    type='text'
                    name='amount'
                    id='amount'
                    value={amount}
                    onChange={this.handleChange}
                    style={{ borderRadius: 4, flex: 1 }}
                  ></Input>
                  <div className='ml-3' style={{ width: 30 }} />
                </Col>
              </FormGroup>
              {refName && (
                <FormGroup row className='mb-4'>
                  <Label
                    className='font-weight-normal text-secondary'
                    style={{ fontSize: 14 }}
                    for='amount'
                    sm={4}
                  >
                    {refName}
                  </Label>
                  <Col xs={12} md={8} className='d-flex align-items-center'>
                    <Input
                      className='form-control'
                      type='text'
                      name='accountNumber'
                      id='accountNumber'
                      value={accountNumber}
                      onChange={this.handleChange}
                      style={{ borderRadius: 4, flex: 1 }}
                    ></Input>
                    <div className='ml-3' style={{ width: 30 }} />
                  </Col>
                </FormGroup>
              )}
              <FormGroup row>
                <Col
                  xs={12}
                  md={{ offset: 4, size: 8 }}
                  className='d-flex justify-content-center'
                >
                  <Button
                    color='success'
                    className='form-control bg-success text-white mb-4 w-100'
                    size='sm'
                    disabled={submitDisabled || submitBillLoading}
                    onClick={this.handleSubmit}
                    style={{ borderRadius: 4 }}
                  >
                    {!submitBillLoading && 'Complete transaction'}
                    {submitBillLoading && (
                      <Spinner
                        color='white'
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </Button>
                  <div className='ml-3' style={{ width: 30 }}></div>
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { billsPayment } = state;
  return { billsPayment };
};

const mapDispatchToProps = {
  getCategories,
  getSubCategories,
  getMerchants,
  getVariations,
  handleChange,
  createBillsPayment,
  setBillsPaymentProperty,
  toggleSuccessModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PayBills);

import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  // Form,
  // FormGroup,
  // Input,
  // Label,
  Table,
} from "reactstrap";
import { getOrgJournals } from "../../../actions/journalAction";
import PostingPagination from "../../../Utils/PostingPagination";
import Records from "./entryRecordTable";
import { Modal as ReactStrapModal, ModalHeader, ModalBody } from 'reactstrap';

const customModalStyles={
  content:{
    display: 'absolute',
    top: '50%',
    left: '50%',
    width: 'auto',
    height: '80%',
    transform: 'translate(-50%, -50%)',
  },
}


const Journals=()=>{

  const [ journals, setJournals] = useState([]);
  const [paginationParams, setPaginationParams] = useState({limit:4});
  const [loading, setLoading] = useState(true);
  const [responseError, setResponseError] = useState(null);
  const [modalKey, setModalKey] = useState(false);
  const [activeJournal, setActiveJournal] = useState(null);
   



  const loader=async(_pagination_params)=>{
    try {
      setLoading(true);
      const {entries, pagination} = await getOrgJournals(_pagination_params);
      setJournals(entries);
      setPaginationParams(pagination); 
      setResponseError(null);
    } catch (error) {
      toast.error("Something went wrong");
    }finally{
      setLoading(false);
    }
  }

  const loadJournals = useCallback(async()=>{
    return loader({limit:5})
  })

  useEffect(()=>{
    loadJournals()
    .catch(err=>{
      setLoading(false);
      toast.error(err.message);
    })

  }, [])


  const modalToggle=()=>{
    if(modalKey){
      setActiveJournal(null);      
    }
    setModalKey((key)=> !key);
  }

  const showJournalRecords=async(journal)=>{
    await setActiveJournal(journal);
    setModalKey(true);
  }

  return(<Container>
    <h1 className="">Journal Entries</h1>
    <div className="d-flex mb-2 rounded justify-content-end pr-4">
      <Link to="/journal/new" 
        className="bg-white p-2 rounded-sm"
      >
          Create New Entry
      </Link>
    </div>
    <Row>
      <Col>
        <PostingPagination {...paginationParams} caller={loader} />
      </Col>
    </Row>
    <Row>
      <Col>
        <Table>
          <thead>
            <tr className="text-uppercase">
              <th>
                <small className="font-weight-bold">Operation Date</small>
              </th>
              <th>
                <small className="font-weight-bold">Branch</small>
              </th>
              <th>
                <small className="font-weight-bold">Narration</small>
              </th>
              <th>
                <small className="font-weight-bold">status</small>
              </th>
              <th>
                <small className="font-weight-bold">Records</small>
              </th>
            </tr>
          </thead>
            <tbody>
            {loading&&
              <tr className="font-weight-bold">
                <td colSpan={5} className="text-center py-5">
                  Loading entries...
                </td>                
              </tr>
            }
            {!loading && journals && journals.map((entry, index) => (
              <tr key={index}>
                <td>{entry.operationDate}</td>
                <td>{entry.branch}</td>
                <td>{entry.narration}</td>
                <td className={entry.status !== "PENDING"?'text-success':'' } >{entry.status}</td>
                <td>
                  <Button className="btn-sm" 
                    onClick={()=>{showJournalRecords(entry)}}
                  >
                    <i className="fas fa-book"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row>
      <Col>
        <PostingPagination {...paginationParams} caller={loader} />
      </Col>
    </Row>
    <ReactStrapModal size="lg" isOpen={modalKey} toggle={modalToggle} >
      <ModalHeader>
        Journal Details
      </ModalHeader>
      <ModalBody>
        {/* Details onf current actives journal entry */}
        { activeJournal &&  Records(activeJournal) }
      </ModalBody>

    </ReactStrapModal>
    
  </Container>)
}

export default Journals;
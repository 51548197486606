import accountsConstants from './actionConstants';
import postConstants from '../constants/postingConstants';

const {
  POSTINGS,
  FETCTHING_POSTS,
  FETCHED,
  PENDING_POSTING_REQUEST,
  PENDING_POSTING_SUCCESS,
  PENDING_POSTING_FAIL,
} = postConstants

const {
  TRANSACTION_POSTING_LOADING,
  TRANSACTION_POSTING_SUCCESS,
  TOGGLE_POSTING_MODAL,
} = accountsConstants;



const initialState = {
  pageLoading: false,
  openModal: false,
  Postings: null, // posting data
  postFetched: null, // fetch resonpose state, false=>error, true=>sucess
  isFetching: false, // fecthing state
};

const postingReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case TRANSACTION_POSTING_LOADING: {
      const { payload } = action;
      const newState = { ...state, ...payload };
      return newState;
    }
    case TRANSACTION_POSTING_SUCCESS: {
      const { payload } = action;
      const newState = { ...state, ...payload };
      return newState;
    }
    case TOGGLE_POSTING_MODAL: {
      return {
        ...state,
        pageLoading: false,
        openModal: false,
      }
    }
    case POSTINGS: {
      return { ...state, Postings: action.payload }
    }
    case FETCTHING_POSTS: {
      return { ...state, isFetching: action.payload }
    }
    case FETCHED: {
      return { ...state, postFetched: action.payload }
    }
    default:
      return state;
  }
};
const pendingPostingsReducer = (state = { pendingPostings: {} }, action) => {
  switch (action.type) {
    case PENDING_POSTING_REQUEST:
      return { ...state, loading: true };
    case PENDING_POSTING_SUCCESS:
      return { loading: false, success: true, pendingPostings: action.payload };
    case PENDING_POSTING_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};


export { postingReducer, pendingPostingsReducer };
import { billsPaymentConstants } from './../constants/billsPaymentConstants';

const {
  CLEAR_SELECTION,
  SET_PROPERTY,
  SUCCESS_MODAL_TOGGLE
} = billsPaymentConstants;

const initialState = {
  categories: [],
  selectedCategory: '',
  categoriesLoading: false,
  categoriesLoadingError: null,
  subCategories: [],
  selectedSubCategory: '',
  subCategoriesLoading: false,
  subCategoriesLoadingError: null,
  merchants: [],
  selectedMerchant: '',
  merchantsLoading: false,
  merchantsLoadingError: null,
  variations: [],
  selectedVariation: '',
  variationsLoading: false,
  variationsLoadingError: null,
  amount: '',
  accountNumber: '',
  refName: null,
  pageLoading: false,
  submitBillLoading: false,
  submitBillError: null,
  submitBillSuccess: null,
  submitBillModalOpen: false,
  createdTellerNo: null,
  billListLoading: false,
  billList: [],
  pagination: {
    limit: 0,
    offset: 0,
    total_count: 0,
  }
};

const billsPaymentReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case CLEAR_SELECTION: {
      return {
        ...state,
        ...{
          selectedCategory: '',
          selectedSubCategory: '',
          selectedMerchant: '',
          amount: ''
        }
      };
    }
    case SET_PROPERTY: {
      const { payload } = action;
      const newState = { ...state, ...payload };
      return newState;
    }
    case SUCCESS_MODAL_TOGGLE: {
      const newState = {
        ...state,
        ...{ submitBillModalOpen: !state.submitBillModalOpen }
      };
      return newState;
    }
    default:
      return state;
  }
};

export default billsPaymentReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import {
  requery,
  smsRequestsHistory,
  smsRecipientsHistory,
} from './../../../actions/bulkSmsAction';
import { Table, ModalHeader, ModalBody, Modal, ModalFooter } from 'reactstrap';
import Pagination from './../Transactions/Pagination';
import Loader from './../Transactions/Loader';

class BulkSmsHistory extends Component {
  state = {
    loading: false,
    detailsModal: false,
    currentPage: 1,
    pagesCount: 1,
    currentRecipientsPage: 1,
    totalRecipientsPages: 1,
    messages: [],
    messageRecipients: [],
  };

  componentDidMount = async () => {
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    this.setState({ orgId });
    const realtimeSmsAdded = this.realtimeSmsAdded;
    var channel = window.pusher.subscribe('maxipropay-channel');
    channel.bind('sms-process-request', function (data) {
      const decodedData = JSON.parse(data.message);
      const {
        sms_requests_id: id,
        total_count: addedCount,
        delivered_count: deliveredCount,
      } = decodedData;
      realtimeSmsAdded(id, addedCount, deliveredCount);
    });

    this.fetchSmsRequestsHistory(1);
  };

  fetchSmsRequestsHistory = (page) => {
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    const { smsRequestsHistory } = this.props;

    this.setState({ loading: true });
    smsRequestsHistory({ orgId, page })
      .then((res) => {
        if (res) {
          this.populateMessages(res);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  populateMessages = (smsResponse) => {
    const { data, pagination } = smsResponse;
    this.setState({
      messages: data,
      currentPage: pagination.current_page,
      totalPages: pagination.total_pages,
    });
  };

  toggleDetailsModal = () => {
    const { detailsModal } = this.state;
    this.setState({ detailsModal: !detailsModal });
  };

  getHistoryRecipients = (id, page = 1) => {
    const { smsRecipientsHistory } = this.props;
    this.setState({ loading: true, smsRequestId: id });
    smsRecipientsHistory({ smsRequestId: id, page })
      .then((res) => {
        if (res) {
          const { data, pagination } = res;
          this.setState({
            messageRecipients: data,
            currentRecipientsPage: pagination.current_page,
            totalRecipientsPages: pagination.total_pages,
          });
          this.setState({ detailsModal: true });
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  realtimeSmsAdded = (id, addedCount, deliveredCount) => {
    const { messages } = this.state;
    const updateMessages = messages.map((message) => {
      if (message.id === id) {
        message.total_messages = addedCount;
        message.delivered_messages = deliveredCount;
      }
      return message;
    });
    this.setState({ messages: updateMessages });
  };

  changePage = (page) => {
    this.fetchSmsRequestsHistory(page);
  };

  requery = (id) => {
    const { user } = window.localStorage;
    const { org_id: orgId } = JSON.parse(user);
    this.setState({ loading: true });
    const { requery } = this.props;
    requery({ id, orgId })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      loading,
      detailsModal,
      messages,
      messageRecipients,
      currentPage,
      totalPages,
      currentRecipientsPage,
      totalRecipientsPages,
      smsRequestId,
    } = this.state;

    const mappedMessages = messages.map((message) => {
      return (
        <tr className='bg-white centered-td' key={message.id}>
          <td style={{ minWidth: 180, maxWidth: 200 }}>{message.message}</td>
          <td>
            <Button
              size={'sm'}
              color={'link'}
              onClick={() => {
                this.getHistoryRecipients(message.id);
              }}
            >
              {message.delivered_messages} delivered of {message.total_messages}{' '}
              sent
            </Button>
          </td>
          <td>
            <Button
              size='sm'
              color={'primary'}
              onClick={() => this.requery(message.id)}
            >
              Requery
            </Button>
          </td>
          <td>{new Date(message.created_at).toUTCString()}</td>
        </tr>
      );
    });

    const mappedMessageRecipients = messageRecipients.map((recipient) => {
      return (
        <div
          className='d-flex justify-content-around align-items-center mb-2'
          key={recipient.id}
        >
          <div>
            <strong>{recipient.phone}</strong>
          </div>
          <div>
            <strong>{recipient.status}</strong>
          </div>
        </div>
      );
    });

    return (
      <Container>
        <Row>
          <Col>{loading && <Loader />}</Col>
        </Row>
        <Row>
          <Col>
            <Modal isOpen={detailsModal} toggle={this.toggleDetailsModal}>
              <ModalHeader toggle={this.toggleDetailsModal}>
                Recipients
              </ModalHeader>
              <ModalBody>{mappedMessageRecipients}</ModalBody>
              <ModalFooter>
                <Pagination
                  pageHistory={{
                    currentPage: currentRecipientsPage,
                    totalPages: totalRecipientsPages,
                  }}
                  onPageChange={(page) => {
                    this.getHistoryRecipients(smsRequestId, page);
                  }}
                ></Pagination>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ size: 10, offset: 1 }} className=''>
            <Table
              responsive
              borderless
              style={{ borderCollapse: 'separate', borderSpacing: '0 5px' }}
            >
              <thead>
                <tr className='text-uppercase'>
                  <th>
                    <small className='font-weight-bold'>Message</small>
                  </th>
                  <th>
                    <small className='font-weight-bold'></small>
                  </th>
                  <th>
                    <small className='font-weight-bold'></small>
                  </th>
                  <th>
                    <small className='font-weight-bold'>Date sent</small>
                  </th>
                </tr>
              </thead>
              <tbody>{mappedMessages}</tbody>
            </Table>
            <div className='d-flex justify-content-end'>
              <Pagination
                pageHistory={{
                  currentPage: currentPage,
                  totalPages: totalPages,
                }}
                onPageChange={(page) => {
                  this.changePage(page);
                }}
              ></Pagination>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  smsRequestsHistory,
  requery,
  smsRecipientsHistory,
};

export default connect(null, mapDispatchToProps)(BulkSmsHistory);

export const billsPaymentConstants = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_SUB_CATEGORIES: 'GET_SUB_CATEGORIES',
  GET_MERCHANTS: 'GET_MERCHANTS',
  GET_VARIATIONS: 'GET_VARIATIONS',
  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
  SET_SELECTED_SUB_CATEGORY: 'SET_SELECTED_SUB_CATEGORY',
  SET_SELECTED_MERCHANT: 'SET_SELECTED_MERCHANT',
  SET_SELECTED_VARIATION: 'SET_SELECTED_VARIATION',
  SET_AMOUNT: 'SET_AMOUNT',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  SET_PROPERTY: 'SET_PROPERTY',
  SUCCESS_MODAL_TOGGLE: 'SUCCESS_MODAL_TOGGLE'
};

export default billsPaymentConstants;

import { accountsConstants } from './../constants/accountsConstants';

const { SET_ACCOUNT_FIELD, SET_EDIT_ACCOUNT } = accountsConstants;

const initialState = {
  salaryHistories: {data:[]},
  loanHistories: {data:[]},
};

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_SALARY_HISTORIES':
        console.log("action", action);
      return updateObject(state, { salaryHistories: action.salaryHistories });
    case 'SAVE_LOAN_HISTORIES':
      return updateObject(state, { loanHistories: action.loanHistories });
    default:
      return state;
  }
};

export default loanReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import {
  Row, Col, Table, Container, Button, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Pagination from './commons/pagination';
import { paginate } from './utils/paginate';
import { getTransaction, addNewUser } from '../../../reducers/home';
import { navPermission } from '../../../actions/permission';
import { getAllPosting, approvePendingPosting } from '../../../actions/postingAction';
import PostingPagination from '../../../Utils/PostingPagination';


class Teller extends Component {
  state = {
    accountName: 'Account Name',
    accountNumber: 'Account Number',
    amount: 'Amount',
    teller_no: 'Teller No',
    trans_type: 'Trans Type',
    pageSize: 10,
    currentPage: 1,
  };
  componentDidMount() {
    if (!navPermission(2)) {
      return (window.location.href = '/');
    } else {
      const { user } = window.localStorage;
      const { org_id: orgId } = JSON.parse(user);
      this.props.getTransaction({ orgId });
      this.props.getAllPosting({ limit: 100 });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showGetTransaction !== this.props.showGetTransaction) {
      console.log('got here!!', this.props.showGetTransaction);
    }
  }
  populateTransactions = showGetTransaction => {
    let startId = 1;
    const transactionsList = showGetTransaction.map(transaction => {
      const {
        // accountNumber,
        account_number,
        amount,
        teller_cheque_no,
        trans_type,
        account_name,
        id,
        created_at
      } = transaction;

      return (
        <tr className='tr' key={id}>
          <th scope='row'>{startId++}</th>
          <td>{account_name}</td>
          <td>{account_number}</td>
          <td>{`NGN ${amount}`}</td>
          <td>{teller_cheque_no}</td>
          <td className='text-center'>
            <strong
              className={`${trans_type === 'CR' ? 'text-success' : 'text-danger'
                }`}
            >
              <span className='mr-2'>
                {trans_type === 'CR' ? (
                  <i className='fas fa-level-up-alt'></i>
                ) : (
                  <i className='fas fa-level-down-alt'></i>
                )}
              </span>
              {trans_type}
            </strong>
          </td>
          <td>{created_at}</td>
        </tr>
      );
    });
    return transactionsList;
  };
  handlePageChange = page => {
    this.setState({ currentPage: page });
  };


  render() {
    const { showGetTransaction, isFetching, postFetched, Postings } = this.props;
    // console.log({Postings});
    const { pageSize, currentPage } = this.state;
    const datas = paginate(showGetTransaction, currentPage, pageSize);
    let data = Postings ? Postings.postings : [];
    let _pagination = Postings ? Postings.pagination : {};
    _pagination = { ..._pagination, movement: 3, range: 5 };
    // console.log(datas, 'data');
    // console.log(data, 'dataaaaaaaaaaaaaaaaaaaaaa');
    return (
      <Container>
        <Row>
          <Col>
            <PostingPagination {..._pagination} caller={this.props.getAllPosting} />
          </Col>

        </Row>
        {isFetching &&
          <Row>
            <Col className="d-flex mt-1 justify-content-center">
              <strong>
                ...Fetching postings
              </strong>
            </Col>
          </Row>
        }
        <Row>
          {postFetched === false ?
            <Col>
              <strong>
                Something went wrong
              </strong>
            </Col> :
            <Col>
              <Table borderless>
                <thead>
                  <tr className='text-uppercase'>
                    <th></th>
                    <th>
                      <small className='font-weight-bold'>Account Name</small>
                    </th>
                    <th>
                      <small className='font-weight-bold'>Account Number</small>
                    </th>
                    <th>
                      <small className='font-weight-bold'>Amount</small>
                    </th>
                    <th>
                      <small className='font-weight-bold'>Teller No</small>
                    </th>
                    <th>
                      <small className='font-weight-bold'>Trans type</small>
                    </th>
                    <th>
                      <small className='font-weight-bold'>Date</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {showGetTransaction && this.populateTransactions(datas)} */}
                  {data && this.populateTransactions(data)}
                </tbody>
              </Table>
            </Col>
          }
        </Row>
        <Row>
          <Col>
            {showGetTransaction && (
              <Pagination
                itemCount={showGetTransaction.length}
                pageSize={pageSize}
                onPageChange={this.handlePageChange}
                currentPage={currentPage}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <PostingPagination {..._pagination} caller={this.props.getAllPosting} />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  showNewAdmin: state.home.showNewAdmin,
  showGetTransaction: state.home.showGetTransaction,
  showNewUser: state.home.showNewUser,
  Postings: state.posting.Postings,
  isFetching: state.posting.isFetching,
  postFetched: state.posting.postFetched,

});

const mapDispatchToProps = {
  getTransaction,
  addNewUser,
  getAllPosting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teller);

 
import  { textFilter } from 'react-bootstrap-table2-filter';

 const headerStyle = {
  padding: '6px',
  margin: '0px',
  textAlign: 'center'
}

export const columns = [
    {
      dataField: 'id',
      text: 'User ID',
      sort: true,
      headerStyle,
      align: 'center',
    style: {
      fontWeight: 'bold',
      fontSize: '18px',
      cursor: 'pointer'
    },
    },
    {
      dataField: 'name',
      text: 'User Name',
      sort: true,
      headerStyle,
    filter: textFilter()
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
      headerStyle
    },
    {
      dataField: 'signed_up',
      text: 'Signed Up',
      headerStyle
    },
    {
      dataField: 'login',
      text: 'Logged In',
      sort: true,
      headerStyle
    },
    {
      dataField: 'info',
      text: 'Complete Info',
      headerStyle
    },
    {
      dataField: 'bvn',
      text: 'BVN',
      sort: true,
      headerStyle
    },
    {
      dataField: 'bvn_skipped',
      text: 'BVN Skipped',
      headerStyle
    },
    {
      dataField: 'card_details',
      text: 'Card Details',
      sort: true,
      headerStyle
    },
    {
      dataField: 'card_skipped',
      text: 'Card Skipped',
      headerStyle
    },
    {
      dataField: 'available',
      text: 'Available',
      sort: true,
      headerStyle
    }
  ];

  export const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

import { accountsConstants } from './../constants/accountsConstants';

const {
  SET_ACCOUNT_FIELD,
  SET_EDIT_ACCOUNT
} = accountsConstants;

const initialState = {
  accountList: [],
  selectedAccount: {},
  pagination: {
    offset: 0,
    limit: 0,
    total_count: 0
  },
  staffId: '',
  pageLoading: false,
  getAccountError: true,
  defaultEditAccount: {
    surname: '',
    first_name: '',
    middle_name: '',
    phone_number: '',
    email: '',
    address: '',
    state: '',
    office_address: '',
    identification_type: '',
    identification_others: '',
    id_image: null,
    picture: null,
    account_type: '',
    account_type_others: '',
    account_number: '',
    sector: ''
  },
  editAccount: {
    surname: '',
    first_name: '',
    middle_name: '',
    phone_number: '',
    email: '',
    address: '',
    state: '',
    office_address: '',
    identification_type: '',
    identification_others: '',
    id_image: null,
    picture: null,
    account_type: '',
    account_type_others: '',
    account_number: '',
    sector: ''
  }
};

const accountsReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SET_ACCOUNT_FIELD: {
      const { payload } = action;
      const newState = { ...state, ...payload };
      return newState;
    }
    case SET_EDIT_ACCOUNT: {
      const { payload } = action;
      const { editAccount } = state;
      const newEditAccount = { ...editAccount, ...payload };
      const newState = { ...state, ...{editAccount: newEditAccount} };
      return newState;
    }
    default:
      return state;
  }
};

export default accountsReducer;

import React, { Component } from 'react';
import Status from './Status';
import Loader from './Loader';
import {
  getAllTransfers,
  getUserIdAndPhoneId,
  updateTransfersStatus
} from './../../../reducers/transfers';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Spinner from 'react-spinner-material';
import { toast } from 'react-toastify';
import FilterView from './FilterView';
import { Button } from 'reactstrap';

Modal.setAppElement('#root');

const colStyle = {
  // whiteSpace: 'nowrap'
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class BatchManageTransfers extends Component {
  state = {
    loading: false,
    transfers: [],
    orgId: '',
    modalIsOpen: false,
    status: '',
    token: '',
    id: '',
    submitAuthorization: false,
    userId: '',
    phoneId: '',
    masterRadio: false,
    authorizerID: '',
    authorizerName: ''
  };

  componentDidMount = async () => {
    this.setState({ loading: true });
    const { user } = window.localStorage;
    const { phone, org_id: orgId, firstname, lastname, id } = JSON.parse(user);
    try {
      const ids = await this.props.getUserIdAndPhoneId({ phone });
      const { phone_id: phoneId, user_id: userId } = ids;
      this.setState({ phoneId, userId, orgId });
      let transfers = await this.props.getAllTransfers({
        orgId,
        status: 'initiated'
      });
      const transferData = transfers.data;

      transfers = transferData.map(transfer => {
        transfer.selectRadio = false;
        return transfer;
      });
this.setState({
  phoneId,
  userId,
  orgId,
  loading: false,
  authorizerID: id,
  authorizerName: `${firstname} ${lastname}`,
  transfers: transfers
});
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  };
  getTransferList = async () => {
    try {
      const { orgId } = this.state;
      const transfers = await this.props.getAllTransfers({
        orgId,
        status: 'initiated'
      });
      this.setState({ transfers: transfers.data, masterRadio: false });
    } catch (err) {
      console.log(err);
    }
  };
  mapTransfers = () => {
    const { transfers } = this.state;
    const mappedTransfers = transfers.map(transfer => {
      let statusIcon;
      const {
        id,
        selectRadio,
        sender_acct_name: senderAccountName,
        sender_acct_no: senderAccountNumber,
        beneficiary_acct_no: beneficiaryAccountNumber,
        beneficiary_acct_name: beneficiaryAccountName,
        beneficiary_org_name: beneficiaryBankName,
        amount_wth_fees: amount,
        order_id: orderId,
        remarks,
        status,
        initiator_name: initiatorName,
        created_at: date,
      } = transfer;

      switch (status) {
        case 'initiated':
          statusIcon = (
            <div className='d-flex justify-content-center align-items-center'>
              <Status color={'#ec9b3b'} />
              <span className='ml-2'>Initiated</span>
            </div>
          );
          break;
        case 'pending':
          statusIcon = (
            <div className='d-flex justify-content-center align-items-center'>
              <Status color={'grey'} />
              <span className='ml-2'>Pending</span>
            </div>
          );
          break;
        case 'failed':
          statusIcon = (
            <div className='d-flex justify-content-center align-items-center'>
              <Status color={'red'} />
              <span className='ml-2'>Failed</span>
            </div>
          );
          break;
        case 'rejected':
          statusIcon = (
            <div className='d-flex justify-content-center align-items-center'>
              <Status color={'#f0134d'} />
              <span className='ml-2'>Rejected</span>
            </div>
          );
          break;
        case 'successful':
          statusIcon = (
            <div className='d-flex justify-content-center align-items-center'>
              <Status color={'green'} />
              <span className='ml-2'>Successful</span>
            </div>
          );
          break;
        default:
          statusIcon = <Status color={'black'} />;
      }
      return (
        <tr key={orderId}>
          <td>
            <div className='form-group form-check text-center'>
              <input
                type='checkbox'
                class='form-check-input'
                name={id}
                checked={selectRadio}
                onClick={this.slaveToggle}
              />
            </div>
          </td>
          <td className='ellipsis' style={colStyle}>
            {statusIcon}
          </td>
          <td style={colStyle}>{senderAccountName}</td>
          <td style={colStyle}>{senderAccountNumber}</td>
          <td className='ellipsis' style={colStyle}>
            {beneficiaryAccountName}
          </td>
          <td style={colStyle}>{beneficiaryAccountNumber}</td>
          <td style={colStyle}>{beneficiaryBankName}</td>
          <td style={colStyle}>₦{amount}</td>
          <td style={colStyle}>{orderId}</td>
          <td style={colStyle}>{initiatorName}</td>
          <td style={colStyle}>{remarks}</td>
          <td style={colStyle}>{date}</td>
        </tr>
      );
    });
    return mappedTransfers;
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  };

  closeModal = () => {
    if (this.state.submitAuthorization) {
      return;
    }
    this.setState({ modalIsOpen: false, token: '', status: '' });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  masterToggle = () => {
    const { transfers, masterRadio } = this.state;
    const newTransfers = transfers.map(transfer => {
      transfer.selectRadio = !masterRadio;
      return transfer;
    });
    this.setState({ transfers: newTransfers, masterRadio: !masterRadio });
  };
  slaveToggle = event => {
    const { name: id } = event.target;
    let allActive = true;
    const { transfers } = this.state;
    const newTransfers = transfers.map(transfer => {
      if (transfer.id === id) {
        transfer.selectRadio = !transfer.selectRadio;
      }
      if (!transfer.selectRadio) {
        allActive = false;
      }
      return transfer;
    });

    this.setState({ transfers: newTransfers, masterRadio: allActive });
  };

  notify = (message, type) => {
    switch (type) {
      case 'success':
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true
        });
        break;
      case 'error':
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true
        });
        break;
      default:
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        });
    }
  };

  refreshList = async () => {
    this.setState({ loading: true });
    await this.getTransferList();
    this.setState({ loading: false });
  };

  onFilter = payload => {
    // this.setState(
    //   {
    //     filterObj: payload,
    //     paginationObj: {
    //       limit: 10,
    //       offset: 0
    //     }
    //   }
    // );
    console.log(payload);
  };

  authorizeTransaction = async event => {
    this.setState({ submitAuthorization: true });
    const { updateTransfersStatus } = this.props;
    try {
      const { token, transfers, status, userId, phoneId, orgId, authorizerName, authorizerID } = this.state;
      const selectedIds = [];

      transfers.map(tf => {
        if (tf.selectRadio) {
          selectedIds.push(tf.id);
        }
        return tf;
      });

      const payload = {
        ids: selectedIds,
        token,
        status,
        userId,
        phoneId,
        orgId,
        authorizerID,
        authorizerName
      };
      // console.log(payload);
      await updateTransfersStatus(payload);
      const res = this.props.updateTransferStatusResponse;
      if (res.error) {
        this.notify(res.message, 'error');
      } else {
        await this.getTransferList();
        this.notify(res.message, 'success');
        this.setState({ submitAuthorization: false });
        this.closeModal();
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ submitAuthorization: false });
  };

  render() {
    const {
      loading,
      submitAuthorization,
      token,
      masterRadio,
      transfers
    } = this.state;
    const mappedTransfers = this.mapTransfers();
    const selectedTransfers = transfers.filter(
      transfer => transfer.selectRadio === true
    );
    const tokenError = token.length !== 6;
    return (
      <div className='container p-4'>
        <div className='d-flex justify-content-start align-items-center'>
          <Button
            outline
            size='sm'
            color='success'
            className='d-flex mr-1 justify-content-center align-items-center refresh-btn'
            onClick={this.refreshList}
          >
            <i className='fas fa-refresh'></i>
            <span className='ml-2'>Refresh list</span>
          </Button>
          <FilterView onFilter={this.onFilter} exclude={['status']} />
          <div className='flex-1' style={{ textAlign: 'end' }}>
            <button
              className='btn btn-outline-success btn-sm btn-batch-manage mr-2'
              disabled={selectedTransfers.length < 1}
              onClick={() => {
                this.setState({ modalIsOpen: true, status: 'pending' });
              }}
            >
              Approve{' '}
              <span class='badge badge-dark rounded'>
                {selectedTransfers.length}
              </span>
            </button>
            <button
              className='btn btn-outline-danger btn-sm btn-batch-manage'
              disabled={selectedTransfers.length < 1}
              onClick={() => {
                this.setState({ modalIsOpen: true, status: 'rejected' });
              }}
            >
              Reject{' '}
              <span class='badge badge-dark rounded'>
                {selectedTransfers.length}
              </span>
            </button>
          </div>
        </div>

        <div className='row'>
          {loading && <Loader />}
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={false}
            style={customStyles}
            contentLabel='Example Modal'
          >
            <h2 className='d-none' ref={subtitle => (this.subtitle = subtitle)}>
              Please enter your token
            </h2>
            <div class='d-flex justify-content-end'>
              <div
                onClick={this.closeModal}
                style={{ padding: 5, cursor: 'pointer' }}
              >
                <i className='fas fa-close text-danger'></i>
              </div>
            </div>
            <div className='form-group'>
              <h6>Please enter your token</h6>
              <input
                type='text'
                className='form-control'
                name='token'
                value={this.state.token}
                onChange={this.handleChange}
              />
              <button
                onClick={this.authorizeTransaction}
                className='btn btn-primary d-flex justify-content-center align-items-center w-100 mt-2'
                disabled={tokenError || submitAuthorization}
              >
                {!submitAuthorization && (
                  <small className='font-weight-bold text-uppercase'>
                    {this.state.status === 'rejected' ? 'Reject' : 'Authorize'}
                  </small>
                )}
                {submitAuthorization && (
                  <Spinner
                    size={16}
                    spinnerColor={'#ffffff'}
                    spinnerWidth={2}
                    visible={true}
                  />
                )}
              </button>
            </div>
          </Modal>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='table-responsive p-2 vertical-scroll shadow'>
              <table className='table'>
                <thead>
                  <tr className=''>
                    <th
                      scope='col'
                      className='text-center'
                      style={{ width: 30 }}
                    >
                      <div className='form-group form-check text-center'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={masterRadio}
                          onClick={this.masterToggle}
                        />
                      </div>
                    </th>
                    <th scope='col' style={colStyle}>
                      Status
                    </th>
                    <th scope='col' style={colStyle}>
                      Sender
                    </th>
                    <th scope='col' style={colStyle}>
                      Sender Account Number
                    </th>
                    <th scope='col' style={colStyle}>
                      Beneficiary
                    </th>
                    <th scope='col' style={colStyle}>
                      Beneficiary Account Number
                    </th>
                    <th scope='col' style={colStyle}>
                      Beneficiary Organization
                    </th>
                    <th scope='col' style={colStyle}>
                      Amount
                    </th>
                    <th scope='col' style={colStyle}>
                      Order ID
                    </th>
                    <th scope='col' style={colStyle}>
                      Transaction Initiator
                    </th>
                    <th scope='col' style={colStyle}>
                      Remarks
                    </th>
                    <th scope='col' style={colStyle}>
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>{mappedTransfers}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountName: state.transfer.accountName,
    updateTransferStatusResponse: state.transfer.updateTransferStatusResponse,
    accountBalance: state.transfer.accountBalance,
    serverResponse: state.transfer.serverResponse
  };
};

const mapDispatchToProps = {
  getAllTransfers,
  updateTransfersStatus,
  getUserIdAndPhoneId
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchManageTransfers);

import React from 'react';
import {
  ListGroup,
  ListGroupItem,
  Card,
  Progress,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
const UserInfoPage = ({ info, toggle, toggleEdit }) => {
  return (
    <div>
      <div className='d-flex justify-content-between'>
        <div>
          {' '}
          <button
            type='button'
            className='back-btn btn-success m-3'
            onClick={toggle}>
            Back
          </button>
        </div>
        <div>
          {' '}
          <button
            type='button'
            className='back-btn btn-success m-3'
            onClick={toggleEdit}>
            Edit
          </button>
        </div>
      </div>

      {/* i started my information box from here*/}
      <CardBody>
        <div className=' info row'>
          <div className='col-sm-6 mt-3'>
            <p>BASIC INFORMATION</p>
            <ListGroup className='font-weight-bolder'>
              <ListGroupItem>FIRSTNAME: {info.firstname}</ListGroupItem>
              <ListGroupItem>LASTNAME: {info.lastname}</ListGroupItem>
              <ListGroupItem>EMAIL ADDRESS: {info.email}</ListGroupItem>
              <ListGroupItem>GENDER: {info.gender}</ListGroupItem>
              <ListGroupItem>PHONE: {info.phone}</ListGroupItem>
              <ListGroupItem>HOME ADDRESS: {info.address.city}</ListGroupItem>
            </ListGroup>
          </div>
          <div className='col-sm-6 mt-3'>
            <p>PARICULARS</p>
            <ListGroup className='font-weight-bolder'>
              <ListGroupItem>ADMIN TYPE: {info.user_type}</ListGroupItem>
              <ListGroupItem>ORGANISATION ID: {info.org_id}</ListGroupItem>
              <ListGroupItem>USER ID: {info.id}</ListGroupItem>
              <ListGroupItem>ACCOUNT STATUS: {info.status}</ListGroupItem>
              <ListGroupItem>STAFF ID: {info.staff_id}</ListGroupItem>
              <ListGroupItem>BRANCH CODE: {info.branch_code}</ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </CardBody>
    </div>
  );
};

export default UserInfoPage;

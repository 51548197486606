import React, { Component } from 'react';
import { connect } from 'react-redux';
import { carDetailsLists } from '../../../reducers/home';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import columns from './columns';


export class CarDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: 'Adelaja Ibrahim Abiola',
      table: true,
      modal: false,
      info: {},
      rowEvents: {
        onClick: (e, row, rowIndex) => {
          this.setState({info: row}, ()=>{
             this.toggleTable();
              console.log(this.state.info);
          })
         
        }
      }
    };
  }

  toggleTable = () => this.setState({table: !this.state.table, modal: !this.state.modal})

  componentDidMount = () => {
    console.log(this.props);
    
    this.props.carDetailsLists();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.showCarDetailsLists !== this.props.showCarDetailsLists) {
      console.log(this.props.showCarDetailsLists);
    }
  };

  indication=()=>{
    return <span>Loading...</span>
  }

  render() {
    return (
      <main className='dash-content'>
        <div className='container-fluid' style={{ minWidth: '1500px' }}>
        {!this.state.table &&<div>
            <h1>Checking</h1>
          <Button color="danger" onClick={this.toggleTable}>{this.props.buttonLabel}</Button>
          <Modal isOpen={this.state.modal} toggle={this.toggleTable} className={this.props.className}>
            <ModalHeader toggle={this.toggleTable}>Modal title</ModalHeader>
            <ModalBody>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggleTable}>Do Something</Button>{' '}
              <Button color="secondary" onClick={this.toggleTable}>Cancel</Button>
            </ModalFooter>
          </Modal>
          </div>}
          {/* <div  style={{minWidth: '1200px'}}> */}
          {this.state.table && (
            <BootstrapTable
              bootstrap4
              striped={true}
              hover={true}
              keyField='_id'
              data={
                this.props.showCarDetailsLists
                  ? this.props.showCarDetailsLists
                  : []
              }
              columns={columns}
              noDataIndication= {this.indication} //Item List preloader
              defaultSortDirection='asc'
              rowEvents={this.state.rowEvents}
              pagination={ paginationFactory() }
            filter={ filterFactory() } 
            headerClasses="header-class"
            />
           )}
          {/* </div> */}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  showCarDetailsLists: state.home.showCarDetailsLists
});

const mapDispatchToProps = {
  carDetailsLists
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarDetails);
// export default CarDetails;

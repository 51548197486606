import * as detailsAPI from "./fakeDetailService";

const balances = [
  {
    _id: "5b21ca3eeb7f6fbccd471815",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471818",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "30",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471816",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471818",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "50",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471817",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471820",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "30",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471819",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471814",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "45",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181a",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471814",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "65",
    balance: 299
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181b",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471825",
      debit: "10",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181e",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471822",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "30",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd47181f",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471824",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "20",
    balance: "299"
  },
  {
    _id: "5b21ca3eeb7f6fbccd471821",
    date: "12/092019 15:04:27",
    category: "Bank Transfer Charges",
    reference: "T760144",
    narration: {
      _id: "5b21ca3eeb7f6fbccd471823",
      name: "Transaction charge forban transfer"
    },
    credit: "",
    debit: "60",
    balance: "559"
  }
];

export function getBalances() {
  return balances;
}

export function getBalance(id) {
  return balances.find(m => m._id === id);
}

export function saveBalance(balance) {
  let balanceInDb = balances.find(m => m._id === balance._id) || {};
  balanceInDb.name = balance.name;
  balanceInDb.detail = detailsAPI.details.find(g => g._id === balance.detailId);
  balanceInDb.numberInStock = balance.numberInStock;
  balanceInDb.dailyRentalRate = balance.dailyRentalRate;

  if (!balanceInDb._id) {
    balanceInDb._id = Date.now();
    balances.push(balanceInDb);
  }

  return balanceInDb;
}

export function deletebalance(id) {
  let balanceInDb = balances.find(m => m._id === id);
  balances.splice(balances.indexOf(balanceInDb), 1);
  return balanceInDb;
}

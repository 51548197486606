import React, { Component } from "react";
// import Movies from "./movies"
class Trip extends Component {
  state = {};
  render() {
    return <div>Trip</div>;
  }
}

export default Trip;

const USER_TYPE = {
  Super_Admin: 8,
  Authorizer: 7,
  Basic_Admin: 6,
  Operational_Staff: 5,
  Super_Agent: 4,
  Basic_Agent: 3,
  Teller: 2,
  Customer: 1
};

const LIST_TYPE = {
  Super_Admin: 8,
  Authorizer: 7,
  Basic_Admin: 6,
  Operational_Staff: 5,
  Super_Agent: 4,
  Basic_Agent: 3,
  Teller: 2,
  Customer: 1
};

const VIEW_NAME = {
  appUser: 8,
  approvals: 7,
  usersInfo: 5,
  transactions: 2,
  carDetails: 8
};

function isJsonString(user) {
  try {
      JSON.parse(user);
  } catch (e) {
      return false;
  }
  return true;
}

const user = localStorage.getItem('user');
console.log({user})
let parseUser;
let user_type;
let orgId;

if(user) {
  parseUser = isJsonString(user) ? JSON.parse(user) : '';
user_type = parseUser.user_type;
// console.log('loging token', user_type);

const { org_id: orgId } = parseUser;
}

export const orgAllowed = group => {
  if (orgId == "229017" || orgId == "229199") {
    return true;
  } else {
    return false;
  }
};

export const orgNotAllowed = group => {
  if (orgId == "221199") {
    return true;
  } else {
    return false;
  }
};

export const isHaggai = () => {
  if (orgId == "221199") {
    return true;
  } else {
    return false;
  }
};

export const brandname = () => {
  const hostName = window.location.hostname;
  if (hostName == "localhost" ) {
    return "maxisoft";
  }
  else if (hostName == "connershop.co" ) {
    return "connershop";
  }
  else if (hostName == "emandate.o3cards.com" || hostName == "mandate.o3cards.com") {
    return "o3capital";
  }
  else if (hostName == "haggai.maxipropay.com") {
    return "haggai";
  }
  else if (hostName == "tdirect.haggaibank.com") {
    return "haggai";
  }
  else if (hostName == "tdirect.haggaisecure.com") {
    return "haggai";
  }
  else if (hostName == "treasury.haggai.com") {
    return "haggai";
  } else {
    return "maxisoft";
  }
};


export const viewPermission = view_name => {
  if (USER_TYPE[user_type] >= VIEW_NAME[view_name]) {
    return true;
  } else {
    return false;
  }
};

export const exactViewPermission = view_name => {
  if (USER_TYPE[user_type] === VIEW_NAME[view_name]) {
    return true;
  } else {
    return false;
  }
};

export const listPermission = list_num => {
  if (LIST_TYPE[user_type] >= list_num) {
    return true;
  } else {
    return false;
  }
};

export const exactPermission = list_num => {
  if (LIST_TYPE[user_type] === list_num) {
    return true;
  } else {
    return false;
  }
};

export const navPermission = nav_level => {
  if (USER_TYPE[user_type] >= nav_level) {
    return true;
  } else {
    return false;
  }
};
